import {
  QuestApi,
  QuestEditorApi,
  QuestNode,
  QuestPointer,
  QuestSubgraph,
  QuestSubgraphWithId,
} from "@worldwidewebb/client-quests";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { Node } from "reactflow";
import { ulid } from "ulid";
import { axiosInstance, buildQuestClientBasePath } from "..";
import { useCopyAndPaste } from "../../hooks/useCopyAndPaste";
import useExportRuntimeData from "../../hooks/useExportRuntimeData";
import useInitialNodeTypes from "../../hooks/useInitialNodeTypes";
import { QuestWithId } from "../../models/api/quest";
import { NodeType } from "../../models/nodeType";
import { exportRuntimeData } from "../../utils/exportRuntimeData";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);
const questApi = new QuestApi(undefined, buildQuestClientBasePath(), axiosInstance);

export const getQuest = async (questId: string): Promise<QuestWithId> => {
  try {
    const response = await questEditorApi.getQuestEditorQuest(questId);
    const { data: quest } = response;

    return transformRawQuest(quest);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQuestOrNull = async (questId: string | null): Promise<QuestWithId | null> => {
  if (questId == null) {
    return null;
  }

  return await getQuest(questId);
};

const useQuests = () => {
  const initialNodeTypes = useInitialNodeTypes();
  const { createDuplicates } = useCopyAndPaste();

  const getQuests = useCallback(async (displayName?: string, includeData?: boolean): Promise<QuestWithId[]> => {
    try {
      const response = await questEditorApi.getQuestEditorQuests(displayName, includeData);
      const { data: quests } = response;

      return quests
        .sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b))
        .map((quest) => transformRawQuest(quest));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const setQuestMetaData = useCallback(
    async ({
      questId,
      version,
      name,
      description,
      isReady,
      isTemplate,
      priority,
      questNpcId,
      repeatPeriod,
      folderId: questEditorFolderId,
    }: QuestWithId): Promise<QuestWithId> => {
      try {
        const response = await questEditorApi.updateQuestEditorQuestMetadata(questId, {
          displayName: name,
          description,
          questEditorFolderId,
          isReady,
          isTemplate,
          priority,
          questNpcId,
          repeatPeriod,
        });
        const { data: quest } = response;

        return transformRawQuest(quest);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const setQuestMetaDataAndNodeData = useCallback(
    async (questId: string, quest: QuestWithId, questNodes: QuestNode[]) => {
      const {name, data: questEditorData, dataDefinitions, folderId, ...questFields} = quest;
      try {
        const response = await questEditorApi.setQuestEditorQuestMetadataAndNodes(questId, {
          metadata: {
            ...questFields,
            version: ulid(),
            displayName: name,
            questEditorData: JSON.stringify(questEditorData),
            questEditorDataDefinitions: JSON.stringify(dataDefinitions),
            questEditorFolderId: folderId,
            updatedAt: new Date().toISOString(),
          },
          nodes: questNodes,
        });

        const { data } = response;
        data.questEditorDataDefinitions = dataDefinitions; // avoid having to return this from server

        return transformRawQuest(data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const { exportQuestNodes } = useExportRuntimeData();

  const createQuest = useCallback(
    async (displayName: string): Promise<QuestWithId> => {
      const questId = ulid();

      return setQuestMetaDataAndNodeData(
        questId,
        {
          questId,
          version: ulid(),
          name: displayName,
          description: "",
          data: {
            edges: [],
            nodes: [],
          },
          dataDefinitions: initialNodeTypes,
          isReady: false,
          isTemplate: false,
          priority: 0,
          questNpcId: "",
          repeatPeriod: "none",
          updatedAt: "",
          folderId: "",
        },
        exportQuestNodes([], [], initialNodeTypes)
      );
    },
    [setQuestMetaDataAndNodeData, initialNodeTypes, exportQuestNodes]
  );

  const updateQuest = useCallback(
    async ({
      questId,
      version,
      name,
      description,
      isReady,
      isTemplate,
      priority,
      questNpcId,
      repeatPeriod,
      folderId,
      updatedAt,
    }: QuestWithId): Promise<QuestWithId> => {
      const quest = await getQuest(questId);

      return setQuestMetaData({
        ...quest,
        questId,
        version: ulid(),
        name,
        description,
        isReady,
        isTemplate,
        priority,
        questNpcId,
        repeatPeriod,
        folderId,
        updatedAt,
      });
    },
    [setQuestMetaData]
  );

  const deleteQuest = useCallback(async (questId: string): Promise<void> => {
    try {
      await questEditorApi.deleteQuestEditorQuest(questId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const duplicateQuest = useCallback(
    async (questId: string): Promise<void> => {
      const {
        name,
        description,
        data: { nodes, edges } = { nodes: [], edges: [] },
        dataDefinitions,
        isTemplate,
        priority,
        questNpcId,
        repeatPeriod,
        updatedAt,
        folderId,
      } = await getQuest(questId);

      const duplicatedQuestId = ulid();
      const { nodes: duplicatedNodes, edges: duplicatedEdges } = await createDuplicates(nodes, edges);

      await setQuestMetaDataAndNodeData(
        duplicatedQuestId,
        {
          questId: duplicatedQuestId,
          version: ulid(),
          name: `${name} (duplicate)`,
          description,
          data: {
            nodes: duplicatedNodes as Node<NodeType>[],
            edges: duplicatedEdges,
          },
          dataDefinitions,
          isReady: false,
          isTemplate,
          priority,
          questNpcId,
          repeatPeriod,
          folderId,
          updatedAt,
        },
        exportQuestNodes(duplicatedNodes, duplicatedEdges, dataDefinitions)
      );
    },
    [setQuestMetaDataAndNodeData, createDuplicates]
  );

  const getOwnQuestPointers = useCallback(async (questId?: string): Promise<QuestPointer[]> => {
    try {
      const response = await questApi.getOwnQuestPointers(questId);
      const { data: questPointers } = response;

      return questPointers;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const generateVoices = useCallback(async (questId: string): Promise<void> => {
    try {
      await questEditorApi.generateVoicesForQuest(questId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getSubgraphs = useCallback(async () => {
    try {
      const response = await questEditorApi.getQuestEditorQuestSubgraphs();
      const { data: subgraphs } = response;

      return subgraphs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getSubgraph = useCallback(async (subgraphId: string) => {
    try {
      const response = await questEditorApi.getQuestEditorQuestSubgraph(subgraphId);
      const { data: subgraph } = response;

      return subgraph;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 404) {
        return null;
      }

      console.error(error);
      throw error;
    }
  }, []);

  const setSubgraph = useCallback(async (subgraphId: string, subgraph: QuestSubgraph): Promise<QuestSubgraphWithId> => {
    try {
      const response = await questEditorApi.setQuestEditorQuestSubgraph(subgraphId, subgraph);
      const { data: subgraphWithId } = response;

      return subgraphWithId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const deleteSubgraph = useCallback(async (subgraphId: string) => {
    try {
      await questEditorApi.deleteQuestEditorQuestSubgraph(subgraphId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getQuests,
    getQuest,
    setQuestMetaData,
    setQuestMetaDataAndNodeData,
    createQuest,
    updateQuest,
    deleteQuest,
    duplicateQuest,
    getOwnQuestPointers,
    generateVoices,
    getSubgraphs,
    getSubgraph,
    setSubgraph,
    deleteSubgraph,
  };
};

export default useQuests;

// eslint-disable-next-line
export const transformRawQuest = (rawQuest: any): QuestWithId => ({
  ...rawQuest,
  questId: rawQuest.questId,
  version: rawQuest.version,
  data: typeof rawQuest.questEditorData == "string" ? JSON.parse(rawQuest.questEditorData) : rawQuest.questEditorData,
  dataDefinitions: typeof rawQuest.questEditorDataDefinitions == "string" ? JSON.parse(rawQuest.questEditorDataDefinitions) : rawQuest.questEditorDataDefinitions,
  name: rawQuest.displayName,
  description: rawQuest.description,
  isReady: rawQuest.isReady,
  isTemplate: rawQuest.isTemplate,
  priority: rawQuest.priority,
  questNpcId: rawQuest.questNpcId,
  repeatPeriod: rawQuest.repeatPeriod,
  folderId: rawQuest.questEditorFolderId,
  authorId: rawQuest.authorId,
  updatedAt: rawQuest.updatedAt,
});
