import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Center,
  ChakraProps,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { CHUNK_H, CHUNK_W, Location } from "../../models/api/location";
import SelectRoom from "./SelectRoom";
import { MdLocationPin } from "react-icons/md";
import ImageFrame from "./ImageFrame";
import useRooms from "../../api/rooms/useRooms";
import { ChunkWithId, WorldMap } from "@worldwidewebb/client-world";
import ReactFlow, {
  NodeProps,
  NodeResizeControl,
  ReactFlowProvider,
  SelectionMode,
  useNodesState,
  useReactFlow,
  useViewport,
} from "reactflow";
import Background from "../../export/reactflow/components/base/Background";
import Controls from "../../export/reactflow/components/base/Controls";
import MiniMap from "../../export/reactflow/components/base/MiniMap";
import { ulid } from "ulid";
import { IoMdResize } from "react-icons/io";
import { useToken } from "@chakra-ui/system";

// TODO: VERY WIP AND DIRTY

const SELECT_WHOLE_ROOM_RADIUS = 100_000;

const MIN_W = 50;

const BackgroundNode: React.FC<NodeProps> = ({ data: { chunks } }) => {
  return <LocationRender chunks={chunks} />;
};

const LocationNode: React.FC<NodeProps> = ({ data: { color } }) => {
  const { zoom } = useViewport();
  const boxSize = `${(1 / (zoom || 0.1)) * 30}px`;

  return (
    <>
      <NodeResizeControl keepAspectRatio={true} minWidth={MIN_W}>
        <Icon color={color} as={IoMdResize} boxSize={boxSize} />
      </NodeResizeControl>
    </>
  );
};

const nodeTypes = {
  LocationNode,
  BackgroundNode,
};

interface LocationCanvasProps extends ChakraProps {
  location: Location;
  chunks: ChunkWithId[];
}

const LocationCanvas: React.FC<LocationCanvasProps> = ({
  color: token,
  location: { roomName, x, y, radius },
  chunks,
}) => {
  const [color] = useToken("colors", [token?.toString() ?? "white"]);

  const coordinates = useMemo(
    () =>
      chunks.flatMap(({ layers, coordinates }) =>
        layers.map(({ dx, dy }) => ({ x: coordinates[0] * CHUNK_W + dx, y: coordinates[1] * CHUNK_H + dy }))
      ),
    [chunks]
  );

  const { w, h } = useMemo(() => {
    const minX = Math.min(...coordinates.map(({ x }) => x));
    const maxX = Math.min(...coordinates.map(({ x }) => x + CHUNK_W));

    const minY = Math.min(...coordinates.map(({ y }) => y));
    const maxY = Math.min(...coordinates.map(({ y }) => y + CHUNK_H));

    return {
      w: maxX - minX,
      h: maxY - minY,
    };
  }, [coordinates]);

  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: ulid(),
      type: "BackgroundNode",
      data: {
        chunks,
      },
      position: { x: 0, y: 0 },
      style: {
        width: w,
        height: h,
      },
      selectable: false,
      draggable: false,
    },
    {
      id: ulid(),
      type: "LocationNode",
      data: {
        color: token,
      },
      position: { x: x - radius, y: y - radius },
      style: {
        background: "transparent",
        borderColor: color?.toString(),
        borderWidth: 5,
        borderRadius: "50%",
        width: radius * 2,
        height: radius * 2,
      },
    },
  ]);

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      proOptions={{ hideAttribution: true }}
      multiSelectionKeyCode={["Meta", "Control"]}
      deleteKeyCode={["Backspace", "Delete"]}
      selectionKeyCode={null}
      selectionMode={SelectionMode.Partial}
      panOnDrag={[1]}
      panActivationKeyCode={"Shift"}
      selectionOnDrag={true}
      minZoom={0.125}
      maxZoom={2}
    >
      <Background />
      <Controls position={"top-right"} showInteractive={false} />
      <MiniMap
        position={"bottom-right"}
        pannable={true}
        zoomable={true}
        nodeColor={({ type }) => (type === "LocationNode" ? color : "transparent")}
      />
    </ReactFlow>
  );
};

interface LocationRenderProps extends BoxProps {
  chunks: ChunkWithId[];
}

const LocationRender: React.FC<LocationRenderProps> = ({ chunks, children }) => {
  return (
    <Box position={"relative"}>
      <>
        {chunks.flatMap(({ layers, coordinates }, index$1) =>
          layers.map((layer, index$2) => {
            const { imageData: src, frameTimes, dx, dy, dz } = layer;
            const top = `${coordinates[1] * CHUNK_H + dy}px`;
            const left = `${coordinates[0] * CHUNK_W + dx}px`;
            const zIndex = -dz;

            return (
              <ImageFrame
                key={`${index$1} ${index$2}`}
                src={src}
                frameTimes={frameTimes}
                position={"absolute"}
                top={top}
                left={left}
                zIndex={zIndex}
              />
            );
          })
        )}

        {children}
      </>
    </Box>
  );
};

interface LocationPickerProps extends ChakraProps {
  isOpen: boolean;
  onClose: () => void;
  roomName: string;
  x: number;
  setX: (x: number) => void;
  y: number;
  setY: (y: number) => void;
  radius: number;
  setRadius: (radius: number) => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({
  color,
  isOpen,
  onClose,
  roomName,
  x,
  setX,
  y,
  setY,
  radius,
  setRadius,
}) => {
  const { getRoom } = useRooms();
  const [_, setWorld] = useState<WorldMap | undefined>(undefined);
  const [chunks, setChunks] = useState<ChunkWithId[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(() => {
    const [
      {
        position: { x, y },
        width,
      },
    ] = reactFlow.getNodes().filter(({ type }) => type === "LocationNode");

    setX(Math.round(x + (width || 0) / 2));
    setY(Math.round(y + (width || 0) / 2));
    setRadius((width || 0) / 2);

    onClose();
  }, [reactFlow]);

  useEffect(() => {
    if (!roomName) {
      return;
    }

    setIsLoading(true);
    setHasError(false);

    getRoom(roomName)
      .then(({ worldMap, chunks }) => {
        if (worldMap == null) {
          return;
        }

        if (chunks == null) {
          return;
        }

        setWorld(worldMap);
        setChunks(chunks);
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [getRoom, roomName]);

  useEffect(() => {
    setTimeout(() => reactFlow.fitView(), 100);
  }, [reactFlow]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />

      <ModalContent bg={"theme.dark.background"}>
        <ModalHeader>
          <Text color={color} casing={"uppercase"}>
            Select a location
          </Text>
        </ModalHeader>

        <ModalCloseButton color={color} />

        <ModalBody display={"flex"}>
          <Box
            flexGrow={1}
            borderColor={color}
            borderWidth={1}
            sx={{
              "::-webkit-scrollbar": {
                width: 2,
                height: 2,
                bg: "transparent",
              },
              "::-webkit-scrollbar-track": {
                bg: "transparent",
              },
              "::-webkit-scrollbar-thumb": {
                bg: color,
              },
              "::-webkit-scrollbar-corner": {
                bg: color,
              },
            }}
            overflow={"auto"}
          >
            {isLoading && (
              <Center w={"100%"} h={"100%"}>
                <Spinner color={color?.toString() ?? ""} />
              </Center>
            )}
            {hasError && (
              <Center w={"100%"} h={"100%"}>
                <Text color={color} casing={"uppercase"}>
                  Failed to load world information
                </Text>
              </Center>
            )}
            {!isLoading && !hasError && (
              <LocationCanvas color={color} location={{ roomName: "", x, y, radius }} chunks={chunks} />
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onClose} variant={"outline"} borderColor={color} borderRadius={0}>
              <Text color={color} casing={"uppercase"}>
                Cancel
              </Text>
            </Button>
            <Button onClick={handleUpdate} variant={"outline"} borderColor={color} borderRadius={0}>
              <Text color={color} casing={"uppercase"}>
                Update
              </Text>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface SelectLocationProps extends ChakraProps {
  value: Location | undefined;
  setValue: (value: Location) => void;
}

const SelectLocation: React.FC<SelectLocationProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showFields, setShowFields] = useState<boolean>(false);
  const [roomName, setRoomName] = useState<string>(value?.roomName ?? "");
  const [x, setX] = useState<number>(value?.x ?? 0);
  const [y, setY] = useState<number>(value?.y ?? 0);
  const [radius, setRadius] = useState<number>(value?.radius ?? 0);

  useEffect(() => {
    setValue({
      roomName,
      x,
      y,
      radius,
    });
  }, [roomName, x, y, radius]);

  const selectWholeRoom = radius === SELECT_WHOLE_ROOM_RADIUS;

  return (
    <ReactFlowProvider>
      <Stack {...chakraProps}>
        <SelectRoom
          value={roomName}
          setValue={(value) => setRoomName(value)}
          color={color}
          flexGrow={1}
          flexShrink={0}
        />

        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              select whole room
            </Text>
          </FormLabel>

          <Checkbox
            color={color}
            isChecked={radius === SELECT_WHOLE_ROOM_RADIUS}
            onChange={({ target: { checked } }) => (checked ? setRadius(SELECT_WHOLE_ROOM_RADIUS) : setRadius(25))}
          />
        </FormControl>

        {!selectWholeRoom && (
          <>
            <HStack>
              <Button
                borderColor={color}
                borderRadius={0}
                borderWidth={2}
                color={color}
                variant={"outline"}
                flexGrow={1}
                onClick={() => setShowFields((showManualForm) => !showManualForm)}
              >
                {showFields ? "HIDE" : "SHOW"} FIELDS
              </Button>

              <Tooltip
                bg={"theme.dark.background"}
                borderColor={color}
                borderRadius={0}
                borderWidth={1}
                color={color}
                label={"select location"}
                placement={"right"}
              >
                <IconButton
                  borderColor={color}
                  borderRadius={0}
                  borderWidth={2}
                  color={color}
                  variant={"outline"}
                  icon={<Icon as={MdLocationPin} />}
                  aria-label={"select location"}
                  onClick={onOpen}
                />
              </Tooltip>
            </HStack>

            {showFields && (
              <Stack>
                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      X
                    </Text>
                  </FormLabel>
                  <NumberInput value={x} defaultValue={0}>
                    <NumberInputField
                      borderColor={color}
                      borderRadius={0}
                      borderWidth={2}
                      onChange={({ target: { value } }) => setX(Number(value))}
                      color={color}
                    />
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Y
                    </Text>
                  </FormLabel>
                  <NumberInput value={y} defaultValue={0}>
                    <NumberInputField
                      borderColor={color}
                      borderRadius={0}
                      borderWidth={2}
                      value={y}
                      onChange={({ target: { value } }) => setY(Number(value))}
                      color={color}
                    />
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Radius
                    </Text>
                  </FormLabel>
                  <NumberInput value={radius} defaultValue={1} min={1}>
                    <NumberInputField
                      borderColor={color}
                      borderRadius={0}
                      borderWidth={2}
                      onChange={({ target: { value } }) => setRadius(Number(value))}
                      color={color}
                    />
                  </NumberInput>
                </FormControl>
              </Stack>
            )}
          </>
        )}

        <LocationPicker
          color={color}
          isOpen={isOpen}
          onClose={onClose}
          roomName={roomName}
          x={x}
          setX={setX}
          y={y}
          setY={setY}
          radius={radius}
          setRadius={setRadius}
        />
      </Stack>
    </ReactFlowProvider>
  );
};

export default SelectLocation;
