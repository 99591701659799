import { Quest } from "../../models/api/quest";
import { useCallback } from "react";
import { json, LoaderFunctionArgs } from "react-router";
import { AxiosError } from "axios";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import useSessions from "../../api/sessions/useSessions";
import useUsers from "../../api/users/useUsers";
import useQuests from "../../api/quests/useQuests";

const useQuestLoader = (
  getQuest: (questId: string) => Promise<Quest>,
  getSessionUserId: () => Promise<string | undefined>,
  getDisplayName: () => Promise<string>,
  getSubgraphs: () => Promise<QuestSubgraphWithId[]>
) => {
  const { getQuestEditorLocks } = useSessions();
  const { getDisplayNameByUserId } = useUsers();
  const { getQuests } = useQuests();

  const loader = useCallback(
    async ({ params: { id: questId } }: LoaderFunctionArgs) => {
      if (!questId) {
        throw json(
          {
            message: "Error occurred with quest identifier...",
          },
          {
            status: 400,
          }
        );
      }

      const sessionUserId = await getSessionUserId();
      const sessionLocks = await getQuestEditorLocks(questId);
      const [sessionUserIdWithLock] = sessionLocks;
      const sessionUserDisplayName = await getDisplayNameByUserId(sessionUserIdWithLock?.userId);

      try {
        return {
          quest: await getQuest(questId),
          sessionUserId,
          sessionUserIdWithLock,
          sessionUserDisplayName,
          displayName: await getDisplayName(),
          subgraphs: await getSubgraphs(),
          quests: await getQuests(),
        };
      } catch (error) {
        console.error(error);

        if (error instanceof AxiosError) {
          throw json(
            {
              message: error.response?.data.message,
              details: error.response?.data.details,
            },
            {
              status: error.response?.data.message === "Unauthorized" ? 401 : error.status,
            }
          );
        }

        throw json(
          {
            message: "Error occurred fetching quest...",
          },
          {
            status: 500,
          }
        );
      }
    },
    [getQuest, getDisplayName, getSubgraphs, getQuests]
  );

  return { loader };
};

export default useQuestLoader;
