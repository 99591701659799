import { AuthApi } from "@worldwidewebb/client-auth";
import { ProfileApi } from "@worldwidewebb/client-profiles";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const authApi = new AuthApi(undefined, buildApiClientBasePath("auth"), axiosInstance);
const profileApi = new ProfileApi(undefined, buildApiClientBasePath("profiles"), axiosInstance);

const useUsers = () => {
  const getDisplayName = useCallback(async (): Promise<string> => {
    try {
      const response = await profileApi.getDisplayName();
      const { data: displayName } = response;

      return displayName;
    } catch (error) {
      console.error(error);
      return "";
    }
  }, []);

  const getDisplayNameByUserId = useCallback(async (userId?: string): Promise<string | undefined> => {
    if (userId == null) {
      return undefined;
    }

    try {
      const response = await profileApi.getProfile(userId);
      const {
        data: { displayName },
      } = response;

      return displayName;
    } catch (error) {
      console.error(error);
      return "";
    }
  }, []);

  const getSessionUserId = useCallback(async (): Promise<string | undefined> => {
    try {
      const response = await authApi.getUserSessions();
      const { data: sessions } = response;
      const [{ userId }] = sessions;

      return userId;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, []);

  return {
    getDisplayName,
    getDisplayNameByUserId,
    getSessionUserId,
  };
};

export default useUsers;
