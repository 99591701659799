import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { getAllegiances } from "../../api/allegiances/allegiances";
import { Allegiance } from "@worldwidewebb/client-skills";

interface SelectAllegianceProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectAllegiance: React.FC<SelectAllegianceProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let allegiances: Allegiance[] = [];

      try {
        allegiances = await getAllegiances();
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return allegiances
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ allegianceId, displayName, category }) => [allegianceId, `${displayName} - ${category}`]);
    },
    [toast]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Allegiance"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectAllegiance;
