import { TargetHandle } from "../models/nodeType";
import { QuestEdge, QuestNode } from "../store/quests";

export function filterByInitialReadyNodesAndEdges(nodes: QuestNode[], edges: QuestEdge[]) {
  const readyNodes: QuestNode[] = [];
  const readyEdges: QuestEdge[] = [];

  nodes.forEach((node, _, nodes) => {
    const {
      data: { targetHandles = [], isReady },
    } = node;

    if (targetHandles.length === 0 && isReady) {
      readyNodes.push(node);
      return;
    }

    if (targetHandles.length !== 0 && hasInitialReadyNode(targetHandles, nodes, edges)) {
      readyNodes.push(node);
      return;
    }
  });

  edges.forEach((edge) => {
    const { source, target } = edge;

    if (readyNodes.map(({ id }) => id).includes(source) && readyNodes.map(({ id }) => id).includes(target)) {
      readyEdges.push(edge);
      return;
    }
  });

  return {
    readyNodes,
    readyEdges,
  };
}

function hasInitialReadyNode(targetHandles: TargetHandle[], nodes: QuestNode[], edges: QuestEdge[]): boolean {
  return targetHandles.some(({ handleId: targetHandle }) => {
    const sourceNode = nodes.find(({ id }) =>
      edges.some((edge) => edge.source === id && edge.targetHandle === targetHandle)
    );

    if (sourceNode == null) {
      return false;
    }

    const {
      data: { targetHandles = [], isReady },
    } = sourceNode;

    if (targetHandles.length === 0 && isReady) {
      return true;
    }

    if (targetHandles.length === 0 && !isReady) {
      return false;
    }

    return hasInitialReadyNode(targetHandles, nodes, edges);
  });
}
