import { AuthApi } from "@worldwidewebb/client-auth";
import { Profile, ProfileApi } from "@worldwidewebb/client-profiles";
import { axiosInstance, buildApiClientBasePath } from "..";

const profileApi = new ProfileApi(undefined, buildApiClientBasePath("profiles"), axiosInstance);

export async function getDisplayNameByUserId(userId: string): Promise<string> {
  try {
    const {
      data: { displayName },
    } = await profileApi.getProfile(userId);

    return displayName;
  } catch (error) {
    console.error(error);
    return "";
  }
}

export async function getProfilesByDisplayName(displayName: string): Promise<Profile[]> {
  try {
    const { data: profiles } = await profileApi.searchUserProfiles(displayName);

    return profiles;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function hasProfile(userId: string): Promise<boolean> {
  try {
    await profileApi.getProfile(userId);

    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const authApi = new AuthApi(undefined, buildApiClientBasePath("auth"), axiosInstance);

export async function getSessionUserId(): Promise<string> {
  try {
    const { data: sessions } = await authApi.getUserSessions();
    const [{ userId }] = sessions;

    return userId;
  } catch (error) {
    console.error(error);
    return "";
  }
}
