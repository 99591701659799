import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useEquipmentSlots from "../../api/equipmentSlots/useEquipmentSlots";
import { EquipmentSlotWithFilter } from "@worldwidewebb/client-assets";

interface SelectEquipmentSlotProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectEquipmentSlot: React.FC<SelectEquipmentSlotProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const { getEquipmentSlots } = useEquipmentSlots();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let equipmentSlots: EquipmentSlotWithFilter[] = [];

      try {
        equipmentSlots = await getEquipmentSlots();
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return equipmentSlots
        .filter((equipmentSlot) =>
          searchQuery ? equipmentSlot.displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
        )
        .map(({ slotName, displayName }) => [slotName, displayName]);
    },
    [toast, getEquipmentSlots]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Equipment Slot"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectEquipmentSlot;
