import { ReactFlowProvider } from "reactflow";
import { Outlet, useLoaderData, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ConnectionProvider } from "../context/ConnectionContext";
import Sidebar, { SidebarItem } from "../components/navigation/Sidebar";
import { CgDebug, CgListTree } from "react-icons/cg";
import { Flex, Grid, GridItem, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { useCallback, useEffect, useMemo, useState } from "react";
import MultiProvider from "../context/MultiProvider";
import NodeSelector from "../components/navigation/elements/NodeSelector";
import NodeDebugger from "../components/navigation/elements/NodeDebugger";
import { UserSettingsProvider } from "../context/UserSettingsContext";
import { ActiveUsersProvider } from "../context/ActiveUsersContext";
import { QuestProvider } from "../context/QuestContext";
import { GoGitBranch } from "react-icons/go";
import QuestCommits from "../components/navigation/elements/QuestCommits";
import { UserQuestPointerProvider } from "../context/UserQuestPointerContext";
import { NodeNotificationsProvider } from "../context/NodeNotificationsProvider";

export const ReactFlowLayout = () => {
  const { sessionUserId } = useLoaderData() as { sessionUserId: string | undefined };
  const { id: questId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  const handleNavigateToHome = useCallback(() => {
    setIsNavigating(true);

    navigate("/");
  }, [navigate]);

  const items: SidebarItem[] = useMemo(
    () => [
      {
        icon: CgListTree,
        label: "node selection",
        content: <NodeSelector />,
      },
      {
        icon: CgDebug,
        label: "quest pointers",
        content: <NodeDebugger />,
      },
      {
        icon: GoGitBranch,
        label: "quest commits",
        content: <QuestCommits questId={questId} />,
      },
    ],
    [questId]
  );

  useEffect(() => {
    const userId = searchParams.get("userId");

    if (userId) {
      return;
    }

    setSearchParams({ ...searchParams, userId: sessionUserId });
  }, [searchParams]);

  return (
    <MultiProvider
      providers={[
        <ReactFlowProvider />,
        <QuestProvider questId={questId} />,
        <ConnectionProvider />,
        <UserQuestPointerProvider questId={questId} />,
        <UserSettingsProvider />,
        <ActiveUsersProvider />,
      ]}
    >
      <Grid as={"main"} minH={"100vh"} templateColumns={"1fr"}>
        <GridItem display={"grid"}>
          <Outlet />
        </GridItem>

        <GridItem
          display={"flex"}
          flexDirection={"column"}
          bg={"theme.dark.background"}
          position={"fixed"}
          top={0}
          left={0}
          bottom={0}
        >
          <Flex bg={"indigo.600"}>
            <Tooltip label={"return home"} placement={"right"}>
              <IconButton
                color={"white"}
                icon={<Icon as={MdHome} />}
                aria-label={"return home"}
                variant={"ghost"}
                borderRadius={0}
                isLoading={isNavigating}
                onClick={handleNavigateToHome}
              />
            </Tooltip>
          </Flex>

          <Sidebar flexGrow={1} bg={"theme.dark.background"} color={"white"} items={items} showContent={false} />
        </GridItem>
      </Grid>
    </MultiProvider>
  );
};
