import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { getGuis, Gui } from "../../api/guis/guis";

interface SelectGuiProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectGui: React.FC<SelectGuiProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let guis: Gui[] = [];

      try {
        guis = await getGuis();
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return guis
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b))
        .map(({ guiName, displayName }) => [guiName, `${displayName} (${guiName})}`]);
    },
    [toast]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"GUI"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectGui;
