import { NpcsApi, NpcWithId } from "@worldwidewebb/client-npcs";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const npcsApi = new NpcsApi(undefined, buildApiClientBasePath("npcs"), axiosInstance);

const useNpcs = () => {
  const getNpcs = useCallback(async (): Promise<NpcWithId[]> => {
    try {
      const response = await npcsApi.getNpcs(); // getNpcsByQuery after resolving auth bug
      const { data: npcs } = response;

      return npcs.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getNpcs,
  };
};

export default useNpcs;
