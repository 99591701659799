import { QuestApi, QuestEditorApi, QuestEditorSessionWithId } from "@worldwidewebb/client-quests";
import { useCallback } from "react";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);
const questApi = new QuestApi(undefined, buildQuestClientBasePath(), axiosInstance);

const useSessions = () => {
  const createQuestEditorLock = useCallback(async (questId: string): Promise<QuestEditorSessionWithId> => {
    try {
      const response = await questApi.createOwnQuestEditorSession(questId);
      const { data: questEditorSession } = response;

      return questEditorSession;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const deleteQuestEditorLock = useCallback(async (questId: string): Promise<void> => {
    try {
      await questApi.deleteOwnQuestEditorSession(questId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getQuestEditorLocks = useCallback(async (questId: string): Promise<QuestEditorSessionWithId[]> => {
    try {
      const response = await questEditorApi.getQuestEditorSessionsForQuest(questId);
      const { data: questEditorSessions } = response;

      return questEditorSessions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    createQuestEditorLock,
    deleteQuestEditorLock,
    getQuestEditorLocks,
  };
};

export default useSessions;
