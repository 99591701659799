import React from "react";
import { Center, List, ListProps, Text } from "@chakra-ui/react";
import { QuestWithId } from "../models/api/quest";
import QuestListItem from "./QuestListItem";

interface QuestListProps extends ListProps {
  quests: QuestWithId[];
  onUpdate: (quest: QuestWithId) => void;
  isUpdating: boolean;
  onDelete: (questId: string) => void;
  isDeleting: boolean;
  onDuplicate: (questId: string) => void;
  isDuplicating: boolean;
  openIndexes: number[];
  onToggleOpenIndex: (index: number) => void;
  onToggleReadiness: (questId: string) => void;
  isToggleReadiness: boolean;
}

const QuestList: React.FC<QuestListProps> = ({
  quests,
  onUpdate,
  isUpdating,
  onDelete,
  isDeleting,
  onDuplicate,
  isDuplicating,
  openIndexes,
  onToggleOpenIndex,
  onToggleReadiness,
  isToggleReadiness,
  ...listProps
}) => {
  if (quests.length === 0) {
    return (
      <Center flexGrow={1} pb={3}>
        <Text color={"white"} casing={"uppercase"}>
          No quest results found
        </Text>
      </Center>
    );
  }

  return (
    <List borderWidth={2} borderColor={"indigo.600"} borderRadius={0} {...listProps}>
      {quests.map((quest, index) => (
        <QuestListItem
          key={quest.questId}
          index={index}
          questId={quest.questId}
          version={quest.version}
          onUpdate={onUpdate}
          isUpdating={isUpdating}
          onDelete={onDelete}
          isDeleting={isDeleting}
          onDuplicate={onDuplicate}
          isDuplicating={isDuplicating}
          openIndexes={openIndexes}
          onToggleOpenIndex={onToggleOpenIndex}
          onToggleReadiness={onToggleReadiness}
          isToggleReadiness={isToggleReadiness}
        />
      ))}
    </List>
  );
};

export default QuestList;
