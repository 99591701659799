import React, { createContext, PropsWithChildren, useContext } from "react";
import { useRandomColor } from "../hooks/useRandomColor";
import { adjectives, animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import { User, useYDoc } from "../hooks/useYDoc";
import { useParams } from "react-router-dom";
import { useLoaderData } from "react-router";

interface ActiveUsers {
  activeUsers: User[];
}

const ActiveUsersContext = createContext<ActiveUsers | null>(null);

const ActiveUsersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { sessionUserId, displayName } = useLoaderData() as { sessionUserId: string | undefined; displayName: string };
  const { id } = useParams();

  const randomUsername = uniqueNamesGenerator({
    dictionaries: [adjectives, colors, animals], // colors can be omitted here as not used
    length: 3,
  });

  const randomColor = useRandomColor();

  const { activeUsers } = useYDoc(id, sessionUserId, displayName || randomUsername, randomColor);

  return <ActiveUsersContext.Provider value={{ activeUsers }}>{children}</ActiveUsersContext.Provider>;
};

const useActiveUsersProvider = () => {
  const context = useContext(ActiveUsersContext);

  if (context == null) {
    throw new Error("useActiveUsersProvider used outside of ActiveUsersProvider");
  }

  return context;
};

export { ActiveUsersProvider, useActiveUsersProvider };
