import { QuestNode, QuestNodeProperty } from "@worldwidewebb/client-quests";
import { Edge, Node } from "reactflow";
import { HandleCategory, NodeData, NodeType, SourceHandle, TargetHandle } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";
import NodeError from "../models/nodes/NodeError";

interface QuestNodePropertyWithNode extends QuestNodeProperty {
  node?: Node<NodeType>;
}

const getTargetNodeProperty = (
  nodeId: string,
  nodeType: string,
  { handleId, handleName }: TargetHandle,
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  isSubgraph?: boolean,
  parentNodes: Node<NodeType>[] = [],
  parentEdges: Edge<EdgeType>[] = []
): QuestNodePropertyWithNode => {
  if (handleId == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): handleId is undefined, should be impossible!`);
  }

  let sourceNodeEdges = edges.filter(({ targetHandle }) => targetHandle === handleId);

  if (sourceNodeEdges.length > 1) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): multiple connections made to ${handleName}`);
  }

  if (sourceNodeEdges.length < 1 && isSubgraph) {
    sourceNodeEdges = parentEdges.filter(({ targetHandle }) => targetHandle === handleId);
  }

  if (sourceNodeEdges.length > 1) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): multiple connections made to ${handleName}`);
  }

  if (sourceNodeEdges.length < 1) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no connection to ${handleName}`);
  }

  const [{ source, sourceHandle: sourceHandleId }] = sourceNodeEdges;

  if (sourceHandleId == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): handleId is undefined, should be impossible!`);
  }

  let sourceNodeId = source;
  let sourceNode = nodes.find(({ id: nodeId }) => nodeId === sourceNodeId);
  let sourceNodeType = sourceNode?.data.nodeName;

  if (sourceNode == null && isSubgraph) {
    sourceNode = parentNodes.find(({ id: nodeId }) => nodeId === sourceNodeId);
    sourceNodeType = sourceNode?.data.nodeName;
  }

  if (sourceNode == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no source node for ${handleName}`);
  }

  if (sourceNodeType == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no source type for ${handleName}`);
  }

  const {
    data: { nodeCategory: sourceNodeCategory },
  } = sourceNode;

  if (
    sourceNodeType !== "subgraph_input" &&
    (sourceNodeCategory === "Subgraph" || sourceNodeCategory === "Subgraph Template")
  ) {
    sourceNode = sourceNode.data.nodeData?.nodes.find(({ data: { sourceHandles } }: Node<NodeType>) =>
      sourceHandles?.map(({ handleId }) => handleId)?.includes(sourceHandleId)
    );

    if (sourceNode == null) {
      throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no internal source node in subgraph for ${handleName}`);
    }

    sourceNodeId = sourceNode.id;
    sourceNodeType = sourceNode.data.nodeName;
  }

  if (sourceNodeType === "subgraph_input") {
    const {
      data: { targetHandles = [] },
    } = sourceNode;

    const [targetHandle] = targetHandles;

    return getTargetNodeProperty(
      sourceNodeId,
      sourceNodeType,
      targetHandle,
      nodes,
      edges,
      isSubgraph,
      parentNodes,
      parentEdges
    );
  }

  const sourceNodeProperty = sourceNode.data.sourceHandles?.find(
    ({ handleId }) => handleId === sourceHandleId
  )?.handleName;

  return {
    nodeId: sourceNodeId,
    nodeType: sourceNodeType,
    propertyName: handleName,
    connectedPropertyName: sourceNodeProperty,
  };
};

const getSourceNodeProperty = (
  targetHandleId: string | null | undefined,
  targetHandleName: string,
  nodeId: string,
  nodeType: string,
  nodes: Node<NodeType>[],
  isSubgraph?: boolean,
  parentNodes: Node<NodeType>[] = []
): QuestNodePropertyWithNode => {
  if (targetHandleId == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): handleId is undefined, should be impossible!`);
  }

  let targetNodeId = nodeId;
  let targetNode = nodes.find(({ id }) => id === targetNodeId);
  let targetNodeType = targetNode?.data.nodeName;

  if (targetNode == null && isSubgraph) {
    targetNode = parentNodes.find(({ id }) => id === targetNodeId);
    targetNodeType = targetNode?.data.nodeName;
  }

  if (targetNode == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no target node for ${targetHandleName}`);
  }

  if (targetNodeType == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no target type for ${targetHandleName}`);
  }

  const {
    data: { nodeCategory: targetNodeCategory },
  } = targetNode;

  if (
    targetNodeType !== "subgraph_input" &&
    (targetNodeCategory === "Subgraph" || targetNodeCategory === "Subgraph Template")
  ) {
    targetNode = targetNode.data.nodeData?.nodes.find(({ data: { targetHandles } }: Node<NodeType>) =>
      targetHandles?.map(({ handleId }) => handleId).includes(targetHandleId)
    );

    if (targetNode == null) {
      throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no internal target node in subgraph for ${targetHandleName}`);
    }

    targetNodeId = targetNode.id;
    targetNodeType = targetNode.data.nodeName;
  }

  return {
    node: targetNode,
    nodeId: targetNodeId,
    nodeType: targetNodeType,
    propertyName: targetHandleName,
  };
};

const getSourceNodeProperties = (
  nodeId: string,
  nodeType: string,
  { handleId, handleName, handleCategory }: SourceHandle,
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  isSubgraph?: boolean,
  parentNodes: Node<NodeType>[] = [],
  parentEdges: Edge<EdgeType>[] = []
): QuestNodePropertyWithNode[] => {
  if (handleId == null) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): handleId is undefined, should be impossible!`);
  }

  let targetNodeEdges = edges.filter(({ sourceHandle }) => sourceHandle === handleId);

  if (targetNodeEdges.length > 1 && !isMultipleSourceConnectionsSupported(handleCategory)) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): multiple connections made from ${handleName}`);
  }

  if (targetNodeEdges.length < 1 && !isDataHandle(handleCategory) && isSubgraph) {
    targetNodeEdges = parentEdges.filter(({ sourceHandle }) => sourceHandle === handleId);
  }

  if (targetNodeEdges.length > 1 && !isMultipleSourceConnectionsSupported(handleCategory)) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): multiple connections made from ${handleName}`);
  }

  if (targetNodeEdges.length < 1 && !isDataHandle(handleCategory)) {
    throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): found no connection from ${handleName}`);
  }

  return targetNodeEdges.flatMap(({ target: nodeId, targetHandle: targetHandleId }) => {
    const sourceNodeProperty = getSourceNodeProperty(
      targetHandleId,
      handleName,
      nodeId,
      nodeType,
      nodes,
      isSubgraph,
      parentNodes
    );

    const { nodeType: targetNodeType } = sourceNodeProperty;

    if (targetNodeType === "subgraph_input") {
      const { node: targetNode, nodeId, nodeType } = sourceNodeProperty;

      if (targetNode == null) {
        throw new NodeError(nodeId, nodeType, `${nodeId} (${nodeType}): target node for subgraph not found, should be impossible!`);
      }

      const {
        data: { sourceHandles = [] },
      } = targetNode;

      const [sourceHandle] = sourceHandles;

      return getSourceNodeProperties(
        nodeId,
        nodeType,
        sourceHandle,
        nodes,
        edges,
        isSubgraph,
        parentNodes,
        parentEdges
      );
    }

    return [sourceNodeProperty];
  });
};

const isMultipleSourceConnectionsSupported = (handleCategory: HandleCategory) => {
  return (
    handleCategory === "data" ||
    handleCategory === "questId" ||
    handleCategory === "taskId" ||
    handleCategory === "entity" ||
    handleCategory === "soundId" ||
    handleCategory === "playerActionAndRequirement"
  );
};

const isDataHandle = (handleCategory: HandleCategory) => {
  return (
    handleCategory === "data" ||
    handleCategory === "entity" ||
    handleCategory === "soundId" ||
    handleCategory === "playerActionAndRequirement"
  );
};

const generateQuestNodes = (
  node: Node<NodeType>,
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  initialNodeTypes: NodeType<NodeData>[],
  isSubgraph?: boolean,
  parentNodes: Node<NodeType>[] = [],
  parentEdges: Edge<EdgeType>[] = []
): QuestNode[] => {
  const {
    id: nodeId,
    data: { nodeName: nodeType, nodeCategory, nodeClass, nodeData = {}, sourceHandles = [], targetHandles = [] },
  } = node;

  try {
    if (nodeCategory === "Subgraph" || nodeCategory === "Subgraph Template") {
      return (
        nodeData.nodes?.flatMap((node: Node<NodeType>) =>
          generateQuestNodes(node, nodeData.nodes, nodeData.edges, initialNodeTypes, true, nodes, edges)
        ) ?? []
      );
    }

    const nodeInputs: QuestNodeProperty[] = targetHandles.map((targetHandle) =>
      getTargetNodeProperty(nodeId, nodeType, targetHandle, nodes, edges, isSubgraph, parentNodes, parentEdges)
    );

    const nodeOutputs: QuestNodeProperty[] = sourceHandles
      .flatMap((sourceHandle) =>
        getSourceNodeProperties(nodeId, nodeType, sourceHandle, nodes, edges, isSubgraph, parentNodes, parentEdges)
      )
      .map(({ nodeId, nodeType, propertyName }) => ({ nodeId, nodeType, propertyName }));

    const initialNodeType = initialNodeTypes.find(({ nodeName }) => nodeName === nodeType);
    const jsonSchema = initialNodeType?.nodeData?.templateData?.jsonSchema;

    const updatedNodeData: NodeData = {
      ...nodeData,
      templateData: {
        ...nodeData.templateData,
        jsonSchema,
      },
    };

    return [
      {
        nodeId,
        nodeType,
        // eslint-disable-next-line
        nodeClass: nodeClass as any,
        nodeData: updatedNodeData,
        nodeInputs,
        nodeOutputs,
      },
    ];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportRuntimeData = (
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  initialNodeTypes: NodeType<NodeData>[] = []
): QuestNode[] => {
  return nodes.flatMap((node) => generateQuestNodes(node, nodes, edges, initialNodeTypes));
};
