import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { getProfilesByDisplayName } from "../../api/users/users";
import { Profile } from "@worldwidewebb/client-auth";

interface SelectUserProps extends ChakraProps {
  initialSearchQuery: string;
  value: string;
  setValue: (value: string) => void;
}

const SelectUser: React.FC<SelectUserProps> = ({ color, initialSearchQuery, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery: string = ""): Promise<[string, string][]> => {
      let users: Profile[] = [];

      try {
        users = await getProfilesByDisplayName(searchQuery);
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return users.map(({ userId, displayName }) => [userId, displayName]);
    },
    [toast]
  );

  return (
    <AsyncSearchProvider initialSearchQuery={initialSearchQuery} onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"User"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectUser;
