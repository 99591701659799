import { useQuery } from "@tanstack/react-query";
import { getQuestOrNull } from "../../api/quests/useQuests";
import { QuestWithId } from "../../models/api/quest";

export default function useQuestOrNull(questId: string | null, questVersion?: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: quest,
  } = useQuery<QuestWithId | null>({
    queryKey: ["quest", questId, questVersion],
    queryFn: () => getQuestOrNull(questId),
    enabled: !!questId,
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    quest,
  };
}
