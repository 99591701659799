import { QuestApi, QuestPointer } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questApi = new QuestApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuestPointersForUser(questId: string, userId: string): Promise<QuestPointer[]> {
  try {
    const { data: questPointers } = await questApi.getQuestPointersForUser(userId, questId);

    return questPointers;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateQuestPointerForUser(userId: string, questPointerId: string, nodeId: string): Promise<void> {
  try {
    await questApi.movePointerForUser(userId, questPointerId, nodeId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteQuestPointerForUser(userId: string, questPointerId: string): Promise<void> {
  try {
    await questApi.deletePointerForUser(userId, questPointerId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
