import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useNpcs from "../../api/npcs/useNpcs";
import { NpcWithId } from "@worldwidewebb/client-npcs";

interface SelectNpcWithLastUsedProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
  title?: string;
  showLabel?: boolean;
}

const SelectNpcWithLastUsed: React.FC<SelectNpcWithLastUsedProps> = ({
  color,
  value,
  setValue,
  title,
  showLabel,
  ...chakraProps
}) => {
  const toast = useToast();

  const { getNpcs } = useNpcs();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let npcs: NpcWithId[] = []; // getNpcsByQuery after resolving auth bug

      try {
        npcs = await getNpcs();
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      const npcEntries: [string, string][] = npcs
        .filter(({ displayName }) =>
          searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
        )
        .map(({ npcId, displayName }) => [npcId, displayName]);

      npcEntries.unshift(["last_used", "last used"]);

      return npcEntries;
    },
    [toast, getNpcs]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={title ?? "NPC"}
        showLabel={showLabel}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectNpcWithLastUsed;
