import { Text, TextProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getDisplayNameByUserId } from "../api/users/users";

interface UserProps extends TextProps {
  userId?: string;
}

export function User({ userId, ...textProps }: UserProps) {
  const [displayName, setDisplayName] = useState<string>("<unknown user>");

  useEffect(() => {
    if (!userId) {
      return;
    }

    getDisplayNameByUserId(userId).then(setDisplayName);
  }, [userId]);

  return (
    <Text casing={"uppercase"} {...textProps}>
      {displayName}
    </Text>
  );
}
