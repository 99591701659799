import { Button, Text } from "@chakra-ui/react";
import { useReactFlowAutoLayoutProvider } from "../../context/ReactFlowAutoLayoutProvider";

function QuestUpdateLayoutButton() {
  const { updateLayout } = useReactFlowAutoLayoutProvider();

  return (
    <Button onClick={updateLayout}>
      <Text color={"white"} casing={"uppercase"}>
        Update Layout
      </Text>
    </Button>
  );
}

export default QuestUpdateLayoutButton;
