import React, { useCallback } from "react";
import useItems from "../../api/items/useItems";
import { ChakraProps, useToast } from "@chakra-ui/react";
import AsyncSelect from "./AsyncSelect";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import { ItemIntrinsicWithName } from "@worldwidewebb/client-assets";

interface SelectItemProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectItem: React.FC<SelectItemProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const { getItems } = useItems();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let items: ItemIntrinsicWithName[] = [];

      try {
        items = await getItems(searchQuery);
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return items.map(({ itemName, displayName }) => [itemName, `${displayName} (${itemName})`]);
    },
    [toast, getItems]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"Item"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectItem;
