import { Card, CardBody, Flex } from "@chakra-ui/react";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import useInitialNodeTypes from "../../../hooks/useInitialNodeTypes";
import { QuestWithId } from "../../../models/api/quest";
import { EdgeType } from "../../../models/edgeType";
import QuestStatusButton from "../../quests/QuestStatusButton";

interface NodeDeployPanelProps {
  quest?: QuestWithId;
}

function NodeDeployPanel({ quest }: NodeDeployPanelProps) {
  const { subgraphs } = useLoaderData() as {
    subgraphs: QuestSubgraphWithId[];
  };

  const initialNodeTypes = useInitialNodeTypes(subgraphs);

  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow<NodeType, EdgeType>();

  const [searchParams] = useSearchParams();

  if (quest == null) {
    return null;
  }

  const { questId } = quest;

  if (questId == null) {
    return null;
  }

  const userId = searchParams.get("userId");

  if (userId == null) {
    return null;
  }

  return (
    <Flex position={"absolute"} top={"4.5rem"} right={20}>
      <Card outline={"none"} bg={"theme.dark.background"} p={1}>
        <CardBody bg={"whiteAlpha.200"} borderRadius={5} p={3}>
          <QuestStatusButton
            questId={questId}
            userId={userId}
            nodes={getNodes()}
            setNodes={setNodes}
            edges={getEdges()}
            setEdges={setEdges}
            nodeTypes={initialNodeTypes}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default NodeDeployPanel;
