import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { FormControl, FormLabel, Input, Stack, Text } from "@chakra-ui/react";
import { StartNodeWithChildren } from "./StartNode";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import SelectLocation from "../../base/SelectLocation";
import { Location } from "../../../models/api/location";

interface NodeData extends Location {
  objectName: string;
  npcIndicator: string;
}

const SpawnEntityAtLocationNode: React.FC<NodeProps<NodeType<NodeData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const [objectName, setEntity] = useState(nodeData?.objectName ?? "");
  const [npcIndicator, setNpcIndicator] = useState(nodeData?.npcIndicator ?? "");

  const roomName = nodeData?.roomName ?? "";
  const x = nodeData?.x ?? 0;
  const y = nodeData?.y ?? 0;
  const radius = nodeData?.radius ?? 10;

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ objectName, npcIndicator }: Omit<NodeData, keyof Location>) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.objectName = objectName;
        nodeData.npcIndicator = npcIndicator;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  const handleUpdateLocation = useCallback(
    ({ roomName, x, y, radius }: Location) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as Location) ?? {};
        nodeData.roomName = roomName;
        nodeData.x = Number(x) || 0;
        nodeData.y = Number(y) || 0;
        nodeData.radius = Number(radius) || 0;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  useEffect(() => {
    handleUpdate({ objectName, npcIndicator });
  }, [handleUpdate, objectName, npcIndicator]);

  return (
    <StartNodeWithChildren {...props}>
      <Stack>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Object Name
            </Text>
          </FormLabel>
          <Input
            borderColor={color}
            borderRadius={0}
            borderWidth={2}
            color={color}
            value={objectName}
            onChange={({ target: { value } }) => setEntity(value)}
          />
        </FormControl>
        <SelectNpcIndicator value={npcIndicator} setValue={(value) => setNpcIndicator(value)} color={color} />
        <SelectLocation value={{ roomName, x, y, radius }} setValue={handleUpdateLocation} color={color} />
      </Stack>
    </StartNodeWithChildren>
  );
};

export default memo(SpawnEntityAtLocationNode);
