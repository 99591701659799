import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType, TargetHandle } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { findLastIndex, findNextIndex } from "../../../utils/handles";

interface CreateQuestLog extends NodeData {
  questTitle: string;
  questDescription: string;
  questRewardInputCount: number;
  allegianceAndAmountInputCount: number;
  skillAndExperiencePointsInputCount: number;
  skillAndLevelsInputCount: number;
}

const CreateQuestLogNode: React.FC<NodeProps<NodeType<CreateQuestLog>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const questTitle = nodeData?.questTitle;
  const questDescription = nodeData?.questDescription;
  const questRewardInputCount = nodeData?.questRewardInputCount ?? 0;
  const allegianceAndAmountInputCount = nodeData?.allegianceAndAmountInputCount ?? 0;
  const skillAndExperiencePointsInputCount = nodeData?.skillAndExperiencePointsInputCount ?? 0;
  const skillAndLevelsInputCount = nodeData?.skillAndLevelsInputCount ?? 0;

  const { register, handleSubmit, control, watch } = useForm<CreateQuestLog>({
    defaultValues: useMemo(
      () => ({
        questTitle,
        questDescription,
        questRewardInputCount,
        allegianceAndAmountInputCount,
        skillAndExperiencePointsInputCount,
        skillAndLevelsInputCount,
      }),
      [
        questTitle,
        questDescription,
        questRewardInputCount,
        allegianceAndAmountInputCount,
        skillAndExperiencePointsInputCount,
        skillAndLevelsInputCount,
      ]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    (createQuestLog: CreateQuestLog) => {
      updateNodeData(createQuestLog);
    },
    [updateNodeData]
  );

  const targetHandleNames = [
    "in",
    "npc",
    "location",
    "item_name_and_quantity",
    "allegiance_and_amount",
    "skill_and_experience_points",
    "skill_and_levels",
  ];

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const watchedItemNameAndQuantityInputCount = watch("questRewardInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "item_name_and_quantity").length;

    if (currentCount === watchedItemNameAndQuantityInputCount) {
      return;
    }

    const handleName = "item_name_and_quantity";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "item_name_and_quantity");

    if (currentCount < watchedItemNameAndQuantityInputCount) {
      const count = watchedItemNameAndQuantityInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Item Name And Quantity",
            handleName: "item_name_and_quantity",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedItemNameAndQuantityInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedItemNameAndQuantityInputCount, updateNodeTargetHandles]);

  const watchedAllegianceAndAmountInputCount = watch("allegianceAndAmountInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "allegiance_and_amount").length;

    if (currentCount === watchedAllegianceAndAmountInputCount) {
      return;
    }

    const handleName = "allegiance_and_amount";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "allegiance_and_amount");

    if (currentCount < watchedAllegianceAndAmountInputCount) {
      const count = watchedAllegianceAndAmountInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Allegiance And Amount",
            handleName: "allegiance_and_amount",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedAllegianceAndAmountInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedAllegianceAndAmountInputCount, updateNodeTargetHandles]);

  const watchedSkillAndExperiencePointsInputCount = watch("skillAndExperiencePointsInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "skill_and_experience_points").length;

    if (currentCount === watchedSkillAndExperiencePointsInputCount) {
      return;
    }

    const handleName = "skill_and_experience_points";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "skill_and_experience_points");

    if (currentCount < watchedSkillAndExperiencePointsInputCount) {
      const count = watchedSkillAndExperiencePointsInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Skill And Experience Points",
            handleName: "skill_and_experience_points",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedSkillAndExperiencePointsInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedSkillAndExperiencePointsInputCount, updateNodeTargetHandles]);

  const watchedSkillAndLevelsInputCount = watch("skillAndLevelsInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "skill_and_levels").length;

    if (currentCount === watchedSkillAndLevelsInputCount) {
      return;
    }

    const handleName = "skill_and_levels";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "skill_and_levels");

    if (currentCount < watchedSkillAndLevelsInputCount) {
      const count = watchedSkillAndLevelsInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Skill And Levels",
            handleName: "skill_and_levels",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedSkillAndLevelsInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedSkillAndLevelsInputCount, updateNodeTargetHandles]);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <Stack>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Quest Title
                </Text>
              </FormLabel>
              <Input id={"questTitle"} isRequired {...register("questTitle")} color={color} />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Quest Description
                </Text>
              </FormLabel>
              <Textarea id={"questDescription"} isRequired {...register("questDescription")} color={color} />
            </FormControl>

            <Divider />

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Rewards
                </Text>
              </FormLabel>
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Item Name And Quantity Count
                </Text>
              </FormLabel>
              <Controller
                name={"questRewardInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Allegiance And Amount Count
                </Text>
              </FormLabel>
              <Controller
                name={"allegianceAndAmountInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Skill And Experience Points Count
                </Text>
              </FormLabel>
              <Controller
                name={"skillAndExperiencePointsInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Skill And Levels Count
                </Text>
              </FormLabel>
              <Controller
                name={"skillAndLevelsInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>
          </Stack>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(CreateQuestLogNode);
