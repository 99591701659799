import { AssetsApi, CraftingStationWithId } from "@worldwidewebb/client-assets";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

const useCraftingStations = () => {
  const getCraftingStations = useCallback(async (displayName?: string): Promise<CraftingStationWithId[]> => {
    try {
      const response = await assetsApi.getCraftingStationsByQuery(displayName);
      const { data: craftingStations } = response;

      return craftingStations.sort(({ craftingStationId: a }, { craftingStationId: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getCraftingStations,
  };
};

export default useCraftingStations;
