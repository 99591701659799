import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { Checkbox, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";

interface FormData {
  showQuestIndicator: boolean;
}

const WaitForPlayerLocationNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const showQuestIndicator = formData?.showQuestIndicator;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      showQuestIndicator,
    },
    mode: "onChange",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ showQuestIndicator }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.showQuestIndicator = showQuestIndicator;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Show Quest Indicator
              </Text>
            </FormLabel>
            <Checkbox id={"showQuestIndicator"} isRequired {...register("showQuestIndicator")} color={color} />
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(WaitForPlayerLocationNode);
