import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { clampToPositiveNonZeroNumber } from "../../../utils/clampToPositiveNonZeroNumber";

interface FormData extends NodeData {
  zoom: number;
  speed: number;
}

const CameraPanToLocationNode: React.FC<NodeProps<NodeType<FormData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const zoom = nodeData?.zoom ?? 1;
  const speed = nodeData?.speed ?? 1;

  const { register, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        zoom,
        speed,
      }),
      [zoom, speed]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<FormData>(nodeId);

  const handleUpdate = useCallback(
    ({ zoom, speed }: FormData) => {
      const clampedZoom = clampToPositiveNonZeroNumber(zoom);
      const clampedSpeed = clampToPositiveNonZeroNumber(speed);

      updateNodeData({
        zoom: clampedZoom,
        speed: clampedSpeed,
      });

      setValue("zoom", clampedZoom);
      setValue("speed", clampedSpeed);
    },
    [updateNodeData, setValue]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Zoom
            </Text>
          </FormLabel>
          <Input {...register("zoom", { valueAsNumber: true })} color={color} />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Speed
            </Text>
          </FormLabel>
          <Input {...register("speed", { valueAsNumber: true })} color={color} />
        </FormControl>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(CameraPanToLocationNode);
