import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { getLuts } from "../../api/quests/luts";
import { DgsSpriteQuery } from "@worldwidewebb/client-quests";

interface SelectQuestProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectLut: React.FC<SelectQuestProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let luts: DgsSpriteQuery[] = [];

      try {
        luts = await getLuts();
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return luts
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b))
        .map(({ spriteName, displayName }) => [spriteName, displayName]);
    },
    [toast]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"LUT"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectLut;
