import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { QuestWithId } from "../../models/api/quest";
import SelectNpc from "../base/SelectNpc";
import { RepeatPeriod } from "@worldwidewebb/client-quests";
import SelectSomething from "../base/SelectSomething";
import SelectQuestFolder from "../base/SelectQuestFolder";

export const repeatPeriods: Record<RepeatPeriod, string> = {
  none: "none",
  hourly: "hourly",
  sixhourly: "every six hours",
  bidaily: "every twelve hours",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
};

interface UpdateQuestModalProps {
  isOpen: boolean;
  onClose: () => void;
  quest: QuestWithId;
  onUpdate: (quest: QuestWithId) => void;
}

const UpdateQuestModal: React.FC<UpdateQuestModalProps> = ({ isOpen, onClose, quest, onUpdate }) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState<string>(quest.name);
  const [description, setDescription] = useState<string>(quest.description ?? "");
  const [priority, setPriority] = useState<number>(quest.priority ?? 0);
  const [isTemplate, setIsTemplate] = useState<boolean>(quest.isTemplate ?? false);
  const [questNpcId, setQuestNpcId] = useState<string>(quest.questNpcId ?? "");
  const [repeatPeriod, setRepeatPeriod] = useState<RepeatPeriod>(quest.repeatPeriod ?? "none");
  const [folderId, setFolderId] = useState<string>(quest.folderId ?? "");

  const handleUpdate = useCallback(() => {
    onUpdate({
      ...quest,
      name,
      description,
      priority,
      isTemplate,
      questNpcId,
      repeatPeriod: repeatPeriod || "none",
      folderId,
    });
  }, [onUpdate, quest, name, description, priority, isTemplate, questNpcId, repeatPeriod, folderId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <ModalOverlay />

      <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <ModalHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"}>Update Quest</Text>
          </Heading>
        </ModalHeader>

        <ModalBody>
          <Stack>
            <FormControl>
              <FormLabel>Quest Name</FormLabel>
              <Input ref={initialFocusRef} value={name} onChange={({ target: { value } }) => setName(value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Quest Description</FormLabel>
              <Textarea value={description} onChange={({ target: { value } }) => setDescription(value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Quest Priority</FormLabel>
              <NumberInput
                defaultValue={0}
                step={1}
                min={0}
                value={priority}
                onChange={(value) => setPriority(Number(value))}
              >
                <NumberInputField color={"white"} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Quest NPC</FormLabel>
              <SelectNpc color={"white"} value={questNpcId} setValue={setQuestNpcId} showLabel={false} />
            </FormControl>

            <FormControl>
              <FormLabel>Quest Template</FormLabel>

              <Checkbox
                isChecked={isTemplate}
                onChange={({ target: { checked } }) => setIsTemplate(checked)}
                alignItems={"center"}
              />
            </FormControl>

            <FormControl>
              <SelectSomething
                color={"white"}
                title={"Quest Repeatability"}
                value={repeatPeriod}
                setValue={(value) => setRepeatPeriod((value || "none") as RepeatPeriod)}
                values={repeatPeriods}
              />
            </FormControl>

            <FormControl>
              <SelectQuestFolder color={"white"} value={folderId} setValue={(value) => setFolderId(value)} />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={onClose} variant={"outline"}>
            Cancel
          </Button>
          <Button onClick={handleUpdate} variant={"outline"}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateQuestModal;
