import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Stack } from "@chakra-ui/react";
import { StartNodeWithChildren } from "./StartNode";
import SelectNpc from "../../base/SelectNpc";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import { Location } from "../../../models/api/location";
import SelectLocation from "../../base/SelectLocation";

interface NodeData extends Location {
  npcId: string;
  npcIndicator: string;
}

const StartWithNpcAtLocationNode: React.FC<NodeProps<NodeType<NodeData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const [npcId, setNpcId] = useState(nodeData?.npcId ?? "");
  const [npcIndicator, setNpcIndicator] = useState(nodeData?.npcIndicator ?? "");

  const roomName = nodeData?.roomName ?? "";
  const x = nodeData?.x ?? 0;
  const y = nodeData?.y ?? 0;
  const radius = nodeData?.radius ?? 10;

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ npcId, npcIndicator }: Omit<NodeData, keyof Location>) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.npcId = npcId;
        nodeData.npcIndicator = npcIndicator;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  const handleUpdateLocation = useCallback(
    ({ roomName, x, y, radius }: Location) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as Location) ?? {};
        nodeData.roomName = roomName;
        nodeData.x = Number(x) || 0;
        nodeData.y = Number(y) || 0;
        nodeData.radius = Number(radius) || 0;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  useEffect(() => {
    handleUpdate({ npcId, npcIndicator });
  }, [handleUpdate, npcId, npcIndicator]);

  return (
    <StartNodeWithChildren {...props}>
      <Stack>
        <SelectNpc value={npcId} setValue={(value) => setNpcId(value)} color={color} />
        <SelectNpcIndicator value={npcIndicator} setValue={(value) => setNpcIndicator(value)} color={color} />
        <SelectLocation value={{ roomName, x, y, radius }} setValue={handleUpdateLocation} color={color} />
      </Stack>
    </StartNodeWithChildren>
  );
};

export default memo(StartWithNpcAtLocationNode);
