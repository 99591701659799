import { AssetsApi, ItemIntrinsicWithImage, ItemIntrinsicWithName, Sprite } from "@worldwidewebb/client-assets";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

const useItems = () => {
  const getItems = useCallback(async (displayName?: string): Promise<ItemIntrinsicWithName[]> => {
    try {
      const response = await assetsApi.getItemIntrinsicsByQuery(displayName);
      const { data: items } = response;

      return items.sort(({ itemName: a }, { itemName: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getItem = useCallback(async (itemName: string): Promise<ItemIntrinsicWithImage> => {
    try {
      const response = await assetsApi.getItemIntrinsic(itemName);
      const { data: item } = response;

      return item as ItemIntrinsicWithImage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getItemSprite = useCallback(async (spriteId: string): Promise<Sprite> => {
    try {
      const response = await assetsApi.getSprite(spriteId);
      const { data: sprite } = response;

      return sprite;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getItems,
    getItem,
    getItemSprite,
  };
};

export default useItems;
