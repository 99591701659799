import { NodeData, NodeType } from "../models/nodeType";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { JSONSchema7Definition } from "json-schema";

const jsonSchemaStartConditions: JSONSchema7Definition = {
  type: "array",
  items: {
    type: "object",
    properties: {
      id: {
        type: "string",
      },
      type: {
        type: "string",
        enum: [
          "quest_completed",
          "quest_started",
          "player_has_item",
          "player_id_is",
          "quest_flag_is_set",
          "quest_flag_is_not_set",
          "user_has_feature_flag",
          "user_does_not_have_feature_flag",
          "allegiance_is_above",
          "allegiance_is_below",
          "skill_level_is_above",
          "skill_level_is_below",
        ],
      },
      value: {},
    },
    dependencies: {
      type: {
        oneOf: [
          {
            properties: {
              type: {
                enum: ["quest_completed"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["quest_started"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["player_has_item"],
              },
              value: {
                type: "object",
                properties: {
                  itemName: {
                    type: "string",
                  },
                  quantity: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["player_id_is"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["quest_flag_is_set"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["quest_flag_is_not_set"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["user_has_feature_flag"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["user_does_not_have_feature_flag"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["allegiance_is_above"],
              },
              value: {
                type: "object",
                properties: {
                  allegianceId: {
                    type: "string",
                  },
                  allegianceAmount: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["allegiance_is_below"],
              },
              value: {
                type: "object",
                properties: {
                  allegianceId: {
                    type: "string",
                  },
                  allegianceAmount: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["skill_level_is_above"],
              },
              value: {
                type: "object",
                properties: {
                  skillId: {
                    type: "string",
                  },
                  level: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["skill_level_is_below"],
              },
              value: {
                type: "object",
                properties: {
                  skillId: {
                    type: "string",
                  },
                  level: {
                    type: "number",
                  },
                },
              },
            },
          },
        ],
      },
    },
  },
};

const jsonSchemaDialog: JSONSchema7Definition = {
  type: "object",
  properties: {
    speakerPassages: {
      type: "array",
      items: {
        type: "object",
        properties: {
          passageId: {
            type: "string",
          },
          passageType: {
            type: "string",
            enum: ["continue", "dialog", "dialog_ai"],
          },
          passage: {
            type: "string",
          },
          passagePrompt: {
            type: "string",
          },
          speakerType: {
            type: "string",
            enum: ["none", "npc", "npc_input", "player"],
          },
          speakerId: {
            type: "string",
          },
        },
      },
    },
    choices: {
      type: "array",
      items: {
        type: "object",
        properties: {
          choiceId: {
            type: "string",
          },
          choice: {
            type: "string",
          },
          isConditional: {
            type: "boolean",
          },
        },
      },
    },
    clearScrollbackHistory: {
      type: "boolean",
    },
  },
};

const useInitialNodeTypes = (subgraphs: QuestSubgraphWithId[] = []): NodeType<NodeData>[] => {
  return [
    ...subgraphs.map((subgraph) => subgraph.questEditorDataDefinition),
    {
      label: "Subgraph",
      color: "pink.400",
      nodeName: "subgraph",
      nodeCategory: "Subgraph",
      nodeClass: "flow",
      nodeData: {},
      targetHandles: [],
      isTargetHandlesEditable: true,
      ignoreTargetHandlesDiff: true,
      sourceHandles: [],
      isSourceHandlesEditable: true,
      ignoreSourceHandlesDiff: true,
      isReady: true,
    },
    {
      label: "Subgraph Input",
      color: "blue.400",
      nodeName: "subgraph_input",
      nodeCategory: "Subgraph",
      nodeClass: "data",
      nodeData: {},
      targetHandles: [],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [],
      ignoreSourceHandlesDiff: true,
      isReady: true,
    },
    {
      label: "Mod",
      color: "pink.400",
      nodeName: "mod",
      nodeCategory: "Logic",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Provides modding capabilities to the quest system",
    },
    {
      label: "Start",
      color: "red.800",
      nodeName: "start",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeData: {
        allStartConditions: [],
        anyStartConditions: [],
      },
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
      ],
      isReady: true,
      nodeDescription:
        "This quest will always try to start as long as the quest conditions are met, no matter where the player is. Good for quests that everyone will start, such as tutorials, or calendar events",
    },
    {
      label: "Start by chatting to NPC",
      color: "red.800",
      nodeName: "start_by_chatting_to_npc",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeData: {
        npcId: "",
        description: "",
        allStartConditions: [],
        anyStartConditions: [],
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              npcId: {
                type: "string",
              },
              description: {
                type: "string",
              },
              allStartConditions: jsonSchemaStartConditions,
              anyStartConditions: jsonSchemaStartConditions,
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription:
        "This quest is marked as being one that can be started from the AI. The AI will receive a list of these, and can choose to start one of them based on its chat with the player",
    },
    {
      label: "Start with NPC at Location",
      color: "red.800",
      nodeName: "start_with_npc_at_location",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeData: {
        npcId: "",
        npcIndicator: "",
        roomName: "",
        x: 0,
        y: 0,
        allStartConditions: [],
        anyStartConditions: [],
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              npcId: {
                type: "string",
              },
              npcIndicator: {
                type: "string",
                enum: ["bank", "crafting", "information", "none", "quest", "quest_complete", "vendor"],
              },
              roomName: {
                type: "string",
              },
              x: {
                type: "number",
              },
              y: {
                type: "number",
              },
              allStartConditions: jsonSchemaStartConditions,
              anyStartConditions: jsonSchemaStartConditions,
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription:
        "Spawns a quest NPC that only the player can see at a location (if an existing AI NPC exists, they will be hidden from the player and a quest NPC spawned to take their place). Clicking on them will start the quest",
    },
    {
      label: "Start If Player At Location",
      color: "red.800",
      nodeName: "start_if_player_at_location",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeData: {
        roomName: "",
        x: 0,
        y: 0,
        radius: 0,
        allStartConditions: [],
        anyStartConditions: [],
      },
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Starts the quest if the player is within some radius of a specific location in a room",
    },
    {
      label: "Start with Spawned Entity",
      color: "red.800",
      nodeName: "start_with_spawned_entity",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeData: {
        objectName: "",
        npcIndicator: "",
        roomName: "",
        x: 0,
        y: 0,
        allStartConditions: [],
        anyStartConditions: [],
      },
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
        {
          label: "Entity",
          handleName: "entity",
          handleType: "source",
          handleCategory: "entity",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription:
        "Spawns an Entity that only the player can see at a location clicking on them will start the quest. The spawned entity must already support a quest start interaction, otherwise this won't work",
    },
    {
      label: "Start with Sprite Object",
      color: "red.800",
      nodeName: "start_with_sprite_object",
      nodeCategory: "Start/End",
      nodeClass: "start",
      nodeData: {
        spriteId: "",
        entityIndicator: "",
        entityIndexZ: 0,
        roomName: "",
        x: 0,
        y: 0,
        allStartConditions: [],
        anyStartConditions: [],
      },
      sourceHandles: [
        {
          label: "Start",
          handleName: "start",
          handleType: "source",
          handleCategory: "start",
        },
        {
          label: "Entity",
          handleName: "entity",
          handleType: "source",
          handleCategory: "entity",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      nodeDescription: "Spawns a sprite object which can be interacted with to start a quest",
    },
    {
      label: "End",
      color: "red.800",
      nodeName: "end",
      nodeCategory: "Start/End",
      nodeClass: "end",
      nodeData: {
        flowTargetCount: 1,
        clearQuestState: true,
      },
      targetHandles: [
        {
          label: "End",
          handleName: "end",
          handleType: "target",
          handleCategory: "end",
        },
      ],
      ignoreTargetHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "This marks the end of a quest, and optionally cleans up any entities that have been spawned and remembered",
    },
    {
      label: "Restart",
      color: "red.800",
      nodeName: "restart",
      nodeCategory: "Start/End",
      nodeClass: "end",
      nodeData: {
        flowTargetCount: 1,
        clearQuestState: true,
      },
      targetHandles: [
        {
          label: "End",
          handleName: "end",
          handleType: "target",
          handleCategory: "end",
        },
      ],
      ignoreTargetHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "This ends the quest and then makes the quest available to start again, and optionally cleans up any entities that have been spawned and remembered",
    },
    {
      label: "Wait for Player Location",
      color: "cyan.300",
      nodeName: "wait_for_player_location",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Requires a player to be in a specific location. A quest indicator can optionally be shown",
    },
    {
      label: "Camera follow NPC",
      color: "green.600",
      nodeName: "camera_follow_npc",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        zoom: 1,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Tells the camera to begin tracking an NPC. You need to do a Camera reset to make it stop tracking",
    },
    {
      label: "Camera reset",
      color: "green.600",
      nodeName: "camera_reset",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Resets the camera state back to default (following the player)",
    },
    {
      label: "Set LUT",
      color: "green.600",
      nodeName: "set_lut",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        lutName: "",
        blend: 1,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Set the room's current lut",
    },
    {
      label: "Clear LUT",
      color: "green.600",
      nodeName: "clear_lut",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Set the room's current lut to the room default",
    },
    {
      label: "Camera pan to location",
      color: "green.600",
      nodeName: "camera_pan_to_location",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        zoom: 1,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Pan the camera to point at a specific coordinate in the room",
    },
    {
      label: "Camera snap to location",
      color: "green.600",
      nodeName: "camera_snap_to_location",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        zoom: 1,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Snaps the camera to point at a specific coordinate in the room",
    },
    {
      label: "Spawn Item",
      color: "green.600",
      nodeName: "spawn_item",
      nodeCategory: "Item",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Spawns an item pickup at a location that only the player can see, the item remains spawned until picked up",
    },
    {
      label: "Spawn Sprite Entity",
      color: "green.600",
      nodeName: "spawn_sprite_entity",
      nodeCategory: "Object",
      nodeClass: "flow",
      nodeData: {
        spriteId: "",
        entityIndexZ: 0,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Entity",
          handleName: "entity",
          handleType: "source",
          handleCategory: "entity",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription:
        "Spawns a sprite entity, which can optionally be made interactible by combining with a Wait for Interaction node",
    },
    {
      label: "Spawn Entity",
      color: "green.600",
      nodeName: "spawn_entity",
      nodeCategory: "Object",
      nodeClass: "flow",
      nodeData: {
        objectName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Entity",
          handleName: "entity",
          handleType: "source",
          handleCategory: "entity",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription: "Spawns an arbitrary entity",
    },
    {
      label: "Spawn Object",
      color: "green.600",
      nodeName: "spawn_object",
      nodeCategory: "Object",
      nodeClass: "flow",
      nodeData: {
        objectName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription: "Spawns a client-side object, useful for spawning VFX objects",
    },
    {
      label: "Player Unequip Equipment",
      color: "green.600",
      nodeName: "player_unequip_equipment",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        slotName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription: "Unequip items in a slot for a player",
    },
    {
      label: "Player Lock",
      color: "green.600",
      nodeName: "player_lock",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Locks the player in place until reset",
    },
    {
      label: "Player Reset",
      color: "green.600",
      nodeName: "player_reset",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Resets the player for example if they were locked prior",
    },
    {
      label: "NPC Unequip Equipment",
      color: "green.600",
      nodeName: "npc_unequip_equipment",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        slotName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription: "Unequip an item from an NPC",
    },
    {
      label: "Despawn Entity",
      color: "green.600",
      nodeName: "despawn_entity",
      nodeCategory: "Object",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Entity",
          handleName: "entity",
          handleType: "target",
          handleCategory: "entity",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription: "Despawns a spawned entity. This can also work on the entity from a start node",
    },
    {
      label: "Player has Item",
      color: "orange.600",
      nodeName: "player_has_item",
      nodeCategory: "Player",
      nodeClass: "logic",
      targetHandles: [
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription:
        "Outputs a boolean for whether a player has a specific quantity of items. Can be used with Condition nodes or other logic nodes",
    },
    {
      label: "Player has Door Permission",
      color: "orange.600",
      nodeName: "player_has_door_permission",
      nodeCategory: "Player",
      nodeClass: "logic",
      targetHandles: [
        {
          label: "Door",
          handleName: "door",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription:
        "Outputs a boolean for whether a player has a access to a specific door",
    },
    {
      label: "Player has Equipment",
      color: "blue.400",
      nodeName: "player_has_equipment",
      nodeCategory: "Tutorial",
      nodeClass: "data",
      nodeData: {
        slotName: "",
      },
      targetHandles: [],
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
    },
    {
      label: "Player has Item in Equipment Slot",
      color: "blue.400",
      nodeName: "player_has_item_in_equipment_slot",
      nodeCategory: "Tutorial",
      nodeClass: "data",
      nodeData: {
        slotName: "",
      },
      targetHandles: [],
      sourceHandles: [
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription:
        "Outputs a what the player has in a specific equipment slot. Can be used with Compare Items node to check if the player has a specific item equipped",
    },
    {
      label: "Take Item",
      color: "green.600",
      nodeName: "take_item",
      nodeCategory: "Item",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Success",
          handleName: "success",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Fail",
          handleName: "fail",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Takes an item from the player. If the player doesn't have the item, flow will be directed to the 'fail' port",
    },
    {
      label: "Give Item",
      color: "green.600",
      nodeName: "give_item",
      nodeCategory: "Item",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Gives an item directly to the player. It'll go into the player's inventory directly and without manifestign in the world as a pickup",
    },
    {
      label: "NPC move to Location",
      color: "green.600",
      nodeName: "npc_move_to_location",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Tells an NPC to move to a location. If the NPC is not in the room, it will be spawned at that location",
    },
    {
      label: "NPC Set Direction",
      color: "green.600",
      nodeName: "npc_set_direction",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        direction: "down",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Tells the NPC to face the specified direction",
    },
    {
      label: "NPC Face Player",
      color: "green.600",
      nodeName: "npc_face_player",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        npcId: "last_used",
        npcIdSelectionEnabled: false,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Tells the NPC to face the player",
    },
    {
      label: "NPC Face NPC",
      color: "green.600",
      nodeName: "npc_face_npc",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        targetNpcId: "",
        targetNpcSelectionEnabled: false,
        npcId: "last_used",
        npcIdSelectionEnabled: false,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Tells the NPC to face the other NPC",
    },
    {
      label: "Player teleport to Location",
      color: "green.600",
      nodeName: "player_teleport_to_location",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      nodeDescription: "Teleports the player to a location, without walking there",
    },
    {
      label: "NPC move to Player",
      color: "green.600",
      nodeName: "npc_move_to_player",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        radius: 24,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Tells an NPC to move to to the player, to within a given radius",
    },
    {
      label: "NPC move by xy",
      color: "green.600",
      nodeName: "npc_move_by_xy",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        x: 0,
        y: 0,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Tells an NPC to move by a relative amount",
    },
    {
      label: "Player move to NPC",
      color: "green.600",
      nodeName: "player_move_to_npc",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        radius: 24,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Tells an NPC to move to to the player, to within a given radius",
    },
    {
      label: "Player move to Location",
      color: "green.600",
      nodeName: "player_move_to_location",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Moves the player to a specific location",
    },
    {
      label: "NPC spawn at Location",
      color: "green.600",
      nodeName: "npc_spawn_at_location",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Spawns an NPC at that location if it doesn't already exist in the room. If the NPC already exists in the room, it will not be moved",
    },
    {
      label: "NPC reset",
      color: "green.600",
      nodeName: "npc_reset",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Despawns a quest NPC. If an AI-controlled NPC of the same ID was in the room at the time, it wil be unhidden",
    },
    {
      label: "Set Flag",
      color: "green.600",
      nodeName: "set_flag",
      nodeCategory: "Flag",
      nodeClass: "flow",
      nodeData: {
        flagName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Sets a quest flag. Flags can either be set or cleared (i.e. true or false). This flag is available to be read by other quests or other systems in the game, and can be used to communicate state across quests or systems",
    },
    {
      label: "Set Feature Flag",
      color: "green.600",
      nodeName: "set_feature_flag",
      nodeCategory: "Flag",
      nodeClass: "flow",
      nodeData: {
        flagName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Sets a feature flag for the player. Flags can either be set or cleared (i.e. true or false). This flag is used for primarily managing user permissions to what features the user has access to.",
    },
    {
      label: "Open URL",
      color: "green.600",
      nodeName: "open_url",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        url: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Opens up the specified url in a new tab for the player",
    },
    {
      label: "Set Flag To Value",
      color: "green.600",
      nodeName: "set_flag_to_value",
      nodeCategory: "Flag",
      nodeClass: "flow",
      nodeData: {
        flagName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Set a quest flag to a specific value provided by another node",
    },
    {
      label: "Clear Flag",
      color: "green.600",
      nodeName: "clear_flag",
      nodeCategory: "Flag",
      nodeClass: "flow",
      nodeData: {
        flagName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Clears a quest flag (i.e. sets it to false)",
    },
    {
      label: "Clear Feature Flag",
      color: "green.600",
      nodeName: "clear_feature_flag",
      nodeCategory: "Flag",
      nodeClass: "flow",
      nodeData: {
        flagName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Clears a feature flag (i.e. sets it to false)",
    },
    {
      label: "Flag",
      color: "blue.400",
      nodeName: "flag",
      nodeCategory: "Flag",
      nodeClass: "data",
      nodeData: {
        flagName: "",
      },
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Provides the value of a quest flag",
    },
    {
      label: "Feature Flag",
      color: "blue.400",
      nodeName: "featureFlag",
      nodeCategory: "Flag",
      nodeClass: "data",
      nodeData: {
        flagName: "",
      },
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Provides the value of a feature flag for the player",
    },
    {
      label: "Prefetch Music",
      color: "green.600",
      nodeName: "prefetch_music",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Prefetches the selected music",
    },
    {
      label: "Set Music",
      color: "green.600",
      nodeName: "set_music",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Plays a music track that will loop until stopped. This overrides the background music while playing",
    },
    {
      label: "Prefetch SFX",
      color: "green.600",
      nodeName: "prefetch_sfx",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Prefetches the selected SFX",
    },
    {
      label: "Play SFX",
      color: "green.600",
      nodeName: "play_sfx",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Sound ID",
          handleName: "sound_id",
          handleType: "source",
          handleCategory: "soundId",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Plays the selected SFX",
    },
    {
      label: "Play SFX Positional",
      color: "green.600",
      nodeName: "play_sfx_positional",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Sound ID",
          handleName: "sound_id",
          handleType: "source",
          handleCategory: "soundId",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Plays the selected SFX as a positional sound",
    },
    {
      label: "Prefetch Ambience",
      color: "green.600",
      nodeName: "prefetch_ambience",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Prefetches the selected ambience",
    },
    {
      label: "Play Ambience",
      color: "green.600",
      nodeName: "play_ambience",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Sound ID",
          handleName: "sound_id",
          handleType: "source",
          handleCategory: "soundId",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Plays the selected ambience",
    },
    {
      label: "Play Ambience Positional",
      color: "green.600",
      nodeName: "play_ambience_positional",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        fileName: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Sound ID",
          handleName: "sound_id",
          handleType: "source",
          handleCategory: "soundId",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Plays the selected ambience as a positional sound",
    },
    {
      label: "Stop Sound",
      color: "green.600",
      nodeName: "stop_sound",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Sound ID",
          handleName: "sound_id",
          handleType: "source",
          handleCategory: "soundId",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: false,
      nodeDescription: "Stops the selected sound",
    },
    {
      label: "NPC Set Sprite",
      color: "green.600",
      nodeName: "npc_set_sprite",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Open UI Element",
      color: "green.600",
      nodeName: "open_ui_element",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Opens the selected UI element for the player",
    },
    {
      label: "Highlight UI Element",
      color: "green.600",
      nodeName: "highlight_ui_element",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Highlights the selected UI element for the player",
    },
    {
      label: "Reset Highlight UI Element",
      color: "green.600",
      nodeName: "reset_highlight_ui_element",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Resets all highlighted UI elements for the player",
    },
    {
      label: "Reset Music",
      color: "green.600",
      nodeName: "reset_music",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Stops any custom music track that is playing, which will restore the background music if there was any",
    },
    {
      label: "Allegiance",
      color: "blue.400",
      nodeName: "allegiance",
      nodeCategory: "Allegiance",
      nodeClass: "data",
      nodeData: {
        allegianceId: "",
      },
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription:
        "Returns the user's allegiance to a faction, the number is between -100 and 100. Default value is 0 if player hasn't encountered the faction before",
    },
    {
      label: "Allegiance And Amount",
      color: "blue.400",
      nodeName: "allegiance_and_amount",
      nodeCategory: "Allegiance",
      nodeClass: "data",
      nodeData: {
        allegianceId: "",
        allegianceAmount: 0,
      },
      sourceHandles: [
        {
          label: "Allegiance And Amount",
          handleName: "allegiance_and_amount",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: false,
      nodeDescription: "Provides allegiance and amount to other nodes",
    },
    {
      label: "Skill And Experience Points",
      color: "blue.400",
      nodeName: "skill_and_experience_points",
      nodeCategory: "Player",
      nodeClass: "data",
      nodeData: {
        skillId: "",
        experiencePoints: 0,
      },
      sourceHandles: [
        {
          label: "Skill And Experience Points",
          handleName: "skill_and_experience_points",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: false,
      nodeDescription: "Provides skill and experience points to other nodes",
    },
    {
      label: "Skill And Levels",
      color: "blue.400",
      nodeName: "skill_and_levels",
      nodeCategory: "Player",
      nodeClass: "data",
      nodeData: {
        skillId: "",
        levels: 0,
      },
      sourceHandles: [
        {
          label: "Skill And Levels",
          handleName: "skill_and_levels",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: false,
      nodeDescription: "Provides skill and levels to other nodes",
    },
    {
      label: "Location",
      color: "blue.400",
      nodeName: "location",
      nodeCategory: "Location",
      nodeClass: "data",
      nodeData: {
        roomName: "",
        x: 0,
        y: 0,
        radius: 100,
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              roomName: {
                type: "string",
              },
              x: {
                type: "number",
              },
              y: {
                type: "number",
              },
              radius: {
                type: "number",
              },
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription:
        "Provides room and location to other nodes. The radius is used by some nodes for either specifying the trigger radius, or a radius in which something may spawn",
    },
    {
      label: "Door",
      color: "blue.400",
      nodeName: "door",
      nodeCategory: "Location",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Door",
          handleName: "door",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Provides a door to other nodes",
    },
    {
      label: "Dynamic Location",
      color: "blue.400",
      nodeName: "dynamic_location",
      nodeCategory: "Location",
      nodeClass: "data",
      nodeData: {
        precedence: "low",
        description: "",
        dynamicData: ["roomName", "x", "y", "radius"],
      },
      sourceHandles: [
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
    },
    {
      label: "Player Current Location",
      color: "blue.400",
      nodeName: "player_current_location",
      nodeCategory: "Player",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Gets the player's current location in the room at the time of evaluation",
    },
    {
      label: "Player Current Health",
      color: "blue.400",
      nodeName: "player_current_health",
      nodeCategory: "Player",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Gets the player's current health at the time of evaluation",
    },
    {
      label: "Player Current Skill Level",
      color: "blue.400",
      nodeName: "player_current_skill_level",
      nodeCategory: "Player",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Gets the player's current skill level at the time of evaluation",
    },
    {
      label: "Player Current Skill Experience Points",
      color: "blue.400",
      nodeName: "player_current_skill_experience_points",
      nodeCategory: "Player",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Gets the player's current skill experience points at the time of evaluation",
    },
    {
      label: "Split Location Properties",
      color: "orange.600",
      nodeName: "split_location_properties",
      nodeCategory: "Logic",
      nodeClass: "logic",
      sourceHandles: [
        {
          label: "Room",
          handleName: "roomName",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "Coordinates",
          handleName: "coordinates",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      targetHandles: [
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Turns a Location value into separate Room and Coordinate values",
    },
    {
      label: "Join Location Properties",
      color: "orange.600",
      nodeName: "join_location_properties",
      nodeCategory: "Logic",
      nodeClass: "logic",
      sourceHandles: [
        {
          label: "Location",
          handleName: "location",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      targetHandles: [
        {
          label: "Room",
          handleName: "roomName",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Coordinates",
          handleName: "coordinates",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Turns a Room and a Coordinate value into a Location value",
    },
    {
      label: "Room",
      color: "blue.400",
      nodeName: "room",
      nodeCategory: "Location",
      nodeClass: "data",
      nodeData: {
        roomName: "",
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              roomName: {
                type: "string",
              },
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "Room",
          handleName: "roomName",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription: "Provides a Room (room name) value",
    },
    {
      label: "Coordinates",
      color: "blue.400",
      nodeName: "coordinates",
      nodeCategory: "Location",
      nodeClass: "data",
      nodeData: {
        x: 0,
        y: 0,
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              x: {
                type: "number",
              },
              y: {
                type: "number",
              },
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "Coordinates",
          handleName: "coordinates",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription:
        "Provides an X/Y coordinate. This can be used as either absolute values, or a delta to be added to another coordinate",
    },
    {
      label: "Add Coordinates",
      color: "orange.600",
      nodeName: "add_coordinates",
      nodeCategory: "Logic",
      nodeClass: "logic",
      sourceHandles: [
        {
          label: "Coordinates",
          handleName: "coordinates",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      targetHandles: [
        {
          label: "Coordinates",
          handleName: "coordinates",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Coordinates",
          handleName: "coordinates",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      isReady: true,
      nodeDescription: "Adds two coordinates together",
    },
    {
      label: "Item Name And Quantity",
      color: "blue.400",
      nodeName: "item_name_and_quantity",
      nodeCategory: "Item",
      nodeClass: "data",
      nodeData: {
        itemName: "",
        quantity: 1,
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              itemName: {
                type: "string",
              },
              quantity: {
                type: "number",
              },
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription: "Provides item name and quantity data to other nodes",
    },
    {
      label: "Dynamic Item Name And Quantity",
      color: "blue.400",
      nodeName: "dynamic_item_name_and_quantity",
      nodeCategory: "Item",
      nodeClass: "data",
      nodeData: {
        precedence: "low",
        description: "",
        dynamicData: ["itemName", "quantity"],
      },
      sourceHandles: [
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "source",
          handleCategory: "data",
        },
      ],
    },
    {
      label: "NPC",
      color: "blue.400",
      nodeName: "npc",
      nodeCategory: "NPC Control",
      nodeClass: "data",
      nodeData: {
        npcId: "",
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              npcId: {
                type: "string",
              },
            },
          },
        },
      },
      sourceHandles: [
        {
          label: "NPC",
          handleName: "npc",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription: "Provides an NPC to other nodes",
    },
    {
      label: "Dynamic NPC",
      color: "blue.400",
      nodeName: "dynamic_npc",
      nodeCategory: "NPC Control",
      nodeClass: "data",
      nodeData: {
        precedence: "low",
        description: "",
        dynamicData: ["npcId"],
      },
      sourceHandles: [
        {
          label: "NPC",
          handleName: "npc",
          handleType: "source",
          handleCategory: "data",
        },
      ],
    },
    {
      label: "Dialog",
      color: "green.200",
      nodeName: "dialog",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        speakerPassages: [],
        choices: [],
        clearScrollbackHistory: false,
        npcInputCount: 0,
        flowTargetCount: 1,
        delayInSeconds: 0,
        templateData: {
          jsonSchema: jsonSchemaDialog,
        },
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription: "Displays a dialogue for the player at this point.",
    },
    {
      label: "AI Decision",
      color: "cyan.300",
      nodeName: "ai_decision",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        prompt: "",
        choices: [],
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Create Quest Log",
      color: "purple.600",
      nodeName: "create_quest_log",
      nodeCategory: "Quest Log",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        questTitle: "",
        questDescription: "",
        questRewardInputCount: 0,
        allegianceAndAmountInputCount: 0,
        skillAndExperiencePointsInputCount: 0,
        skillAndLevelsInputCount: 0,
        flowTargetCount: 1,
        delayInSeconds: 0,
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              questTitle: {
                type: "string",
              },
              questDescription: {
                type: "string",
              },
            },
          },
        },
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Quest ID",
          handleName: "questId",
          handleType: "source",
          handleCategory: "questId",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription:
        "Creates a quest log entry. You need to add another node to mark quest log entreis as complete and plug it into this one via the 'quest id' port",
    },
    {
      label: "Modify Player Allegiance",
      color: "green.600",
      nodeName: "modify_player_allegiance",
      nodeCategory: "Allegiance",
      nodeClass: "flow",
      nodeData: {
        allegianceId: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Changes a player's allegiance to a faction by a number. This number gets added onto a player's existing allegiance (if any). The starting value is 0, and range is -100 to 100.",
    },
    {
      label: "Increase Player Health By",
      color: "green.600",
      nodeName: "modify_player_health",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Increases the player health by the number provided. Provide a negative number to decrease the player health instead.",
    },
    {
      label: "Lock Door",
      color: "green.600",
      nodeName: "lock_door",
      nodeCategory: "Location",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Door",
          handleName: "door",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Locks a door such that a player can no longer interact with it",
    },
    {
      label: "Unlock Door",
      color: "green.600",
      nodeName: "unlock_door",
      nodeCategory: "Location",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Door",
          handleName: "door",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Unlocks a door such that a player can interact with it",
    },
    {
      label: "Reset Door",
      color: "green.600",
      nodeName: "reset_door",
      nodeCategory: "Location",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Door",
          handleName: "door",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Resets the lock status of the door to whatever the default is",
    },
    {
      label: "Player Grant Skill Experience Points",
      color: "green.600",
      nodeName: "player_grant_skill_experience_points",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Grants the player experience points towards the specified skill",
    },
    {
      label: "Player Grant Skill Level",
      color: "green.600",
      nodeName: "player_grant_skill_level",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Grants the player skill levels towards the specified skill",
    },
    {
      label: "Complete Quest Log",
      color: "purple.600",
      nodeName: "complete_quest_log",
      nodeCategory: "Quest Log",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        grantRewards: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Quest ID",
          handleName: "questId",
          handleType: "target",
          handleCategory: "questId",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Marks a quets log entry a scomplete. You need to plug the corresponding 'Create Quest Log' into this one via the 'quest id' port",
    },
    {
      label: "Create Quest Task",
      color: "purple.600",
      nodeName: "create_quest_task",
      nodeCategory: "Quest Log",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        taskDescription: "",
        questRequirementInputCount: 0,
        playerActionAndRequirementInputCount: 0,
        questWaypoint: "none",
        flowTargetCount: 1,
        delayInSeconds: 0,
        templateData: {
          jsonSchema: {
            type: "object",
            properties: {
              taskDescription: {
                type: "string",
              },
              questWaypoint: {
                type: "string",
                enum: ["none", "location", "npc"],
              },
            },
          },
        },
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Quest ID",
          handleName: "questId",
          handleType: "target",
          handleCategory: "questId",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Task ID",
          handleName: "taskId",
          handleType: "source",
          handleCategory: "taskId",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      isReadyForTemplating: true,
      nodeDescription:
        "Creates a task for the quest. You need to plug the corresponding 'Create Quest Log' into this one via the 'quest id' port so it knows which quest log to add it to",
    },
    {
      label: "Show Vendor",
      color: "cyan.300",
      nodeName: "show_vendor",
      nodeCategory: "Item",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Vendor",
          handleName: "vendor",
          handleType: "target",
          handleCategory: "vendorId",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Shows a vendor screen to the player. The player can buy multiple items, and closing the screen allows the quest to continue",
    },
    {
      label: "Show Cinema Bars",
      color: "green.600",
      nodeName: "show_cinema_bars",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Shows black cinematic bars for the player",
    },
    {
      label: "Hide Cinema Bars",
      color: "green.600",
      nodeName: "hide_cinema_bars",
      nodeCategory: "Camera and Music",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Hides black cinematic bars for the player",
    },
    {
      label: "Spawn Enemy Sequencer",
      color: "green.600",
      nodeName: "spawn_enemy_sequencer",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Sequencer ID",
          handleName: "sequencer_id",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Despawn Enemy Sequencer",
      color: "green.600",
      nodeName: "despawn_enemy_sequencer",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Sequencer ID",
          handleName: "sequencer_id",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Wait For Enemy Sequencer",
      color: "cyan.300",
      nodeName: "wait_for_enemy_sequencer",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        flowSourceCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Sequencer ID",
          handleName: "sequencer_id",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Create Session",
      color: "green.600",
      nodeName: "create_session",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "Session ID",
          handleName: "session_id",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Wait For Player In Session",
      color: "cyan.300",
      nodeName: "wait_for_player_in_session",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Session ID",
          handleName: "session_id",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Move Player To Session",
      color: "green.600",
      nodeName: "move_player_to_session",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Session ID",
          handleName: "session_id",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Show emote on NPC",
      color: "green.600",
      nodeName: "show_emote_on_npc",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        npcId: "last_used",
        npcIdSelectionEnabled: false,
        emote: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Plays an emote on an NPC",
    },
    {
      label: "Set NPC Context",
      color: "green.600",
      nodeName: "set_npc_context",
      nodeCategory: "Context",
      nodeClass: "flow",
      nodeData: {
        npcId: "last_used",
        npcIdSelectionEnabled: false,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Set Room Context",
      color: "green.600",
      nodeName: "set_room_context",
      nodeCategory: "Context",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Clear Room Context",
      color: "green.600",
      nodeName: "clear_room_context",
      nodeCategory: "Context",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Hide emote on NPC",
      color: "green.600",
      nodeName: "hide_emote_on_npc",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        npcId: "last_used",
        npcIdSelectionEnabled: false,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Hides any playing emote on an NPC",
    },
    {
      label: "Vendor",
      color: "blue.400",
      nodeName: "vendor",
      nodeCategory: "Item",
      nodeClass: "data",
      nodeData: {
        vendorId: "",
      },
      sourceHandles: [
        {
          label: "Vendor",
          handleName: "vendor",
          handleType: "source",
          handleCategory: "vendorId",
        },
      ],
      isReady: true,
      nodeDescription: "Provides a vendor ID to a 'Show Vendor' node",
    },
    {
      label: "Show Crafting Station",
      color: "cyan.300",
      nodeName: "show_crafting_station",
      nodeCategory: "Item",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Crafting Station",
          handleName: "crafting_station",
          handleType: "target",
          handleCategory: "craftingStationId",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Shows a crafting screen to the player. The player can craft multiple items, and closing the screen allows the quest to continue",
    },
    {
      label: "Crafting Station",
      color: "blue.400",
      nodeName: "crafting_station",
      nodeCategory: "Item",
      nodeClass: "data",
      nodeData: {
        craftingStationId: "",
      },
      sourceHandles: [
        {
          label: "Crafting Station",
          handleName: "crafting_station",
          handleType: "source",
          handleCategory: "craftingStationId",
        },
      ],
      isReady: true,
      nodeDescription: "Provides a crafting station ID to a 'Show Crafting Station' node",
    },
    {
      label: "Show Bank",
      color: "cyan.300",
      nodeName: "show_bank",
      nodeCategory: "Item",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Shows the player's bank",
    },
    {
      label: "Complete Quest Task",
      color: "purple.600",
      nodeName: "complete_quest_task",
      nodeCategory: "Quest Log",
      nodeClass: "flow",
      nodeData: {
        removeRequiredItems: false,
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Task ID",
          handleName: "taskId",
          handleType: "target",
          handleCategory: "taskId",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Completes a task for the quest. You need to plug the corresponding 'Create Quest Task' into this one via the 'task id' port so it knows which task to complete",
    },
    {
      label: "Player at Location",
      color: "orange.600",
      nodeName: "player_at_location",
      nodeCategory: "Player",
      nodeClass: "logic",
      targetHandles: [
        {
          label: "Location",
          handleName: "location",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Outputs a boolean for whether the player is within a radius of a specific location in a room",
    },
    {
      label: "Split",
      color: "green.600",
      nodeName: "split",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowSourceCount: 2,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Splits the execution into several parallel paths. This can be used to make sub-quests (tasks that the player can complete in any order), or to simultaneously control several NPCs. Split flows can be consolidated using a 'Join' node or ended with a 'Terminate' node.",
    },
    {
      label: "Join",
      color: "green.600",
      nodeName: "join",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        continueCondition: "any",
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Allows a split flow to be rejoined. It can be configured to wait for all of the split flows to arrive at this node, or any one of the split flows to arrive",
    },
    {
      label: "Terminate",
      color: "green.600",
      nodeName: "terminate",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        delayInSeconds: 0,
      },
      sourceHandles: [],
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Terminates a split flow node that doesn't need to be rejoined",
    },
    {
      label: "Player has completed Quest",
      color: "blue.400",
      nodeName: "player_has_completed_quest",
      nodeCategory: "Player",
      nodeClass: "data",
      nodeData: {
        questId: "",
      },
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Whether the player has completed a given quest",
    },
    {
      label: "Player has email login",
      color: "blue.400",
      nodeName: "player_has_email_login",
      nodeCategory: "Tutorial",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Returns whether the player has an email login attached to their account",
    },
    {
      label: "Player has wallet login",
      color: "blue.400",
      nodeName: "player_has_wallet_login",
      nodeCategory: "Tutorial",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Returns whether the player has a wallet login attached to their account",
    },
    {
      label: "Player has SSO login",
      color: "blue.400",
      nodeName: "player_has_sso_login",
      nodeCategory: "Tutorial",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Returns whether the player has an SSO login attached to their account",
    },
    {
      label: "Player has Discord login",
      color: "blue.400",
      nodeName: "player_has_discord_login",
      nodeCategory: "Tutorial",
      nodeClass: "data",
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Returns whether the player has a Discord login attached to their account",
    },
    {
      label: "Show email account promote",
      color: "green.600",
      nodeName: "show_email_account_promote",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Shows the email account promotion screen, asking the player to attach an email login to their account",
    },
    {
      label: "Show wallet account promote",
      color: "green.600",
      nodeName: "show_wallet_account_promote",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Shows the wallet account promotion screen, asking the player to attach a wallet login to their account",
    },
    {
      label: "Show SSO account promote",
      color: "green.600",
      nodeName: "show_sso_account_promote",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Shows the SSO account promotion screen, asking the player to attach an SSO login to their account",
    },
    {
      label: "Show Redeem Code",
      color: "green.600",
      nodeName: "show_redeem",
      nodeCategory: "Tutorial",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription:
        "Shows a redeem code modal, asking the player to input a redeem code (will be auto-populated if the player used a link with a code in it)",
    },
    {
      label: "Wait For NPC Interaction",
      color: "cyan.300",
      nodeName: "wait_for_npc_interaction",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        npcIndicator: "none",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Waits for the player to click on an NPC before continuing the quest",
    },
    {
      label: "Wait For Entity Interaction",
      color: "cyan.300",
      nodeName: "wait_for_entity_interaction",
      nodeCategory: "Object",
      nodeClass: "flow",
      nodeData: {
        entityIndicator: "none",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Entity",
          handleName: "entity",
          handleType: "target",
          handleCategory: "entity",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Wait for chat with NPC",
      color: "cyan.300",
      nodeName: "wait_for_chat_with_npc",
      nodeCategory: "NPC Control",
      nodeClass: "flow",
      nodeData: {
        npcIndicator: "none",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "NPC",
          handleName: "npc",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Condition",
      color: "green.600",
      nodeName: "condition",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "True",
          handleName: "true",
          handleType: "source",
          handleCategory: "flow",
        },
        {
          label: "False",
          handleName: "false",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Use a boolean to control the flow of the quest",
    },
    {
      label: "Wait for Condition",
      color: "cyan.300",
      nodeName: "wait_for_condition",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Waits indefinitely until the condition is true",
    },
    {
      label: "Show Player Name Change",
      color: "cyan.300",
      nodeName: "show_player_name_change",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
    },
    {
      label: "Switch",
      color: "green.600",
      nodeName: "switch",
      nodeCategory: "Flow",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowSourceCount: 1,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "0",
          handleName: "0",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Pick between multiple outputs based on the input numerical value",
    },
    {
      label: "Boolean Operator",
      color: "orange.600",
      nodeName: "boolean_operator",
      nodeCategory: "Logic",
      nodeClass: "logic",
      nodeData: {
        operator: "not",
        inputCount: 1,
      },
      targetHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Apply boolean operations on inputs",
    },
    {
      label: "Additive Operator",
      color: "orange.600",
      nodeName: "additive_operator",
      nodeCategory: "Logic",
      nodeClass: "logic",
      nodeData: {
        addInputCount: 2,
        subInputCount: 0,
      },
      targetHandles: [
        {
          label: "ADD",
          handleName: "add",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "ADD",
          handleName: "add",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Apply additive math operations on inputs",
    },
    {
      label: "Multiplicative Operator",
      color: "orange.600",
      nodeName: "multiplicative_operator",
      nodeCategory: "Logic",
      nodeClass: "logic",
      nodeData: {
        mulInputCount: 2,
        divInputCount: 0,
      },
      targetHandles: [
        {
          label: "MUL",
          handleName: "mul",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "MUL",
          handleName: "mul",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Apply multiplicative math operations on inputs",
    },
    {
      label: "Relational Operator",
      color: "orange.600",
      nodeName: "relational_operator",
      nodeCategory: "Logic",
      nodeClass: "logic",
      nodeData: {
        operator: "lt",
      },
      targetHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "Compares two numbers",
    },
    {
      label: "Compare Items",
      color: "orange.600",
      nodeName: "compare_items",
      nodeCategory: "Tutorial",
      nodeClass: "logic",
      nodeData: {
        operator: "item_names_and_quantities_are_equal",
      },
      targetHandles: [
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "target",
          handleCategory: "data",
        },
        {
          label: "Item Name And Quantity",
          handleName: "item_name_and_quantity",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription:
        "Compares two items to see if they are the same. Can compare either just the item name, or both the item name and quantity",
    },
    {
      label: "Boolean",
      color: "blue.400",
      nodeName: "boolean",
      nodeCategory: "Logic",
      nodeClass: "data",
      nodeData: {
        value: false,
      },
      sourceHandles: [
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "A boolean value",
    },
    {
      label: "Number",
      color: "blue.400",
      nodeName: "number",
      nodeCategory: "Logic",
      nodeClass: "data",
      nodeData: {
        value: 0,
      },
      sourceHandles: [
        {
          label: "Number",
          handleName: "number",
          handleType: "source",
          handleCategory: "data",
        },
      ],
      isReady: true,
      nodeDescription: "A number value",
    },
    {
      label: "Change Energy",
      color: "green.600",
      nodeName: "change_energy",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Increase or decrease user energy",
    },
    {
      label: "Set Energy Capacity Offset",
      color: "green.600",
      nodeName: "set_energy_capacity_offset",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
        {
          label: "Number",
          handleName: "number",
          handleType: "target",
          handleCategory: "data",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Set energy capacity offset for player",
    },
    {
      label: "Watch Player Action",
      color: "green.600",
      nodeName: "watch_player_action",
      nodeCategory: "Player",
      nodeClass: "flow",
      nodeData: {
        eventName: "",
        observationId: "",
        isDonePortEnabled: false,
        flowTargetCount: 1,
        delayInSeconds: 0,
      },
      targetHandles: [
        {
          label: "IN",
          handleName: "in",
          handleType: "target",
          handleCategory: "flow",
        },
      ],
      ignoreTargetHandlesDiff: true,
      sourceHandles: [
        {
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
        },
      ],
      ignoreSourceHandlesDiff: true,
      isReady: true,
      nodeDescription: "Watches the number of actions the player has made",
    },
    {
      label: "Player Action Count",
      color: "blue.400",
      nodeName: "player_action_count",
      nodeCategory: "Player",
      nodeClass: "data",
      nodeData: {
        eventName: "",
        observationId: "",
      },
      sourceHandles: [
        {
          label: "Required Count",
          handleName: "requiredCount",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "Current Count",
          handleName: "currentCount",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "Boolean",
          handleName: "boolean",
          handleType: "source",
          handleCategory: "data",
        },
        {
          label: "Player Action And Requirement",
          handleName: "playerActionAndRequirement",
          handleType: "source",
          handleCategory: "playerActionAndRequirement",
        },
      ],
      isReady: true,
      nodeDescription: "Number of actions the player has made since last watching",
    },
  ];
};

export default useInitialNodeTypes;
