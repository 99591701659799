import { AssetsApi, EquipmentSlotWithFilter } from "@worldwidewebb/client-assets";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

const useEquipmentSlots = () => {
  const getEquipmentSlots = useCallback(async (): Promise<EquipmentSlotWithFilter[]> => {
    try {
      const response = await assetsApi.getEquipmentSlots();
      const { data } = response;

      // ignore type issue
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const equipmentSlots = Object.values<EquipmentSlotWithFilter[]>(data as any).flat();

      return equipmentSlots.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getEquipmentSlots,
  };
};

export default useEquipmentSlots;
