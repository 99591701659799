import { NpcsApi, NpcWithId } from "@worldwidewebb/client-npcs";
import { axiosInstance, buildApiClientBasePath } from "..";

const npcsApi = new NpcsApi(undefined, buildApiClientBasePath("npcs"), axiosInstance);

export async function getNpc(npcId: string): Promise<NpcWithId> {
  try {
    const { data: npc } = await npcsApi.getNpc(npcId);

    return npc;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
