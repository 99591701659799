import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateQuestWithStartCondition as updateQuestWithStartConditionAsync } from "../../api/quests/quests";
import { StartConditionKind } from "../../components/quests/QuestUpdateTargetModal";
import { StartConditionType } from "../../store/quests";
import useExportRuntimeData from "../useExportRuntimeData";

interface UpdateQuestWithStartCondition<T> {
  questId: string;
  startConditionNodeId: string;
  startConditionKind: StartConditionKind;
  startConditionType: StartConditionType;
  value: T;
}

export default function useUpdateQuestWithStartCondition<T>() {
  const { exportQuestNodes } = useExportRuntimeData();

  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: updateQuestWithStartCondition } = useMutation({
    mutationFn: ({
      questId,
      startConditionNodeId,
      startConditionKind,
      startConditionType,
      value,
    }: UpdateQuestWithStartCondition<T>) =>
      updateQuestWithStartConditionAsync(
        questId,
        startConditionNodeId,
        startConditionKind,
        startConditionType,
        value,
        exportQuestNodes
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["quests"],
      });

      await queryClient.invalidateQueries({
        queryKey: ["quest"],
      });

      toast({ title: "Quest update successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestWithStartCondition,
  };
}
