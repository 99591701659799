import React, { memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import {
  Avatar,
  Button,
  Center,
  ChakraProps,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Stack,
  Tag,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useFieldArray, useForm, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ulid } from "ulid";
import { useDnD } from "../../../hooks/useDnD";
import { MdClose, MdDragHandle } from "react-icons/md";
import SelectQuest from "../../base/SelectQuest";
import SelectItem from "../../base/SelectItem";
import SelectSkill from "../../base/SelectSkill";
import { ItemNameAndQuantity } from "@worldwidewebb/client-quests";
import useItems from "../../../api/items/useItems";
import { ItemIntrinsicWithImage, Sprite } from "@worldwidewebb/client-assets";
import { useLoaderData } from "react-router";
import { QuestWithId } from "../../../models/api/quest";
import { QuestPointerContainer } from "../../quests/QuestPointerContainer";
import SelectAllegiance from "../../base/SelectAllegiance";
import SelectEquipmentSlot from "../../base/SelectEquipmentSlot";
import SelectItemType from "../../base/SelectItemType";

const StartConditionValueAsStringComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue = "",
  onUpdateStartConditionValue,
  color,
}) => {
  return (
    <FormControl>
      <FormLabel>
        <Text casing={"uppercase"} color={color}>
          Value
        </Text>
      </FormLabel>
      <Input value={startConditionValue} onChange={({ target: { value } }) => onUpdateStartConditionValue(value)} />
    </FormControl>
  );
};

const StartConditionValueAllegianceComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  const [allegianceId, setAllegianceId] = useState<string>(startConditionValue?.allegianceId);
  const [allegianceAmount, setAllegianceAmount] = useState<number>(startConditionValue?.allegianceAmount ?? 0);

  useEffect(() => {
    onUpdateStartConditionValue({ allegianceId, allegianceAmount });
  }, [onUpdateStartConditionValue, allegianceId, allegianceAmount]);

  const min = -100;
  const max = +100;

  return (
    <FormControl>
      <SelectAllegiance color={color} value={allegianceId} setValue={setAllegianceId} />

      <FormControl>
        <FormLabel>
          <Text casing={"uppercase"} color={color}>
            Allegiance
          </Text>
        </FormLabel>
        <NumberInput
          defaultValue={0}
          step={5}
          min={min}
          max={max}
          value={allegianceAmount}
          onChange={(value) => setAllegianceAmount(Number(value))}
        >
          <NumberInputField color={color} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </FormControl>
  );
};

const StartConditionValueQuestCompletedComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  return (
    <SelectQuest value={startConditionValue} setValue={(value) => onUpdateStartConditionValue(value)} color={color} />
  );
};

const StartConditionValueQuestStartedComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  return (
    <SelectQuest value={startConditionValue} setValue={(value) => onUpdateStartConditionValue(value)} color={color} />
  );
};

const StartConditionValuePlayerHasItemComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  const [itemName, setItemName] = useState<string>(startConditionValue?.itemName);
  const [quantity, setQuantity] = useState<number>(startConditionValue?.quantity ?? 1);

  useEffect(() => {
    onUpdateStartConditionValue({ itemName, quantity });
  }, [onUpdateStartConditionValue, itemName, quantity]);

  return (
    <FormControl>
      <SelectItem value={itemName} setValue={(value) => setItemName(value)} color={color} />
      <FormControl>
        <FormLabel>
          <Text casing={"uppercase"} color={color}>
            Quantity
          </Text>
        </FormLabel>
        <NumberInput
          defaultValue={1}
          step={1}
          min={1}
          value={quantity}
          onChange={(value) => setQuantity(Number(value))}
        >
          <NumberInputField color={color} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </FormControl>
  );
};

const StartConditionValuePlayerHasItemTypeComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  const [itemType, setItemType] = useState<string>(startConditionValue?.itemType);

  useEffect(() => {
    onUpdateStartConditionValue({ itemType });
  }, [onUpdateStartConditionValue, itemType]);

  return (
    <FormControl>
      <SelectItemType value={itemType} setValue={(value) => setItemType(value)} color={color} />
    </FormControl>
  );
};

const StartConditionValuePlayerHasEquipmentInSlotComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  const [slotName, setSlotName] = useState<string>(startConditionValue?.slotName);

  useEffect(() => {
    onUpdateStartConditionValue({ slotName });
  }, [onUpdateStartConditionValue, slotName]);

  return (
    <FormControl>
      <SelectEquipmentSlot value={slotName} setValue={(value) => setSlotName(value)} color={color} />
    </FormControl>
  );
};

const StartConditionValueSkillLevelComponent: React.FC<StartConditionValueComponentProps> = ({
  startConditionValue,
  onUpdateStartConditionValue,
  color,
}) => {
  const [skillId, setSkillId] = useState<string>(startConditionValue?.skillId);
  const [level, setLevel] = useState<number>(startConditionValue?.level ?? 0);

  useEffect(() => {
    onUpdateStartConditionValue({ skillId, level });
  }, [onUpdateStartConditionValue, skillId, level]);

  return (
    <FormControl>
      <SelectSkill value={skillId} setValue={(value) => setSkillId(value)} color={color} />
      <FormControl>
        <FormLabel>
          <Text casing={"uppercase"} color={color}>
            Skill Level
          </Text>
        </FormLabel>
        <NumberInput defaultValue={1} step={1} min={0} value={level} onChange={(value) => setLevel(Number(value))}>
          <NumberInputField color={color} />
          <NumberInputStepper>
            <NumberIncrementStepper color={color} />
            <NumberDecrementStepper color={color} />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </FormControl>
  );
};

type StartConditionType =
  | "quest_completed"
  | "quest_started"
  | "player_has_item"
  | "player_has_item_type"
  | "player_has_equipment_in_slot"
  | "player_id_is"
  | "quest_flag_is_set"
  | "quest_flag_is_not_set"
  | "user_has_feature_flag"
  | "user_does_not_have_feature_flag"
  | "allegiance_is_above"
  | "allegiance_is_below"
  | "skill_level_is_above"
  | "skill_level_is_below";

interface StartConditionDefinition {
  type: StartConditionType;
  displayName: string;
  valueComponent: React.FC<StartConditionValueComponentProps>;
}

const startConditionDefinitions: StartConditionDefinition[] = [
  {
    type: "quest_completed",
    displayName: "Quest Completed",
    valueComponent: StartConditionValueQuestCompletedComponent,
  },
  {
    type: "quest_started",
    displayName: "Quest Started",
    valueComponent: StartConditionValueQuestStartedComponent,
  },
  {
    type: "player_has_item",
    displayName: "Player Has Item",
    valueComponent: StartConditionValuePlayerHasItemComponent,
  },
  {
    type: "player_has_item_type",
    displayName: "Player Has Item Type",
    valueComponent: StartConditionValuePlayerHasItemTypeComponent,
  },
  {
    type: "player_has_equipment_in_slot",
    displayName: "Player Has Equipment In Slot",
    valueComponent: StartConditionValuePlayerHasEquipmentInSlotComponent,
  },
  {
    type: "player_id_is",
    displayName: "Player ID is",
    valueComponent: StartConditionValueAsStringComponent,
  },
  {
    type: "quest_flag_is_set",
    displayName: "Quest Flag Is Set",
    valueComponent: StartConditionValueAsStringComponent,
  },
  {
    type: "quest_flag_is_not_set",
    displayName: "Quest Flag Is Not Set",
    valueComponent: StartConditionValueAsStringComponent,
  },
  {
    type: "user_has_feature_flag",
    displayName: "User Has Feature Flag",
    valueComponent: StartConditionValueAsStringComponent,
  },
  {
    type: "user_does_not_have_feature_flag",
    displayName: "User Does Not Have Feature Flag",
    valueComponent: StartConditionValueAsStringComponent,
  },
  {
    type: "allegiance_is_above",
    displayName: "Allegiance Is Above",
    valueComponent: StartConditionValueAllegianceComponent,
  },
  {
    type: "allegiance_is_below",
    displayName: "Allegiance Is Below",
    valueComponent: StartConditionValueAllegianceComponent,
  },
  {
    type: "skill_level_is_above",
    displayName: "Skill Level Is Above Or Equal To",
    valueComponent: StartConditionValueSkillLevelComponent,
  },
  {
    type: "skill_level_is_below",
    displayName: "Skill Level Is Below",
    valueComponent: StartConditionValueSkillLevelComponent,
  },
];

interface StartCondition {
  type: StartConditionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

interface StartConditionWithId extends StartCondition {
  id: string;
}

interface FormData {
  allStartConditions: StartConditionWithId[];
  anyStartConditions: StartConditionWithId[];
}

interface StartConditionValueComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startConditionValue: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateStartConditionValue: (startConditionValue: any) => void;
  color?: string;
}

interface StartConditionComponentProps {
  group: keyof FormData;
  index: number;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  register: UseFormRegister<FormData>;
  setValue: UseFormSetValue<FormData>;
  color?: string;
  startConditionType: StartConditionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startConditionValue: any;
}

const StartConditionComponent: React.FC<StartConditionComponentProps> = ({
  group,
  index,
  updateIndex,
  removeIndex,
  register,
  setValue,
  color,
  startConditionType: currentStartConditionType,
  startConditionValue: currentStartConditionValue,
}) => {
  const { handlerId, isDragging, ref, refPreview } = useDnD(group, index, updateIndex);

  const [startConditionType, setStartConditionType] = useState<StartConditionType>(currentStartConditionType);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [startConditionValue, setStartConditionValue] = useState<any>(currentStartConditionValue);

  const StartConditionValueComponent = useMemo<React.FC<StartConditionValueComponentProps>>(() => {
    return (
      startConditionDefinitions.find(({ type }) => type === startConditionType)?.valueComponent ??
      StartConditionValueQuestCompletedComponent
    );
  }, [startConditionType]);

  useEffect(() => {
    setValue(`${group}.${index}.value`, startConditionValue);
  }, [startConditionValue]);

  const handleSetStartConditionType = useCallback((startConditionType: StartConditionType) => {
    setStartConditionType(startConditionType);
    setStartConditionValue(undefined);
  }, []);

  return (
    <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
      <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
        <Icon as={MdDragHandle} />
      </Center>
      <Stack flexGrow={1}>
        <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
          <IconButton
            size={"xs"}
            color={"white"}
            variant={"ghost"}
            icon={<Icon as={MdClose} />}
            aria-label={"delete start condition"}
            onClick={() => removeIndex(index)}
          />
        </Flex>
        <Stack p={2}>
          <HStack alignItems={"flex-start"}>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={"white"}>
                  Type
                </Text>
              </FormLabel>
              <Select
                id={`${group}.${index}.type`}
                textTransform={"uppercase"}
                {...register(`${group}.${index}.type`, { required: true })}
                onChange={({ target: { value } }) => handleSetStartConditionType(value as StartConditionType)}
              >
                {startConditionDefinitions &&
                  startConditionDefinitions.map(({ type, displayName }, index) => (
                    <option key={index} value={type}>
                      {displayName}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <StartConditionValueComponent
                startConditionValue={startConditionValue}
                onUpdateStartConditionValue={setStartConditionValue}
                color={"white"}
              />
            </FormControl>
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
};

interface StartNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  allStartConditions: StartConditionWithId[];
  anyStartConditions: StartConditionWithId[];
  onUpdateAllStartConditions: (allStartConditions: StartConditionWithId[]) => void;
  onUpdateAnyStartConditions: (anyStartConditions: StartConditionWithId[]) => void;
  color?: string;
}

const StartNodeConfigurationModal: React.FC<StartNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  allStartConditions,
  anyStartConditions,
  onUpdateAllStartConditions,
  onUpdateAnyStartConditions,
  color,
}) => {
  const { reset, register, setValue, control, handleSubmit } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        allStartConditions,
        anyStartConditions,
      }),
      [allStartConditions, anyStartConditions]
    ),
    mode: "onBlur",
  });

  useEffect(() => {
    reset({
      allStartConditions,
      anyStartConditions,
    });
  }, [allStartConditions, anyStartConditions]);

  const {
    fields: allStartConditionFields,
    insert: insertAllStartCondition,
    remove: removeAllStartCondition,
    move: moveAllStartCondition,
  } = useFieldArray({
    name: "allStartConditions",
    control,
  });

  const {
    fields: anyStartConditionFields,
    insert: insertAnyStartCondition,
    remove: removeAnyStartCondition,
    move: moveAnyStartCondition,
  } = useFieldArray({
    name: "anyStartConditions",
    control,
  });

  const handleUpdate = useCallback(
    ({ allStartConditions, anyStartConditions }: FormData) => {
      onUpdateAllStartConditions(allStartConditions);
      onUpdateAnyStartConditions(anyStartConditions);

      onClose();
    },
    [onClose, onUpdateAllStartConditions, onUpdateAnyStartConditions]
  );

  const handleInsertAllStartCondition = useCallback(
    (index: number) => {
      insertAllStartCondition(index, {
        id: ulid(),
        type: "quest_completed",
        value: "",
      });
    },
    [insertAllStartCondition]
  );

  const handleRemoveAllStartCondition = useCallback(
    (index: number) => {
      removeAllStartCondition(index);
    },
    [removeAllStartCondition]
  );

  const handleMoveAllStartCondition = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveAllStartCondition(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveAllStartCondition]
  );

  const handleInsertAnyStartCondition = useCallback(
    (index: number) => {
      insertAnyStartCondition(index, {
        id: ulid(),
        type: "quest_completed",
        value: "",
      });
    },
    [insertAnyStartCondition]
  );

  const handleRemoveAnyStartCondition = useCallback(
    (index: number) => {
      removeAnyStartCondition(index);
    },
    [removeAnyStartCondition]
  );

  const handleMoveAnyStartCondition = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveAnyStartCondition(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveAnyStartCondition]
  );

  const handleCancel = useCallback(() => {
    reset({
      allStartConditions,
      anyStartConditions,
    });

    onClose();
  }, [onClose, reset, allStartConditions, anyStartConditions]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <Stack>
              <Text color={"white"}>All Start Conditions</Text>
              <Stack>
                {allStartConditionFields && allStartConditionFields.length === 0 ? (
                  <Center>
                    <Text color={"white"}>No start conditions (insert new)</Text>
                  </Center>
                ) : (
                  allStartConditionFields.map(({ id, type, value }, index) => (
                    <StartConditionComponent
                      key={id}
                      group={"allStartConditions"}
                      index={index}
                      updateIndex={handleMoveAllStartCondition}
                      removeIndex={handleRemoveAllStartCondition}
                      register={register}
                      setValue={setValue}
                      color={color}
                      startConditionType={type}
                      startConditionValue={value}
                    />
                  ))
                )}
              </Stack>

              <Flex justifyContent={"flex-end"}>
                <Button
                  onClick={() => handleInsertAllStartCondition(allStartConditionFields.length)}
                  variant={"outline"}
                >
                  <Text color={color} textTransform={"uppercase"}>
                    Insert New
                  </Text>
                </Button>
              </Flex>
            </Stack>

            <Stack>
              <Text color={"white"}>Any Start Conditions</Text>
              <Stack>
                {anyStartConditionFields && anyStartConditionFields.length === 0 ? (
                  <Center>
                    <Text color={"white"}>No start conditions (insert new)</Text>
                  </Center>
                ) : (
                  anyStartConditionFields.map(({ id, type, value }, index) => (
                    <StartConditionComponent
                      key={id}
                      group={"anyStartConditions"}
                      index={index}
                      updateIndex={handleMoveAnyStartCondition}
                      removeIndex={handleRemoveAnyStartCondition}
                      register={register}
                      setValue={setValue}
                      color={color}
                      startConditionType={type}
                      startConditionValue={value}
                    />
                  ))
                )}
              </Stack>

              <Flex justifyContent={"flex-end"}>
                <Button
                  onClick={() => handleInsertAnyStartCondition(anyStartConditionFields.length)}
                  variant={"outline"}
                >
                  <Text color={color} textTransform={"uppercase"}>
                    Insert New
                  </Text>
                </Button>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

interface QuestComponentProps extends ChakraProps {
  questId: string;
}

export const QuestComponent: React.FC<QuestComponentProps> = ({ color, questId }) => {
  const { quests } = useLoaderData() as {
    quests: QuestWithId[];
  };

  const displayName = useMemo(() => quests.find((quest) => quest.questId === questId)?.name, [quests, questId]);

  return (
    <Text color={color} casing={"uppercase"}>
      {displayName}
    </Text>
  );
};

interface ItemNameAndQuantityComponentProps {
  itemName: string;
  quantity: number;
}

export const ItemNameAndQuantityComponent: React.FC<ItemNameAndQuantityComponentProps> = ({ itemName, quantity }) => {
  const [item, setItem] = useState<ItemIntrinsicWithImage | null>(null);
  const [icon, setIcon] = useState<Sprite | null>(null);
  const { getItem, getItemSprite } = useItems();

  useEffect(() => {
    getItem(itemName)
      .then((item) => {
        setItem(item);
      })
      .catch(() => setItem(null));
  }, []);

  useEffect(() => {
    if (item == null) {
      return;
    }

    const { spriteId } = item;

    if (spriteId == null) {
      return;
    }

    getItemSprite(spriteId)
      .then((sprite) => {
        setIcon(sprite);
      })
      .catch(() => setIcon(null));
  }, [item]);

  if (item == null) {
    return <Spinner size={"md"} color={"white"} />;
  }

  const { displayName } = item;

  return (
    <HStack alignItems={"center"}>
      <Avatar
        bg={"transparent"}
        borderColor={"transparent"}
        size={"md"}
        src={icon?.imageUri}
        sx={{ imageRendering: "pixelated" }}
      />

      <Text color={"white"} fontWeight={500}>
        {quantity} &times; {displayName}
      </Text>
    </HStack>
  );
};

interface QuestNodeQuestTriggersProps extends ChakraProps {
  allStartConditions: StartConditionWithId[];
  anyStartConditions: StartConditionWithId[];
}

const QuestNodeQuestTriggers: React.FC<QuestNodeQuestTriggersProps> = ({
  color,
  allStartConditions,
  anyStartConditions,
}) => {
  const allQuestsCompleted: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "quest_completed").map(({ value }) => value),
    [allStartConditions]
  );

  const anyQuestsCompleted: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "quest_completed").map(({ value }) => value),
    [anyStartConditions]
  );

  const allQuestsStarted: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "quest_started").map(({ value }) => value),
    [allStartConditions]
  );

  const anyQuestsStarted: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "quest_started").map(({ value }) => value),
    [anyStartConditions]
  );

  const allRequiredItems: ItemNameAndQuantity[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "player_has_item").map(({ value }) => value),
    [allStartConditions]
  );

  const anyRequiredItems: ItemNameAndQuantity[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "player_has_item").map(({ value }) => value),
    [anyStartConditions]
  );

  const allPlayerIds: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "player_id_is").map(({ value }) => value),
    [allStartConditions]
  );

  const anyPlayerIds: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "player_id_is").map(({ value }) => value),
    [anyStartConditions]
  );

  const allQuestFlagsSet: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "quest_flag_is_set").map(({ value }) => value),
    [allStartConditions]
  );

  const anyQuestFlagsSet: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "quest_flag_is_set").map(({ value }) => value),
    [anyStartConditions]
  );

  const allQuestFlagsNotSet: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "quest_flag_is_not_set").map(({ value }) => value),
    [allStartConditions]
  );

  const anyQuestFlagsNotSet: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "quest_flag_is_not_set").map(({ value }) => value),
    [anyStartConditions]
  );

  const allUserFeatureFlagsSet: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "user_has_feature_flag").map(({ value }) => value),
    [allStartConditions]
  );

  const anyUserFeatureFlagsSet: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "user_has_feature_flag").map(({ value }) => value),
    [anyStartConditions]
  );

  const allUserFeatureFlagsNotSet: string[] = useMemo(
    () => allStartConditions.filter(({ type }) => type === "user_does_not_have_feature_flag").map(({ value }) => value),
    [allStartConditions]
  );

  const anyUserFeatureFlagsNotSet: string[] = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "user_does_not_have_feature_flag").map(({ value }) => value),
    [anyStartConditions]
  );

  const allAllegianceIsAbove = useMemo(
    () => allStartConditions.filter(({ type }) => type === "allegiance_is_above").map(({ value }) => value),
    [allStartConditions]
  );

  const anyAllegianceIsAbove = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "allegiance_is_above").map(({ value }) => value),
    [anyStartConditions]
  );

  const allAllegianceIsBelow = useMemo(
    () => allStartConditions.filter(({ type }) => type === "allegiance_is_below").map(({ value }) => value),
    [allStartConditions]
  );

  const anyAllegianceIsBelow = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "allegiance_is_below").map(({ value }) => value),
    [anyStartConditions]
  );

  const allSkillLevelsIsAbove = useMemo(
    () => allStartConditions.filter(({ type }) => type === "skill_level_is_above").map(({ value }) => value),
    [allStartConditions]
  );

  const anySkillLevelsIsAbove = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "skill_level_is_above").map(({ value }) => value),
    [anyStartConditions]
  );

  const allSkillLevelsIsBelow = useMemo(
    () => allStartConditions.filter(({ type }) => type === "skill_level_is_below").map(({ value }) => value),
    [allStartConditions]
  );

  const anySkillLevelsIsBelow = useMemo(
    () => anyStartConditions.filter(({ type }) => type === "skill_level_is_below").map(({ value }) => value),
    [anyStartConditions]
  );

  return (
    <Stack>
      <Heading size={"sm"}>
        <Text color={color} casing={"uppercase"}>
          Start Conditions
        </Text>
      </Heading>

      {allQuestsCompleted.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All Quests Completed
            </Text>
          </Heading>
          <Wrap>
            {allQuestsCompleted.map((questId) => (
              <WrapItem key={questId}>
                <QuestComponent color={"white"} questId={questId} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyQuestsCompleted.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any Quests Completed
            </Text>
          </Heading>
          <Wrap>
            {anyQuestsCompleted.map((questId) => (
              <WrapItem key={questId}>
                <QuestComponent color={"white"} questId={questId} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allQuestsStarted.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All Quests Started
            </Text>
          </Heading>
          <Wrap>
            {allQuestsStarted.map((questId) => (
              <WrapItem key={questId}>
                <QuestComponent color={"white"} questId={questId} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyQuestsStarted.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any Quests Started
            </Text>
          </Heading>
          <Wrap>
            {anyQuestsStarted.map((questId) => (
              <WrapItem key={questId}>
                <QuestComponent color={"white"} questId={questId} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allRequiredItems.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All Items Required
            </Text>
          </Heading>
          <Wrap>
            {allRequiredItems.map(({ itemName, quantity }) => (
              <WrapItem key={itemName}>
                <ItemNameAndQuantityComponent itemName={itemName} quantity={quantity} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyRequiredItems.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any Items Required
            </Text>
          </Heading>
          <Wrap>
            {anyRequiredItems.map(({ itemName, quantity }) => (
              <WrapItem key={itemName}>
                <ItemNameAndQuantityComponent itemName={itemName} quantity={quantity} />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allPlayerIds.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All player IDs required
            </Text>
          </Heading>
          <Wrap>
            {allPlayerIds.map((playerId) => (
              <WrapItem key={playerId}>
                <Text color={"white"} casing={"uppercase"}>
                  {playerId}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyPlayerIds.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any player IDs required
            </Text>
          </Heading>
          <Wrap>
            {anyPlayerIds.map((playerId) => (
              <WrapItem key={playerId}>
                <Text color={"white"} casing={"uppercase"}>
                  {playerId}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allQuestFlagsSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All quest flags set
            </Text>
          </Heading>
          <Wrap>
            {allQuestFlagsSet.map((questFlag) => (
              <WrapItem key={questFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {questFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyQuestFlagsSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any quest flags set
            </Text>
          </Heading>
          <Wrap>
            {anyQuestFlagsSet.map((questFlag) => (
              <WrapItem key={questFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {questFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allQuestFlagsNotSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All quest flags not set
            </Text>
          </Heading>
          <Wrap>
            {allQuestFlagsNotSet.map((questFlag) => (
              <WrapItem key={questFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {questFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyQuestFlagsNotSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any quest flags not set
            </Text>
          </Heading>
          <Wrap>
            {anyQuestFlagsNotSet.map((questFlag) => (
              <WrapItem key={questFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {questFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allUserFeatureFlagsSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All user feature flags set
            </Text>
          </Heading>
          <Wrap>
            {allUserFeatureFlagsSet.map((featureFlag) => (
              <WrapItem key={featureFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {featureFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyUserFeatureFlagsSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any user feature flags set
            </Text>
          </Heading>
          <Wrap>
            {anyUserFeatureFlagsSet.map((featureFlag) => (
              <WrapItem key={featureFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {featureFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allUserFeatureFlagsNotSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All user feature flags not set
            </Text>
          </Heading>
          <Wrap>
            {allUserFeatureFlagsNotSet.map((featureFlag) => (
              <WrapItem key={featureFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {featureFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyUserFeatureFlagsNotSet.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any user feature flags not set
            </Text>
          </Heading>
          <Wrap>
            {anyUserFeatureFlagsNotSet.map((featureFlag) => (
              <WrapItem key={featureFlag}>
                <Text color={"white"} casing={"uppercase"}>
                  {featureFlag}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allAllegianceIsAbove.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All allegiances above
            </Text>
          </Heading>
          <Wrap>
            {allAllegianceIsAbove.map(({ allegianceId, allegianceAmount }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {allegianceId} ({allegianceAmount})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyAllegianceIsAbove.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any allegiances above
            </Text>
          </Heading>
          <Wrap>
            {anyAllegianceIsAbove.map(({ allegianceId, allegianceAmount }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {allegianceId} ({allegianceAmount})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allAllegianceIsBelow.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All allegiances below
            </Text>
          </Heading>
          <Wrap>
            {allAllegianceIsBelow.map(({ allegianceId, allegianceAmount }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {allegianceId} ({allegianceAmount})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anyAllegianceIsBelow.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any allegiances below
            </Text>
          </Heading>
          <Wrap>
            {anyAllegianceIsBelow.map(({ allegianceId, allegianceAmount }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {allegianceId} ({allegianceAmount})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allSkillLevelsIsAbove.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All allegiances above
            </Text>
          </Heading>
          <Wrap>
            {allSkillLevelsIsAbove.map(({ skillId, level }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {skillId} ({level})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anySkillLevelsIsAbove.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any allegiances above
            </Text>
          </Heading>
          <Wrap>
            {anySkillLevelsIsAbove.map(({ skillId, level }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {skillId} ({level})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {allSkillLevelsIsBelow.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              All skill levels above or equal to
            </Text>
          </Heading>
          <Wrap>
            {allSkillLevelsIsBelow.map(({ skillId, level }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {skillId} ({level})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}

      {anySkillLevelsIsBelow.length !== 0 && (
        <>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Any skill levels below
            </Text>
          </Heading>
          <Wrap>
            {anySkillLevelsIsBelow.map(({ skillId, level }, index) => (
              <WrapItem key={index}>
                <Text color={"white"} casing={"uppercase"}>
                  {skillId} ({level})
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
    </Stack>
  );
};

const StartNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <StartNodeWithChildren {...props} />;
};

export const StartNodeWithChildren: React.FC<NodeProps<NodeType> & PropsWithChildren> = ({ children, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const formData = nodeData as FormData | undefined;
  const allStartConditions = formData?.allStartConditions ?? [];
  const anyStartConditions = formData?.anyStartConditions ?? [];

  const reactFlow = useReactFlow();

  const handleUpdateAllStartConditions = useCallback(
    (allStartConditions: StartConditionWithId[]) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.allStartConditions = allStartConditions;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  const handleUpdateAnyStartConditions = useCallback(
    (anyStartConditions: StartConditionWithId[]) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.anyStartConditions = anyStartConditions;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  const startConditionCount = allStartConditions.length + anyStartConditions.length;
  const hasStartConditions = Boolean(startConditionCount);

  const overview = hasStartConditions && (
    <VStack p={2} alignItems={"flex-start"}>
      <Tag>
        {startConditionCount} condition{startConditionCount !== 1 ? "s" : ""} set
      </Tag>
    </VStack>
  );

  return (
    <>
      <QuestPointerContainer mx={1} mb={1} color={color} nodeId={nodeId} />

      <BaseNodeWithChildren overview={overview} {...props}>
        <Stack p={2}>
          <QuestNodeQuestTriggers
            color={color}
            allStartConditions={allStartConditions}
            anyStartConditions={anyStartConditions}
          />

          {children && children}

          <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
            Configure Conditions
          </Button>
        </Stack>
      </BaseNodeWithChildren>

      <StartNodeConfigurationModal
        isOpen={isOpen}
        onClose={onClose}
        allStartConditions={allStartConditions}
        anyStartConditions={anyStartConditions}
        onUpdateAllStartConditions={handleUpdateAllStartConditions}
        onUpdateAnyStartConditions={handleUpdateAnyStartConditions}
        color={color}
      />
    </>
  );
};

export default memo(StartNode);
