import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { QuestEditorCommitWithId } from "@worldwidewebb/client-quests";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { getCommit } from "../../../api/quests/questCommits";
import { transformRawQuest } from "../../../api/quests/useQuests";
import { useQuestProvider } from "../../../context/QuestContext";
import ConfirmModal from "../../modals/ConfirmModal";
import QuestCommitPreview from "./QuestCommitPreview";

interface QuestCommitPreviewModalProps {
  questId: string;
  questEditorCommitId: string;
  isOpen: boolean;
  onClose: () => void;
}

function QuestCommitPreviewModal({ questId, questEditorCommitId, isOpen, onClose }: QuestCommitPreviewModalProps) {
  const [commit, setCommit] = useState<QuestEditorCommitWithId | null>(null);

  const { loadQuest } = useQuestProvider();

  const handleLoadQuest = useCallback(() => {
    loadQuest(transformRawQuest(commit?.quest));

    onClose();
  }, [loadQuest, commit, onClose]);

  const toast = useToast();

  useEffect(() => {
    getCommit(questId, questEditorCommitId)
      .then(setCommit)
      .catch((error) => {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      });
  }, [questEditorCommitId, toast]);

  const questEditorData = commit?.quest.questEditorData;

  const questData = useMemo(
    () => (typeof questEditorData === "string" ? JSON.parse(questEditorData) : questEditorData),
    [questEditorData]
  );

  const nodes = questData?.nodes;
  const edges = questData?.edges;

  const questEditorDataDefinitions = commit?.quest.questEditorDataDefinitions;

  const initialNodeTypes = useMemo(
    () =>
      typeof questEditorDataDefinitions === "string"
        ? JSON.parse(questEditorDataDefinitions)
        : questEditorDataDefinitions,
    [questEditorDataDefinitions]
  );

  if (commit == null) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
      <ModalOverlay />

      <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <ModalHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"} casing={"uppercase"}>
              Preview ({questEditorCommitId})
            </Text>
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <ReactFlowProvider>
            <QuestCommitPreview initialNodes={nodes} initialEdges={edges} initialNodeTypes={initialNodeTypes} />
          </ReactFlowProvider>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={onClose}>
            <Text color={"white"} casing={"uppercase"}>
              Cancel
            </Text>
          </Button>
          <ConfirmModal color={"white"} title={"Restore"} onConfirm={() => handleLoadQuest()}>
            Restore this version and overwrite your local version?
          </ConfirmModal>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default memo(QuestCommitPreviewModal);
