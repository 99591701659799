import { AssetsApi } from "@worldwidewebb/client-assets";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

const useMusic = () => {
  const getMusic = useCallback(async (searchQuery?: string): Promise<string[]> => {
    try {
      const response = await assetsApi.getAudioMetadatasForTypeByQuery("music", searchQuery);
      const { data } = response;

      return data.map(({ filename }) => filename).sort((a, b) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getMusic,
  };
};

export default useMusic;
