import { Door, WorldApi } from "@worldwidewebb/client-world";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const worldApi = new WorldApi(undefined, buildApiClientBasePath("world"), axiosInstance);

const useDoors = () => {
  const getDoors = useCallback(async (worldId: string): Promise<Door[]> => {
    try {
      const response = await worldApi.getDoors(worldId);
      const { data: doors } = response;

      return doors.sort(({ doorId: a }, { doorId: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getDoors,
  };
};

export default useDoors;
