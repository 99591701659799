import { AssetsApi, Sprite } from "@worldwidewebb/client-assets";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

const useSprites = () => {
  const getSprites = useCallback(async (searchQuery?: string): Promise<Sprite[]> => {
    try {
      const response = await assetsApi.getSpritesByQuery(searchQuery);
      const { data: sprites } = response;

      return sprites.sort(({ spriteId: a }, { spriteId: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getSprites,
  };
};

export default useSprites;
