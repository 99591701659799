import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useRevalidator, useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdAdd, MdClose, MdDelete, MdEdit, MdRefresh } from "react-icons/md";
import SearchBar from "../components/base/SearchBar";
import { QuestWithId } from "../models/api/quest";
import useQuests from "../api/quests/useQuests";
import { useLoaderData } from "react-router";
import QuestList from "../components/QuestList";
import { BiCog, BiCollapseAlt, BiExpandAlt } from "react-icons/bi";
import { GoRepo, GoRepoTemplate } from "react-icons/go";
import { GrOverview } from "react-icons/gr";
import { AnimatePresence, motion } from "framer-motion";
import ConfirmModal from "../components/modals/ConfirmModal";
import { DialogSpeakerPassageWithId } from "@worldwidewebb/shared-messages/quests";
import { ulid } from "ulid";
import { exportRuntimeData } from "../utils/exportRuntimeData";
import { QuestEditorFolder, QuestEditorFolderWithId } from "@worldwidewebb/client-quests";
import { TbFolderPlus } from "react-icons/tb";
import { useForm } from "react-hook-form";
import { createFolder, deleteFolder, updateFolder } from "../api/quests/questFolders";
import { AxiosError } from "axios";
import useUsers from "../api/users/useUsers";

interface CreateFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateFolder: () => void;
}

function CreateFolderModal({ isOpen, onClose, onCreateFolder }: CreateFolderModalProps) {
  const toast = useToast();

  const { register, handleSubmit } = useForm<Pick<QuestEditorFolder, "displayName" | "description">>({
    defaultValues: useMemo(
      () => ({
        displayName: "",
        description: "",
      }),
      []
    ),
    mode: "onChange",
  });

  const handleCreate = useCallback(
    ({ displayName, description }: Pick<QuestEditorFolder, "displayName" | "description">) => {
      createFolder({ questEditorFolderId: ulid(), displayName, description })
        .then(() => {
          toast({
            title: "Created quest editor folder",
            status: "success",
          });

          onCreateFolder();
          onClose();
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            toast({
              title: "Creating of quest editor folder unsuccessful",
              description: error.response?.data.details,
              status: "error",
            });
          }

          console.error(error);
        });
    },
    [onCreateFolder, onClose, toast]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(handleCreate)}>
          <ModalHeader bg={"indigo.600"}>
            <Heading size={"md"}>
              <Text color={"white"}>Create Folder</Text>
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel>
                  <Text color={"white"}>Name</Text>
                </FormLabel>

                <Input color={"white"} {...register("displayName")} />
              </FormControl>

              <FormControl>
                <FormLabel>
                  <Text color={"white"}>Description</Text>
                </FormLabel>

                <Input color={"white"} {...register("description")} />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button variant={"outline"} type={"submit"}>
              Create
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

interface UpdateFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateFolder: () => void;
  questEditorFolder: QuestEditorFolderWithId;
}

function UpdateFolderModal({ isOpen, onClose, onUpdateFolder, questEditorFolder }: UpdateFolderModalProps) {
  const toast = useToast();

  const { register, handleSubmit } = useForm<Pick<QuestEditorFolder, "displayName" | "description">>({
    defaultValues: useMemo(
      () => ({
        displayName: questEditorFolder.displayName,
        description: questEditorFolder.description,
      }),
      [questEditorFolder]
    ),
    mode: "onChange",
  });

  const handleUpdate = useCallback(
    ({ displayName, description }: Pick<QuestEditorFolder, "displayName" | "description">) => {
      updateFolder(questEditorFolder.questEditorFolderId, { displayName, description })
        .then(() => {
          toast({
            title: "Updated quest editor folder",
            status: "success",
          });

          onUpdateFolder();
          onClose();
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            toast({
              title: "Updating of quest editor folder unsuccessful",
              description: error.response?.data.details,
              status: "error",
            });
          }

          console.error(error);
        });
    },
    [onUpdateFolder, onClose, toast]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader bg={"indigo.600"}>
            <Heading size={"md"}>
              <Text color={"white"}>Update Folder</Text>
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel>
                  <Text color={"white"}>Name</Text>
                </FormLabel>

                <Input color={"white"} {...register("displayName")} />
              </FormControl>

              <FormControl>
                <FormLabel>
                  <Text color={"white"}>Description</Text>
                </FormLabel>

                <Input color={"white"} {...register("description")} />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button variant={"outline"} type={"submit"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

interface DeleteFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteFolder: () => void;
  questEditorFolder: QuestEditorFolderWithId;
}

function DeleteFolderModal({
  isOpen,
  onClose,
  onDeleteFolder,
  questEditorFolder: { questEditorFolderId },
}: DeleteFolderModalProps) {
  const toast = useToast();
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  const handleDelete = useCallback(
    (questEditorFolderId: string) => {
      deleteFolder(questEditorFolderId)
        .then(() => {
          toast({
            title: "Deleted quest editor folder",
            status: "success",
          });

          onDeleteFolder();
          onClose();
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            toast({
              title: "Deleting of quest editor folder unsuccessful",
              description: error.response?.data.details,
              status: "error",
            });
          }

          console.error(error);
        });
    },
    [onDeleteFolder, onClose, toast]
  );

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={leastDestructiveRef}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"}>Delete Folder</Text>
          </Heading>
        </AlertDialogHeader>

        <AlertDialogCloseButton />

        <AlertDialogBody>
          <Stack>
            <Text color={"white"}>Are you sure you want to delete the folder? Quests will not be deleted.</Text>
          </Stack>
        </AlertDialogBody>

        <AlertDialogFooter gap={2}>
          <Button onClick={onClose} variant={"outline"} ref={leastDestructiveRef}>
            Cancel
          </Button>
          <Button variant={"outline"} onClick={() => handleDelete(questEditorFolderId)} color={"red.800"}>
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

interface QuestFoldersProps {
  isSearching: boolean;
  questEditorFolders: QuestEditorFolderWithId[];
  quests: QuestWithId[];
  filteredQuests: QuestWithId[];
  onUpdate: (quest: QuestWithId) => void;
  isUpdating: boolean;
  onDelete: (questId: string) => void;
  isDeleting: boolean;
  onDuplicate: (questId: string) => void;
  isDuplicating: boolean;
  onToggleReadiness: (questId: string) => void;
  isToggleReadiness: boolean;
}

function QuestFolders({
  isSearching,
  questEditorFolders,
  quests,
  filteredQuests,
  onUpdate,
  isUpdating,
  onDelete,
  isDeleting,
  onDuplicate,
  isDuplicating,
  onToggleReadiness,
  isToggleReadiness,
}: QuestFoldersProps) {
  const questEditorFoldersWithQuests = useMemo(
    () =>
      questEditorFolders.map((questEditorFolder) => ({
        questEditorFolder,
        quests: quests.filter(({ folderId }) => folderId === questEditorFolder.questEditorFolderId),
        filteredQuests: filteredQuests.filter(({ folderId }) => folderId === questEditorFolder.questEditorFolderId),
      })),
    [questEditorFolders, filteredQuests]
  );

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleToggleOpenIndex = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes((openIndexes) => openIndexes.filter((openIndex) => openIndex !== index));
      } else {
        setOpenIndexes((openIndexes) => openIndexes.concat(index));
      }
    },
    [openIndexes]
  );

  const handleCollapseFolders = useCallback(() => {
    setOpenIndexes([]);
  }, [questEditorFoldersWithQuests]);

  const handleExpandFolders = useCallback(() => {
    setOpenIndexes(Array.from({ length: questEditorFoldersWithQuests.length }, (_, index) => index));
  }, [questEditorFoldersWithQuests]);

  useEffect(() => {
    if (isSearching) {
      handleExpandFolders();
    } else {
      handleCollapseFolders();
    }
  }, [isSearching, handleExpandFolders, handleCollapseFolders]);

  return (
    <Accordion allowMultiple index={openIndexes}>
      {questEditorFoldersWithQuests
        .filter(({ filteredQuests }) => (isSearching ? filteredQuests.length !== 0 : true))
        .map(({ questEditorFolder, quests, filteredQuests }, index) => (
          <QuestFolder
            key={questEditorFolder.questEditorFolderId}
            index={index}
            onToggleOpenIndex={handleToggleOpenIndex}
            isSearching={isSearching}
            questEditorFolder={questEditorFolder}
            quests={quests}
            filteredQuests={filteredQuests}
            onUpdate={onUpdate}
            isUpdating={isUpdating}
            onDelete={onDelete}
            isDeleting={isDeleting}
            onDuplicate={onDuplicate}
            isDuplicating={isDuplicating}
            onToggleReadiness={onToggleReadiness}
            isToggleReadiness={isToggleReadiness}
          />
        ))}
    </Accordion>
  );
}

interface QuestFolderProps {
  index: number;
  onToggleOpenIndex: (index: number) => void;
  isSearching: boolean;
  questEditorFolder: QuestEditorFolderWithId;
  quests: QuestWithId[];
  filteredQuests: QuestWithId[];
  onUpdate: (quest: QuestWithId) => void;
  isUpdating: boolean;
  onDelete: (questId: string) => void;
  isDeleting: boolean;
  onDuplicate: (questId: string) => void;
  isDuplicating: boolean;
  onToggleReadiness: (questId: string) => void;
  isToggleReadiness: boolean;
}

function QuestFolder({
  index,
  onToggleOpenIndex,
  isSearching,
  questEditorFolder,
  quests,
  filteredQuests,
  onUpdate,
  isUpdating,
  onDelete,
  isDeleting,
  onDuplicate,
  isDuplicating,
  onToggleReadiness,
  isToggleReadiness,
}: QuestFolderProps) {
  const { displayName, description } = questEditorFolder;

  const { isOpen: isOpenUpdateFolder, onOpen: onOpenUpdateFolder, onClose: onCloseUpdateFolder } = useDisclosure();
  const { isOpen: isOpenDeleteFolder, onOpen: onOpenDeleteFolder, onClose: onCloseDeleteFolder } = useDisclosure();
  const { revalidate } = useRevalidator();

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleCollapseQuests = useCallback(() => {
    setOpenIndexes([]);
  }, [filteredQuests]);

  const handleExpandQuests = useCallback(() => {
    setOpenIndexes(Array.from({ length: filteredQuests.length }, (_, index) => index));
  }, [filteredQuests]);

  useEffect(() => {
    if (isSearching) {
      handleExpandQuests();
    } else {
      handleCollapseQuests();
    }
  }, [isSearching, handleExpandQuests, handleCollapseQuests]);

  const handleToggleOpenIndex = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes((openIndexes) => openIndexes.filter((openIndex) => openIndex !== index));
      } else {
        setOpenIndexes((openIndexes) => openIndexes.concat(index));
      }
    },
    [openIndexes]
  );

  const [isActivating, setIsActivating] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);

  useEffect(() => {
    if (isActivating && quests.some(({ isReady }) => !isReady)) {
      return;
    }

    setIsActivating(false);
  }, [quests, isActivating]);

  useEffect(() => {
    if (isDeactivating && quests.some(({ isReady }) => isReady)) {
      return;
    }

    setIsDeactivating(false);
  }, [quests, isDeactivating]);

  const handleOnOpenUpdateFolder = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      onOpenUpdateFolder();
    },
    [onOpenUpdateFolder]
  );

  const handleOnOpenDeleteFolder = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      onOpenDeleteFolder();
    },
    [onOpenDeleteFolder]
  );

  const handleActivateQuests = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      setIsActivating(true);

      quests.filter(({ isReady }) => !isReady).forEach((quest) => onUpdate({ ...quest, isReady: true }));
    },
    [quests, onUpdate]
  );

  const handleDeactivateQuests = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      setIsDeactivating(true);

      quests.filter(({ isReady }) => isReady).forEach((quest) => onUpdate({ ...quest, isReady: false }));
    },
    [quests, onUpdate]
  );

  const readyQuestCount = quests.filter(({ isReady }) => isReady).length;
  const totalQuestCount = quests.length;

  let colorQuestCount = "orange.600";

  if (readyQuestCount === 0) {
    colorQuestCount = "red.800";
  }

  if (readyQuestCount === totalQuestCount) {
    colorQuestCount = "green.600";
  }

  return (
    <>
      <AccordionItem>
        <AccordionButton justifyContent={"space-between"} p={1} onClick={() => onToggleOpenIndex(index)}>
          <Stack textAlign={"start"}>
            <Heading size={"md"}>
              <Text color={"white"} casing={"uppercase"}>
                {displayName}
              </Text>
            </Heading>

            <Text color={"white"} casing={"uppercase"} size={"sm"}>
              {description}
            </Text>

            <HStack>
              <Tooltip label={"update folder"}>
                <IconButton
                  as={"div"}
                  aria-label={"update folder"}
                  icon={<Icon position={"absolute"} as={MdEdit} />}
                  onClick={handleOnOpenUpdateFolder}
                  isDisabled={isActivating || isDeactivating}
                  color={"white"}
                />
              </Tooltip>
              <Tooltip label={"delete folder"}>
                <IconButton
                  as={"div"}
                  aria-label={"delete folder"}
                  icon={<Icon position={"absolute"} as={MdDelete} />}
                  onClick={handleOnOpenDeleteFolder}
                  isDisabled={isActivating || isDeactivating}
                  color={"red.800"}
                />
              </Tooltip>

              <Button
                as={"div"}
                color={"white"}
                onClick={handleActivateQuests}
                disabled={isDeactivating || readyQuestCount === totalQuestCount}
                isLoading={isActivating}
              >
                <Text color={"green.600"} casing={"uppercase"}>
                  Activate Quests
                </Text>
              </Button>
              <Button
                as={"div"}
                color={"white"}
                onClick={handleDeactivateQuests}
                disabled={isActivating || readyQuestCount === 0}
                isLoading={isDeactivating}
              >
                <Text color={"red.800"} casing={"uppercase"}>
                  Deactivate Quests
                </Text>
              </Button>

              <Tag h={10} bg={"whiteAlpha.200"}>
                <Text color={colorQuestCount} casing={"uppercase"}>
                  {readyQuestCount}/{totalQuestCount} active
                </Text>
              </Tag>
            </HStack>
          </Stack>

          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p={1}>
          <Stack>
            <HStack>
              <Tooltip label={"expand quests"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon position={"absolute"} as={BiExpandAlt} />}
                  aria-label={"expand quests"}
                  onClick={handleExpandQuests}
                />
              </Tooltip>
              <Tooltip label={"collapse quests"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon position={"absolute"} as={BiCollapseAlt} />}
                  aria-label={"collapse quests"}
                  onClick={handleCollapseQuests}
                />
              </Tooltip>
            </HStack>

            <QuestList
              flexGrow={1}
              quests={filteredQuests}
              onUpdate={onUpdate}
              isUpdating={isUpdating}
              onDelete={onDelete}
              isDeleting={isDeleting}
              onDuplicate={onDuplicate}
              isDuplicating={isDuplicating}
              openIndexes={openIndexes}
              onToggleOpenIndex={handleToggleOpenIndex}
              onToggleReadiness={onToggleReadiness}
              isToggleReadiness={isToggleReadiness}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      {isOpenUpdateFolder && (
        <UpdateFolderModal
          isOpen={isOpenUpdateFolder}
          onClose={onCloseUpdateFolder}
          onUpdateFolder={() => revalidate()}
          questEditorFolder={questEditorFolder}
        />
      )}

      {isOpenDeleteFolder && (
        <DeleteFolderModal
          isOpen={isOpenDeleteFolder}
          onClose={onCloseDeleteFolder}
          onDeleteFolder={() => revalidate()}
          questEditorFolder={questEditorFolder}
        />
      )}
    </>
  );
}

const Quests: React.FC = () => {
  const { quests, questFolders } = useLoaderData() as {
    quests: QuestWithId[];
    questFolders: QuestEditorFolderWithId[];
  };

  const { createQuest, updateQuest, deleteQuest, duplicateQuest, getQuest, generateVoices } = useQuests();

  const [searchParams, setSearchParams] = useSearchParams();
  const showTemplates = Boolean(JSON.parse(searchParams.get("showTemplates") ?? "false"));

  const [showManagementTools, setShowManagementTools] = useState<boolean>(false);

  const [isReloadingQuests, setIsReloadingQuests] = useState<boolean>(false);
  const [isToggleReadiness, setIsToggleReadiness] = useState<boolean>(false);
  const [isCreatingQuest, setIsCreatingQuest] = useState<boolean>(false);
  const [isUpdatingQuest, setIsUpdatingQuest] = useState<boolean>(false);
  const [isDeletingQuest, setIsDeletingQuest] = useState<boolean>(false);
  const [isDuplicatingQuest, setIsDuplicatingQuest] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const questsByIsTemplate = useMemo(
    () => quests.filter(({ isTemplate }) => showTemplates === isTemplate),
    [quests, showTemplates]
  );

  const filteredQuests = useMemo(
    () =>
      questsByIsTemplate
        .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase().trim()))
        .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    [questsByIsTemplate, searchValue]
  );

  const orphanFilteredQuests = useMemo(
    () =>
      filteredQuests.filter(
        ({ folderId = "" }) => !questFolders.map(({ questEditorFolderId }) => questEditorFolderId).includes(folderId)
      ),
    [filteredQuests, questFolders]
  );

  const filteredQuestCount = filteredQuests.length;

  const toast = useToast();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const handleReloadQuests = useCallback(async () => {
    try {
      setIsReloadingQuests(true);

      revalidate();

      toast({
        title: "Reloaded quests",
        status: "success",
      });
    } catch (error) {
      console.error(error);

      toast({
        title: "Error occurred reloading quests",
        description: (error as Error).message,
        status: "error",
      });
    } finally {
      setIsReloadingQuests(false);
    }
  }, []);

  const { getDisplayName } = useUsers();

  const handleCreateQuest = useCallback(async () => {
    try {
      setIsCreatingQuest(true);

      const userDisplayName = await getDisplayName();
      const userCreatedDate = new Date();
      const displayName = `${userDisplayName.toUpperCase()} - ${userCreatedDate.toUTCString()}`;

      const { questId } = await createQuest(displayName);

      navigate(questId);

      toast({
        title: "Created quest",
        status: "success",
      });
    } catch (error) {
      console.error(error);

      toast({
        title: "Error occurred creating quest",
        description: (error as Error).message,
        status: "error",
      });
    } finally {
      setIsCreatingQuest(false);
    }
  }, [createQuest, getDisplayName]);

  const handleUpdateQuest = useCallback(
    async (quest: QuestWithId) => {
      try {
        setIsUpdatingQuest(true);

        await updateQuest(quest);

        revalidate();

        toast({
          title: "Updated quest",
          status: "success",
        });
      } catch (error) {
        console.error(error);

        toast({
          title: "Error occurred updating quest",
          description: (error as Error).message,
          status: "error",
        });
      } finally {
        setIsUpdatingQuest(false);
      }
    },
    [updateQuest]
  );

  const handleToggleReadiness = useCallback(
    async (questId: string) => {
      setIsToggleReadiness(true);

      const quest = await getQuest(questId);

      if (quest == null) {
        return;
      }

      updateQuest({
        ...quest,
        isReady: !quest.isReady,
      })
        .then((quest) => {
          toast({
            title: "Updated quest readiness",
            status: "success",
          });

          revalidate();
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            toast({
              title: "Updating of quest readiness failed",
              description: "See developer console for details",
              status: "error",
            });
          }

          toast({
            title: "Updating of quest readiness failed",
            description: "Another user has already an active session",
            status: "error",
          });

          console.error(error);
        })
        .finally(() => setIsToggleReadiness(false));
    },
    [getQuest, updateQuest]
  );

  const handleDeleteQuest = useCallback(
    async (questId: string) => {
      try {
        setIsDeletingQuest(true);

        await deleteQuest(questId);

        revalidate();

        toast({
          title: "Deleted quest",
          status: "success",
        });
      } catch (error) {
        console.error(error);

        toast({
          title: "Error occurred deleting quest",
          description: (error as Error).message,
          status: "error",
        });
      } finally {
        setIsDeletingQuest(false);
      }
    },
    [deleteQuest]
  );

  const handleDuplicateQuest = useCallback(
    async (questId: string) => {
      try {
        setIsDuplicatingQuest(true);

        await duplicateQuest(questId);

        revalidate();

        toast({
          title: "Duplicated quest",
          status: "success",
        });
      } catch (error) {
        console.error(error);

        toast({
          title: "Error occurred duplicating quest",
          description: (error as Error).message,
          status: "error",
        });
      } finally {
        setIsDuplicatingQuest(false);
      }
    },
    [duplicateQuest]
  );

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleCollapseQuests = useCallback(() => {
    setOpenIndexes([]);
  }, [filteredQuests]);

  const handleExpandQuests = useCallback(() => {
    setOpenIndexes(Array.from({ length: filteredQuests.length }, (_, index) => index));
  }, [filteredQuests]);

  const handleToggleOpenIndex = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes((openIndexes) => openIndexes.filter((openIndex) => openIndex !== index));
      } else {
        setOpenIndexes((openIndexes) => openIndexes.concat(index));
      }
    },
    [openIndexes]
  );

  const [isRegeneratingVoiceAudio, setIsRegeneratingVoiceAudio] = useState(false);
  const [regeneratingVoiceAudioProgress, setRegeneratingVoiceAudioProgress] = useState(0);

  const handleRegenerateVoiceAudio = useCallback(async () => {
    setIsRegeneratingVoiceAudio(true);
    setRegeneratingVoiceAudioProgress(0);

    await Promise.all(
      questsByIsTemplate.map(async ({ questId }) => {
        try {
          await generateVoices(questId);

          setRegeneratingVoiceAudioProgress((value) => value + 1);
        } catch (error) {
          console.error(error);

          toast({
            title: "Error occurred requesting regeneration of voice audio",
            description: (error as Error).message,
            status: "error",
          });
        }
      })
    );

    toast({
      title: "Requested regeneration of voice audio",
      status: "success",
    });

    setIsRegeneratingVoiceAudio(false);
  }, [questsByIsTemplate, generateVoices]);

  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  const navigateWithLoading = useCallback(
    (url: string) => {
      setIsNavigating(true);

      navigate(url);
    },
    [navigate]
  );

  const handleToggleShowTemplates = useCallback(() => {
    setSearchParams({ showTemplates: String(!showTemplates) });

    setIsNavigating(true);
  }, [showTemplates]);

  useEffect(() => {
    setIsNavigating(false);
  }, [showTemplates]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSearching = searchValue !== "";

  useEffect(() => {
    if (isSearching) {
      handleExpandQuests();
    } else {
      handleCollapseQuests();
    }
  }, [isSearching, handleExpandQuests, handleCollapseQuests]);

  return (
    <Container maxW={"4xl"} display={"flex"}>
      <Card bg={"theme.dark.background"} borderRadius={0} flexGrow={1}>
        <CardHeader bg={"theme.dark.background"} borderBottomWidth={2} position={"sticky"} top={0} zIndex={1}>
          <Stack>
            <HStack borderColor={"indigo.600"} borderBottomWidth={2}>
              <Tooltip label={showTemplates ? "show quests" : "show quest templates"}>
                <IconButton
                  bg={"indigo.600"}
                  variant={"outline"}
                  borderRadius={0}
                  borderBottomWidth={0}
                  isLoading={isNavigating}
                  onClick={() => handleToggleShowTemplates()}
                  aria-label={showTemplates ? "show quests" : "show quest templates"}
                  icon={<Icon as={showTemplates ? GoRepoTemplate : GoRepo} />}
                />
              </Tooltip>
              <Tooltip label={"show quests overview"}>
                <IconButton
                  bg={"indigo.600"}
                  variant={"outline"}
                  borderRadius={0}
                  borderBottomWidth={0}
                  isLoading={isNavigating}
                  aria-label={"show quests overview"}
                  onClick={() => navigateWithLoading("/quests-overview")}
                  icon={<Icon as={GrOverview} />}
                />
              </Tooltip>
            </HStack>

            <HStack>
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                inputFocusKey={"/"}
                placeholder={"Search for quests"}
              />

              <Tooltip label={"reload quests"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon as={MdRefresh} />}
                  aria-label={"reload quests"}
                  isLoading={isReloadingQuests}
                  onClick={handleReloadQuests}
                />
              </Tooltip>
              <Tooltip label={"create quest"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon as={MdAdd} />}
                  aria-label={"create quest"}
                  isLoading={isCreatingQuest}
                  onClick={handleCreateQuest}
                />
              </Tooltip>
            </HStack>

            <HStack flexGrow={1} justifyContent={"space-between"} pb={2}>
              <HStack>
                <Tooltip label={"expand quests"}>
                  <IconButton
                    color={"white"}
                    variant={"outline"}
                    icon={<Icon as={BiExpandAlt} />}
                    aria-label={"expand quests"}
                    onClick={handleExpandQuests}
                  />
                </Tooltip>
                <Tooltip label={"collapse quests"}>
                  <IconButton
                    color={"white"}
                    variant={"outline"}
                    icon={<Icon as={BiCollapseAlt} />}
                    aria-label={"collapse quests"}
                    onClick={handleCollapseQuests}
                  />
                </Tooltip>
              </HStack>

              <HStack>
                <Tooltip label={"management tools"}>
                  <IconButton
                    color={"white"}
                    variant={showManagementTools ? "solid" : "outline"}
                    icon={<Icon as={BiCog} />}
                    aria-label={"management tools"}
                    onClick={() => setShowManagementTools((showManagementTools) => !showManagementTools)}
                  />
                </Tooltip>
              </HStack>
            </HStack>
          </Stack>

          <AnimatePresence>
            {showManagementTools && (
              <motion.div
                variants={{
                  hide: { transition: { height: { delay: 0.25 } }, opacity: 0, height: 0 },
                  show: { transition: { opacity: { delay: 0.25 } }, opacity: 1, height: "auto" },
                }}
                initial={"hide"}
                animate={"show"}
                exit={"hide"}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Stack borderWidth={1} borderRadius={6} p={2}>
                  <ConfirmModal color={"white"} title={"Regenerate voice audio"} onConfirm={handleRegenerateVoiceAudio}>
                    Regenerating voice audio will trigger a batch process for all quests, continue?
                  </ConfirmModal>

                  {isRegeneratingVoiceAudio && (
                    <Progress
                      colorScheme={"whiteAlpha"}
                      min={0}
                      max={questsByIsTemplate.length}
                      value={regeneratingVoiceAudioProgress}
                    />
                  )}
                </Stack>
              </motion.div>
            )}
          </AnimatePresence>
        </CardHeader>

        <CardBody display={"flex"}>
          <Stack flexGrow={1}>
            <HStack>
              <Tooltip label={"create folder"}>
                <IconButton
                  color={"white"}
                  aria-label={"create folder"}
                  icon={<Icon as={TbFolderPlus} onClick={onOpen} />}
                />
              </Tooltip>
            </HStack>

            <QuestFolders
              isSearching={isSearching}
              questEditorFolders={questFolders}
              quests={questsByIsTemplate}
              filteredQuests={filteredQuests}
              onUpdate={handleUpdateQuest}
              isUpdating={isUpdatingQuest}
              onDelete={handleDeleteQuest}
              isDeleting={isDeletingQuest}
              onDuplicate={handleDuplicateQuest}
              isDuplicating={isDuplicatingQuest}
              onToggleReadiness={handleToggleReadiness}
              isToggleReadiness={isToggleReadiness}
            />

            {orphanFilteredQuests.length !== 0 && (
              <QuestList
                flexGrow={1}
                quests={orphanFilteredQuests}
                onUpdate={handleUpdateQuest}
                isUpdating={isUpdatingQuest}
                onDelete={handleDeleteQuest}
                isDeleting={isDeletingQuest}
                onDuplicate={handleDuplicateQuest}
                isDuplicating={isDuplicatingQuest}
                openIndexes={openIndexes}
                onToggleOpenIndex={handleToggleOpenIndex}
                onToggleReadiness={handleToggleReadiness}
                isToggleReadiness={isToggleReadiness}
              />
            )}
          </Stack>

          {isOpen && <CreateFolderModal isOpen={isOpen} onClose={onClose} onCreateFolder={() => revalidate()} />}
        </CardBody>

        <CardFooter bg={"theme.dark.background"} borderTopWidth={2} position={"sticky"} bottom={0}>
          <Text color={"white"} casing={"uppercase"} fontWeight={500}>
            Showing {filteredQuestCount} quest{filteredQuestCount !== 1 ? "s" : ""}
          </Text>
        </CardFooter>
      </Card>
    </Container>
  );
};

export default Quests;
