import { AssetsApi, Sprite } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getSprite(spriteId?: string): Promise<Sprite | null> {
  if (spriteId == null) {
    return null;
  }

  try {
    const { data: sprite } = await assetsApi.getSprite(spriteId);

    return sprite;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
