import React, { memo, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { QuestWithId } from "../models/api/quest";
import { NodeType } from "../models/nodeType";
import { Edge, Node } from "reactflow";
import { ItemIntrinsicWithImage, ItemNameAndQuantity, Sprite } from "@worldwidewebb/client-assets";
import useItems from "../api/items/useItems";
import { Avatar, Heading, HStack, Spinner, Stack, Tag, Text, Wrap, WrapItem } from "@chakra-ui/react";
import useQuests from "../api/quests/useQuests";

interface QuestComponentProps {
  questId: string;
}

const QuestComponent: React.FC<QuestComponentProps> = ({ questId }) => {
  const [quest, setQuest] = useState<QuestWithId | null>(null);
  const { getQuest } = useQuests();

  useEffect(() => {
    getQuest(questId)
      .then((quest) => setQuest(quest))
      .catch(() => setQuest(null));
  }, []);

  if (quest == null) {
    return <Spinner size={"md"} color={"white"} />;
  }

  const { name } = quest;

  return <Tag>{name}</Tag>;
};

interface QuestListComponentProps extends PropsWithChildren {
  questIds: string[];
}

const QuestListComponent: React.FC<QuestListComponentProps> = ({ questIds, children }) => {
  if (questIds.length === 0) {
    return null;
  }

  return (
    <Stack>
      <Heading size={"sm"}>
        <Text color={"white"} casing={"uppercase"}>
          {children}
        </Text>
      </Heading>

      <Wrap>
        {questIds.map((questId) => (
          <WrapItem key={questId}>
            <QuestComponent questId={questId} />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};

interface QuestOverviewComponentProps {
  nodes: Node<NodeType>[];
}

const QuestOverviewComponent: React.FC<QuestOverviewComponentProps> = ({ nodes }) => {
  const startNodes = nodes.filter(({ data: { nodeClass } }) => nodeClass === "start");

  const allQuestsRequiredToBeStarted: string[] = startNodes.flatMap(({ data: { nodeData } }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { allStartConditions = [] } = (nodeData ?? {}) as any;

    return allStartConditions
      .filter(({ type }: { type: string }) => type === "quest_started")
      .map(({ value }: { value: string }) => value);
  });

  const allQuestsRequiredToBeCompleted: string[] = startNodes.flatMap(({ data: { nodeData } }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { allStartConditions = [] } = (nodeData ?? {}) as any;

    return allStartConditions
      .filter(({ type }: { type: string }) => type === "quest_completed")
      .map(({ value }: { value: string }) => value);
  });

  const anyQuestsRequiredToBeStarted: string[] = startNodes.flatMap(({ data: { nodeData } }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { anyStartConditions = [] } = (nodeData ?? {}) as any;

    return anyStartConditions
      .filter(({ type }: { type: string }) => type === "quest_started")
      .map(({ value }: { value: string }) => value);
  });

  const anyQuestsRequiredToBeCompleted: string[] = startNodes.flatMap(({ data: { nodeData } }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { anyStartConditions = [] } = (nodeData ?? {}) as any;

    return anyStartConditions
      .filter(({ type }: { type: string }) => type === "quest_completed")
      .map(({ value }: { value: string }) => value);
  });

  return (
    <Stack>
      <QuestListComponent questIds={allQuestsRequiredToBeStarted}>
        All quests required to be already started
      </QuestListComponent>
      <QuestListComponent questIds={allQuestsRequiredToBeCompleted}>
        All quests required to be completed
      </QuestListComponent>
      <QuestListComponent questIds={anyQuestsRequiredToBeStarted}>
        Any quests required to be already started
      </QuestListComponent>
      <QuestListComponent questIds={anyQuestsRequiredToBeCompleted}>
        Any quests required to be completed
      </QuestListComponent>
    </Stack>
  );
};

interface ItemOverviewComponentProps {
  nodes: Node<NodeType>[];
  edges: Edge[];
}

const ItemOverviewComponent: React.FC<ItemOverviewComponentProps> = ({ nodes, edges }) => {
  const createQuestTaskItems = useMemo(() => {
    const mappedNodesFound = mapNodesByConnectedHandleName(
      nodes,
      edges,
      "item_name_and_quantity",
      "create_quest_task",
      "item_name_and_quantity"
    );

    return aggregateNodesByItemNameAndQuantity(mappedNodesFound);
  }, [nodes, edges]);

  const createQuestLogItems = useMemo(() => {
    const mappedNodesFound = mapNodesByConnectedHandleName(
      nodes,
      edges,
      "item_name_and_quantity",
      "create_quest_log",
      "item_name_and_quantity"
    );

    return aggregateNodesByItemNameAndQuantity(mappedNodesFound);
  }, [nodes, edges]);

  const giveItemItems = useMemo(() => {
    const mappedNodesFound = mapNodesByConnectedHandleName(
      nodes,
      edges,
      "item_name_and_quantity",
      "give_item",
      "item_name_and_quantity"
    );

    return aggregateNodesByItemNameAndQuantity(mappedNodesFound);
  }, [nodes, edges]);

  const takeItemItems = useMemo(() => {
    const mappedNodesFound = mapNodesByConnectedHandleName(
      nodes,
      edges,
      "item_name_and_quantity",
      "take_item",
      "item_name_and_quantity"
    );

    return aggregateNodesByItemNameAndQuantity(mappedNodesFound);
  }, [nodes, edges]);

  const spawnItemItems = useMemo(() => {
    const mappedNodesFound = mapNodesByConnectedHandleName(
      nodes,
      edges,
      "item_name_and_quantity",
      "spawn_item",
      "item_name_and_quantity"
    );

    return aggregateNodesByItemNameAndQuantity(mappedNodesFound);
  }, [nodes, edges]);

  return (
    <Stack>
      <ItemNameAndQuantityDictionaryComponent itemNameAndQuantityDictionary={createQuestLogItems}>
        Items tracked as quest rewards
      </ItemNameAndQuantityDictionaryComponent>
      <ItemNameAndQuantityDictionaryComponent itemNameAndQuantityDictionary={createQuestTaskItems}>
        Items tracked as quest task requirements
      </ItemNameAndQuantityDictionaryComponent>
      <ItemNameAndQuantityDictionaryComponent itemNameAndQuantityDictionary={giveItemItems}>
        Items given
      </ItemNameAndQuantityDictionaryComponent>
      <ItemNameAndQuantityDictionaryComponent itemNameAndQuantityDictionary={takeItemItems}>
        Items taken
      </ItemNameAndQuantityDictionaryComponent>
      <ItemNameAndQuantityDictionaryComponent itemNameAndQuantityDictionary={spawnItemItems}>
        Items spawned
      </ItemNameAndQuantityDictionaryComponent>
    </Stack>
  );
};

interface ItemNameAndQuantityDictionaryComponentProps extends PropsWithChildren {
  itemNameAndQuantityDictionary: Record<string, number>;
}

const ItemNameAndQuantityDictionaryComponent: React.FC<ItemNameAndQuantityDictionaryComponentProps> = ({
  itemNameAndQuantityDictionary,
  children,
}) => {
  const itemNameAndQuantityDictionaryEntries = Object.entries(itemNameAndQuantityDictionary);

  if (itemNameAndQuantityDictionaryEntries.length === 0) {
    return null;
  }

  return (
    <Stack>
      <Heading size={"sm"}>
        <Text color={"white"} casing={"uppercase"}>
          {children}
        </Text>
      </Heading>

      <Wrap>
        {itemNameAndQuantityDictionaryEntries.map(([itemName, quantity]) => (
          <WrapItem key={itemName}>
            <ItemNameAndQuantityComponent itemName={itemName} quantity={quantity} />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};

interface ItemNameAndQuantityComponentProps {
  itemName: string;
  quantity: number;
}

export const ItemNameAndQuantityComponent: React.FC<ItemNameAndQuantityComponentProps> = ({ itemName, quantity }) => {
  const [item, setItem] = useState<ItemIntrinsicWithImage | null>(null);
  const [icon, setIcon] = useState<Sprite | null>(null);
  const { getItem, getItemSprite } = useItems();

  useEffect(() => {
    getItem(itemName)
      .then((item) => setItem(item))
      .catch(() => setItem(null));
  }, []);

  useEffect(() => {
    if (item == null) {
      return;
    }

    const { spriteId } = item;

    if (spriteId == null) {
      return;
    }

    getItemSprite(spriteId)
      .then((sprite) => setIcon(sprite))
      .catch(() => setIcon(null));
  }, [item]);

  if (item == null) {
    return <Spinner size={"md"} color={"white"} />;
  }

  const { displayName } = item;

  return (
    <HStack alignItems={"center"}>
      <Avatar
        bg={"transparent"}
        borderColor={"transparent"}
        size={"md"}
        src={icon?.imageUri}
        sx={{ imageRendering: "pixelated" }}
      />

      <Text color={"white"} fontWeight={500}>
        {quantity} &times; {displayName}
      </Text>
    </HStack>
  );
};

const mapNodesByConnectedHandleName = (
  nodes: Node<NodeType>[],
  edges: Edge[],
  targetHandleName: string,
  targetNodeType: string,
  sourceNodeType: string
): Node<NodeType>[] => {
  return nodes
    .filter(({ data: { nodeName } }) => nodeName === targetNodeType)
    .flatMap(({ data: { targetHandles = [] } }) => targetHandles)
    .filter(({ handleName }) => handleName === targetHandleName)
    .flatMap(({ handleId: targetHandleId }) =>
      nodes
        .filter(({ data: { nodeName } }) => nodeName === sourceNodeType)
        .filter(({ data: { sourceHandles } }) =>
          sourceHandles?.some(({ handleId: sourceHandleId }) => {
            return edges
              .filter(({ sourceHandle }) => sourceHandle === sourceHandleId)
              .map(({ targetHandle }) => targetHandle)
              .includes(targetHandleId);
          })
        )
    );
};

const aggregateNodesByItemNameAndQuantity = (nodes: Node<NodeType>[]): Record<string, number> => {
  return nodes
    .map(({ data: { nodeData } }): ItemNameAndQuantity => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { itemName, quantity } = (nodeData ?? {}) as any;

      return {
        itemName,
        quantity,
      };
    })
    .reduce((itemNameAndQuantityDictionary: Record<string, number>, { itemName, quantity }) => {
      itemNameAndQuantityDictionary[itemName] ||= 0;
      itemNameAndQuantityDictionary[itemName] += quantity;

      return itemNameAndQuantityDictionary;
    }, {});
};

interface QuestListItemOverviewAggregateProps {
  quest: QuestWithId;
}

const QuestListItemOverviewAggregate: React.FC<QuestListItemOverviewAggregateProps> = ({
  quest: { data: { nodes, edges } = { nodes: [], edges: [] } },
}) => {
  return (
    <Stack>
      <QuestOverviewComponent nodes={nodes} />
      <ItemOverviewComponent nodes={nodes} edges={edges} />
    </Stack>
  );
};

export default memo(QuestListItemOverviewAggregate);
