import {
  Button,
  Tooltip,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { QuestEditorSessionWithId } from "@worldwidewebb/client-quests";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useParams, useRevalidator } from "react-router";
import useSessions from "../api/sessions/useSessions";
import Timer from "./base/Timer";

const UserSessionControl = () => {
  const { id: questId } = useParams();
  const { revalidate } = useRevalidator();
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen: true });

  const { sessionUserId, sessionUserDisplayName, sessionUserIdWithLock } = useLoaderData() as {
    sessionUserId?: string;
    sessionUserDisplayName?: string;
    sessionUserIdWithLock?: QuestEditorSessionWithId;
  };

  const { createQuestEditorLock, deleteQuestEditorLock } = useSessions();

  const [isCreatingSession, setIsCreatingSession] = useState<boolean>(false);
  const [isDeletingSession, setIsDeletingSession] = useState<boolean>(false);
  const [isLockedSessionDismissed, setIsLockedSessionDismissed] = useState<boolean>(false);

  const handleCreateSession = useCallback(() => {
    if (questId == null) {
      return;
    }

    setIsCreatingSession(true);

    createQuestEditorLock(questId)
      .then(() => {
        toast({
          title: "Created quest editor session",
          status: "success",
        });

        revalidate();
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          toast({
            title: "Creating of quest editor session failed",
            description: "See developer console for details",
            status: "error",
          });

          throw error;
        }

        toast({
          title: "Creating of quest editor session failed",
          description: "Another user has already an active session",
          status: "error",
        });

        setIsCreatingSession(false);
      });
  }, [questId, createQuestEditorLock, toast, revalidate]);

  const handleDeleteSession = useCallback(() => {
    if (questId == null) {
      return;
    }

    setIsDeletingSession(true);

    deleteQuestEditorLock(questId)
      .then(() => {
        toast({
          title: "Deleted quest editor session",
          status: "success",
        });

        revalidate();
      })
      .catch((error) => {
        toast({
          title: "Deleting of quest editor session failed",
          description: "See developer console for details",
          status: "error",
        });

        setIsDeletingSession(false);
      });
  }, [questId, deleteQuestEditorLock, toast, revalidate]);

  const handleClose = useCallback(() => {
    setIsLockedSessionDismissed(true);

    onClose();
  }, [onClose]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsCreatingSession(false);
      setIsDeletingSession(false);

      revalidate();
    }, 30_000);

    return () => clearInterval(interval);
  }, [revalidate]);

  useEffect(() => {
    if (sessionUserIdWithLock == null) {
      setIsLockedSessionDismissed(false);
    }

    if (sessionUserIdWithLock != null && sessionUserIdWithLock.userId !== sessionUserId) {
      !isLockedSessionDismissed && onOpen();
    }
  }, [sessionUserIdWithLock, sessionUserIdWithLock, sessionUserId, onOpen]);

  if (sessionUserId == null) {
    return null;
  }

  if (sessionUserIdWithLock == null) {
    return (
      <Tooltip label={"create locked session"}>
        <Button onClick={handleCreateSession} isLoading={isCreatingSession}>
          <Text color={"green.600"} casing={"uppercase"}>
            Create Locked Session
          </Text>
        </Button>
      </Tooltip>
    );
  }

  if (sessionUserIdWithLock != null && sessionUserIdWithLock.userId === sessionUserId) {
    return (
      <Tooltip label={"delete locked session"}>
        <Button onClick={handleDeleteSession} isLoading={isDeletingSession}>
          <Text color={"red.800"} casing={"uppercase"}>
            Delete Locked Session
          </Text>

          <Timer color={"red.800"} time={new Date(sessionUserIdWithLock.expiresAt)} />
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <>
        <Button>
          <Text color={"yellow.600"} casing={"uppercase"}>
            Locked by {sessionUserDisplayName}
          </Text>

          <Timer color={"yellow.600"} time={new Date(sessionUserIdWithLock.expiresAt)} />
        </Button>

        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />

          <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"yellow.600"}>
            <ModalHeader bg={"yellow.600"}>
              <Heading size={"md"}>
                <Text color={"white"}>Locked by {sessionUserDisplayName}</Text>
              </Heading>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody p={12}>
              <Text color={"white"}>Saving will fail because it is locked by {sessionUserDisplayName}</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
};

export default UserSessionControl;
