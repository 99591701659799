import {
  Card,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Icon,
  Stack,
  StackProps,
  Tag,
  Text,
  FormControl,
  FormLabel,
  Input,
  Divider,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { QuestEditorCommitWithId } from "@worldwidewebb/client-quests";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdEye } from "react-icons/io";
import { MdSave } from "react-icons/md";
import { decodeTime } from "ulid";
import { getCommits, updateCommit } from "../../../api/quests/questCommits";
import { User } from "../../User";
import QuestCommitPreviewModal from "./QuestCommitPreviewModal";

interface QuestCommitProps {
  commit: Omit<QuestEditorCommitWithId, "quest">;
}

function QuestCommit({
  commit: { questEditorCommitId, questId, version, userId, message: initialMessage },
}: QuestCommitProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  let versionTime = "<unknown time>";

  try {
    versionTime = new Date(decodeTime(version)).toUTCString();
  } catch (error) {
    console.error(error);
  }

  const { register, reset, handleSubmit } = useForm<
    Omit<QuestEditorCommitWithId, "questEditorCommitId" | "quest" | "questId" | "version" | "userId">
  >({
    defaultValues: {
      message: initialMessage ?? "",
    },
    mode: "onBlur",
  });

  const handleUpdateQuestEditorCommit = useCallback(
    async (
      questEditorCommitPartial: Omit<
        QuestEditorCommitWithId,
        "questEditorCommitId" | "quest" | "questId" | "version" | "userId"
      >
    ) => {
      try {
        const { message } = await updateCommit(questId, questEditorCommitId, questEditorCommitPartial);

        reset({ message });

        toast({
          title: "Updating of commit successful",
          status: "success",
        });
      } catch (error) {
        toast({
          title: "Updating of commit unsuccessful",
          description: (error as Error).message,
          status: "error",
        });
      }
    },
    [reset, toast]
  );

  return (
    <>
      <Card p={2}>
        <Stack>
          <Grid gap={2} templateColumns={"1fr auto"}>
            <GridItem>
              <Stack>
                <HStack>
                  <Text color={"white"} fontWeight={500}>
                    ID
                  </Text>
                  <Tag>{questEditorCommitId}</Tag>
                </HStack>

                <HStack>
                  <Text color={"white"} fontWeight={500}>
                    on
                  </Text>
                  <Tag>
                    <Text color={"white"} casing={"uppercase"}>
                      {versionTime}
                    </Text>
                  </Tag>
                </HStack>

                <HStack>
                  <Text color={"white"} fontWeight={500}>
                    by
                  </Text>
                  <Tag>
                    <User color={"white"} userId={userId} />
                  </Tag>
                </HStack>
              </Stack>
            </GridItem>

            <GridItem>
              <Stack flexGrow={1}>
                <IconButton
                  aria-label={"view"}
                  icon={<Icon position={"absolute"} as={IoMdEye} />}
                  color={"white"}
                  onClick={onOpen}
                />
              </Stack>
            </GridItem>
          </Grid>

          <Divider />

          <form onSubmit={handleSubmit(handleUpdateQuestEditorCommit)}>
            <HStack alignItems={"flex-end"}>
              <FormControl>
                <FormLabel>
                  <Text color={"white"} fontWeight={500}>
                    message (optional)
                  </Text>
                </FormLabel>

                <Input color={"white"} {...register("message")} />
              </FormControl>

              <IconButton
                aria-label={"view"}
                icon={<Icon position={"absolute"} as={MdSave} />}
                color={"white"}
                type={"submit"}
              />
            </HStack>
          </form>
        </Stack>
      </Card>

      <QuestCommitPreviewModal
        questId={questId}
        questEditorCommitId={questEditorCommitId}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

interface QuestCommitListProps extends StackProps {
  commits: Omit<QuestEditorCommitWithId, "quest">[];
}

function QuestCommitList({ commits, ...stackProps }: QuestCommitListProps) {
  return (
    <>
      <Stack
        minW={"md"}
        px={2}
        pb={2}
        sx={{
          "::-webkit-scrollbar": {
            width: 2,
          },
          "::-webkit-scrollbar-track": {
            bg: "mirage.900",
          },
          "::-webkit-scrollbar-thumb": {
            bg: "indigo.600",
          },
        }}
        overflowY={"auto"}
        {...stackProps}
      >
        {commits.map((commit) => (
          <QuestCommit key={commit.questEditorCommitId} commit={commit} />
        ))}
      </Stack>
    </>
  );
}

interface QuestCommitsProps {
  questId: string;
}

function QuestCommits({ questId }: QuestCommitsProps) {
  const [commits, setCommits] = useState<Omit<QuestEditorCommitWithId, "quest">[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const [scrollableHeight, setScrollableHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const containerRefHeight = containerRef.current?.offsetHeight ?? 0;

    setScrollableHeight(containerRefHeight);

    return () => setScrollableHeight(0);
  }, []);

  const toast = useToast();

  useEffect(() => {
    getCommits(questId)
      .then(setCommits)
      .catch((error) => {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      });
  }, [questId, toast]);

  if (commits.length === 0) {
    return (
      <Stack textAlign={"center"} minW={"md"}>
        <Text color={"white"} casing={"uppercase"}>
          No commits found
        </Text>
      </Stack>
    );
  }

  return (
    <Card bg={"theme.dark.background"}>
      <Stack ref={containerRef} flexGrow={1}>
        <Stack flexGrow={1} h={scrollableHeight}>
          <QuestCommitList commits={commits} />
        </Stack>
      </Stack>
    </Card>
  );
}

export default QuestCommits;
