import { World, WorldApi, WorldMetadata } from "@worldwidewebb/client-world";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const worldApi = new WorldApi(undefined, buildApiClientBasePath("world"), axiosInstance);

const useRooms = () => {
  const getRooms = useCallback(async (searchQuery?: string): Promise<WorldMetadata[]> => {
    try {
      const response = await worldApi.getWorldMetadataByQuery(searchQuery);
      const { data: rooms } = response;

      return rooms.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getRoom = useCallback(async (roomName: string): Promise<World> => {
    try {
      const response = await worldApi.getWorld(roomName, true);
      const { data: worldWithChunks } = response;

      return worldWithChunks;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getRooms,
    getRoom,
  };
};

export default useRooms;
