import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { getSkills } from "../../api/skills/skills";
import { SkillWithOrder } from "@worldwidewebb/client-skills";

interface SelectSkillProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectSkill: React.FC<SelectSkillProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let skills: SkillWithOrder[] = [];

      try {
        skills = await getSkills();
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return skills
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ displayName, skillId }) => [skillId, `${displayName} (${skillId})`]);
    },
    [toast, getSkills]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"Skill"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectSkill;
