import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { Sprite } from "../../../models/api/sprite";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectSprite from "../../base/SelectSprite";

const SpawnSpriteEntityNode: React.FC<NodeProps<NodeType<Sprite>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const spriteId = nodeData?.spriteId ?? "";
  const entityIndexZ = nodeData?.entityIndexZ ?? 0;

  const { getValues, setValue, control, handleSubmit } = useForm<Sprite>({
    defaultValues: useMemo(
      () => ({
        spriteId,
        entityIndexZ,
      }),
      [spriteId, entityIndexZ]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    ({ spriteId, entityIndexZ }: Sprite) => {
      updateNodeData({
        spriteId,
        entityIndexZ,
      });
    },
    [updateNodeData]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <SelectSprite
            color={color}
            value={getValues("spriteId")}
            setValue={(value) => setValue("spriteId", value)}
          />

          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Entity Index Z
              </Text>
            </FormLabel>
            <Controller
              name={"entityIndexZ"}
              control={control}
              render={({ field: { ref, value, onChange, onBlur, name } }) => (
                <NumberInput
                  value={value}
                  defaultValue={0}
                  name={name}
                  step={1}
                  ref={ref}
                  onChange={(value) => onChange(Number(value))}
                  onBlur={onBlur}
                >
                  <NumberInputField color={color} borderColor={color} borderRadius={0} borderWidth={2} />
                  <NumberInputStepper>
                    <NumberIncrementStepper color={color} />
                    <NumberDecrementStepper color={color} />
                  </NumberInputStepper>
                </NumberInput>
              )}
            />
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(SpawnSpriteEntityNode);
