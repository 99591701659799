import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { NodeData, NodeType, TargetHandle } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import SelectWaypoint, { QuestWaypoint } from "../../base/SelectWaypoint";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { findLastIndex, findNextIndex } from "../../../utils/handles";

interface CreateQuestTask extends NodeData {
  taskDescription: string;
  questRequirementInputCount: number;
  playerActionAndRequirementInputCount: number;
  questWaypoint: QuestWaypoint;
}

const CreateQuestTaskNode: React.FC<NodeProps<NodeType<CreateQuestTask>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const taskDescription = nodeData?.taskDescription;
  const questRequirementInputCount = nodeData?.questRequirementInputCount ?? 0;
  const playerActionAndRequirementInputCount = nodeData?.playerActionAndRequirementInputCount ?? 0;
  const questWaypoint = nodeData?.questWaypoint ?? "none";

  const { register, control, watch, getValues, setValue, handleSubmit } = useForm<CreateQuestTask>({
    defaultValues: useMemo(
      () => ({
        taskDescription,
        questRequirementInputCount,
        playerActionAndRequirementInputCount,
        questWaypoint,
      }),
      [taskDescription, questRequirementInputCount, playerActionAndRequirementInputCount, questWaypoint]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    (createQuestTask: CreateQuestTask) => {
      updateNodeData(createQuestTask);
    },
    [updateNodeData]
  );

  const targetHandleNames = [
    "in",
    "questId",
    "item_name_and_quantity",
    "playerActionAndRequirement",
    "location",
    "npc",
  ];

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const watchedItemNameAndQuantityRequirementInputCount = watch("questRequirementInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "item_name_and_quantity").length;

    if (currentCount === watchedItemNameAndQuantityRequirementInputCount) {
      return;
    }

    const handleName = "item_name_and_quantity";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "item_name_and_quantity");

    if (currentCount < watchedItemNameAndQuantityRequirementInputCount) {
      const count = watchedItemNameAndQuantityRequirementInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Item Name And Quantity",
            handleName: "item_name_and_quantity",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedItemNameAndQuantityRequirementInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedItemNameAndQuantityRequirementInputCount, updateNodeTargetHandles]);

  const watchedPlayerActionAndRequirementInputCount = watch("playerActionAndRequirementInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "playerActionAndRequirement").length;

    if (currentCount === watchedPlayerActionAndRequirementInputCount) {
      return;
    }

    const handleName = "playerActionAndRequirement";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "playerActionAndRequirement");

    if (currentCount < watchedPlayerActionAndRequirementInputCount) {
      const count = watchedPlayerActionAndRequirementInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Player Action And Requirement",
            handleName: "playerActionAndRequirement",
            handleType: "target",
            handleCategory: "playerActionAndRequirement",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedPlayerActionAndRequirementInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedPlayerActionAndRequirementInputCount, updateNodeTargetHandles]);

  const watchedQuestWaypoint = watch("questWaypoint");

  useEffect(() => {
    const hasCurrentHandle = targetHandles.some(({ handleName }) => handleName === watchedQuestWaypoint);

    if (hasCurrentHandle) {
      return;
    }

    const hasRemovedHandle = targetHandles.every(({ handleName }) => handleName !== "location" && handleName !== "npc");

    if (hasRemovedHandle && watchedQuestWaypoint === "none") {
      return;
    }

    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, "location") + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, "npc"));

    if (watchedQuestWaypoint === "none") {
      updateNodeTargetHandles([...headHandles, ...tailHandles]);
    } else {
      const waypointHandle: TargetHandle = {
        label: `${watchedQuestWaypoint} waypoint`,
        handleName: watchedQuestWaypoint,
        handleType: "target",
        handleCategory: "data",
      };

      updateNodeTargetHandles([...headHandles, waypointHandle, ...tailHandles]);
    }

    updateNodeInternals(nodeId);
  }, [watchedQuestWaypoint, updateNodeTargetHandles, targetHandles, updateNodeInternals, nodeId]);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <Stack>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Task Description
                </Text>
              </FormLabel>
              <Textarea id={"taskDescription"} isRequired {...register("taskDescription")} color={color} />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Item Name And Quantity Input Count
                </Text>
              </FormLabel>
              <Controller
                name={"questRequirementInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Player Action and Requirement Count
                </Text>
              </FormLabel>
              <Controller
                name={"playerActionAndRequirementInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>

            <FormControl>
              <SelectWaypoint
                color={color}
                value={getValues("questWaypoint")}
                setValue={(value: string) => setValue("questWaypoint", value ? (value as QuestWaypoint) : "none")}
              />
            </FormControl>
          </Stack>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(CreateQuestTaskNode);
