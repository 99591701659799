import { AssetsApi, Vendor } from "@worldwidewebb/client-assets";
import { useCallback } from "react";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

const useVendors = () => {
  const getVendors = useCallback(async (searchQuery?: string): Promise<Vendor[]> => {
    try {
      const response = await assetsApi.getVendorsByQuery(searchQuery);
      const { data: vendors } = response;

      return vendors.sort(({ vendorId: a }, { vendorId: b }) => a.localeCompare(b));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getVendors,
  };
};

export default useVendors;
