import { memo, useCallback } from "react";
import { Card, Flex, HStack, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import useUpdateQuestMetaDatas from "../../hooks/quests/useUpdateQuestMetaDatas";
import { useReactFlowStoreProvider } from "../../context/ReactFlowStoreContext";
import { MdSave } from "react-icons/md";

function QuestsToolbar() {
  const { nodes, edges } = useReactFlowStoreProvider();

  const { isUpdating, updateQuestMetaDatas } = useUpdateQuestMetaDatas();

  const handleSave = useCallback(() => {
    updateQuestMetaDatas(nodes.map(({ id, position }) => ({ questId: id, questEditorPosition: position })));
  }, [updateQuestMetaDatas, nodes, edges]);

  return (
    <HStack position={"fixed"} right={20} top={4}>
      <Card p={1} bg={"theme.dark.background"}>
        <Flex gap={1} align={"center"}>
          <Tooltip label={"save"}>
            <IconButton
              color={"white"}
              icon={<Icon as={MdSave} />}
              aria-label={"save"}
              onClick={handleSave}
              isLoading={isUpdating}
            />
          </Tooltip>
        </Flex>
      </Card>
    </HStack>
  );
}

export default memo(QuestsToolbar);
