import StyledBackground from "../components/reactFlow/StyledBackground";
import StyledControls from "../components/reactFlow/StyledControls";
import StyledMiniMap from "../components/reactFlow/StyledMiniMap";
import ControlSchemeInfo from "../components/reactFlow/ControlSchemeInfo";
import React, { memo, useCallback, useEffect, useState } from "react";
import ReactFlow, { ControlButton, Edge, Node } from "reactflow";
import { Button, HStack, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { BiSolidInfoCircle } from "react-icons/bi";
import { LiaHandPaperSolid, LiaHandPointer } from "react-icons/lia";
import { TiImageOutline } from "react-icons/ti";
import { useReactFlowStoreProvider } from "../context/ReactFlowStoreContext";
import { useUserProvider } from "../context/UserContext";
import { filterByInitialReadyNodesAndEdges } from "../utils/questNodes";
import { useStoreSelector } from "../store/hooks";
import QuestNode from "../features/quests/QuestNode";
import QuestEdge from "../features/quests/QuestEdge";
import QuestSearchPanel from "../features/quests/QuestSearchPanel";
import { StyledPanel } from "../components/reactFlow/StyledPanel";
import QuestUpdateLayoutButton from "../features/quests/QuestUpdateLayoutButton";
import generateLayout from "../utils/generateLayout";
import QuestNotificationsButton from "../features/quests/QuestNotificationsButton";
import { NodeType } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";
import QuestsToolbar from "../components/reactFlow/QuestsToolbar";

// TODO: cleanup

const nodeTypes = {
  QuestNode: memo(QuestNode),
};

const edgeTypes = {
  QuestEdge: memo(QuestEdge),
};

function QuestsOverview() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showActiveQuests, setShowActiveQuests] = useState<boolean>(true);

  const { controlScheme, toggleControlScheme, reactFlowProps } = useUserProvider();

  const {
    nodes,
    setNodes,
    onNodesChange,
    setEdges,
    edges,
    onEdgesChange,
    handleClickOfSelection,
    handleDownloadGraphAsImage,
  } = useReactFlowStoreProvider();

  const handleToggleShowActiveQuests = useCallback(
    () => setShowActiveQuests((showActiveQuests) => !showActiveQuests),
    []
  );

  const { questNodes, questEdges } = useStoreSelector(({ quests }) => quests);

  useEffect(() => {
    let nodes: Node<NodeType>[] = questNodes;
    let edges: Edge<EdgeType>[] = questEdges;

    if (showActiveQuests) {
      const { readyNodes, readyEdges } = filterByInitialReadyNodesAndEdges(questNodes, questEdges);

      nodes = readyNodes;
      edges = readyEdges;
    } else {
      nodes = questNodes;
      edges = questEdges;
    }

    setNodes(nodes);
    setEdges(edges);
  }, [questNodes, questEdges, showActiveQuests]);

  return (
    <>
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={handleClickOfSelection}
        deleteKeyCode={[]}
        proOptions={{ hideAttribution: true }}
        minZoom={0.125}
        maxZoom={1}
        {...reactFlowProps}
      >
        <StyledBackground />

        <StyledMiniMap position={"bottom-right"} pannable={true} zoomable={true} />

        <StyledControls position={"top-right"} showInteractive={false}>
          <ControlButton onClick={handleDownloadGraphAsImage}>
            <Icon as={TiImageOutline} />
          </ControlButton>
          <ControlButton onClick={onOpen}>
            <Icon as={BiSolidInfoCircle} />
          </ControlButton>
          <ControlButton onClick={toggleControlScheme}>
            <Icon as={controlScheme === "primary" ? LiaHandPointer : LiaHandPaperSolid} />
          </ControlButton>
        </StyledControls>

        <QuestSearchPanel color={"white"} />

        <StyledPanel position={"bottom-center"}>
          <HStack>
            <QuestUpdateLayoutButton />

            <Button onClick={handleToggleShowActiveQuests}>
              <Text color={"white"} casing={"uppercase"}>
                {showActiveQuests ? "Show Inactive" : "Hide Inactive"}
              </Text>
            </Button>
          </HStack>
        </StyledPanel>
      </ReactFlow>

      <ControlSchemeInfo isOpen={isOpen} onClose={onClose} />

      <QuestsToolbar />

      <QuestNotificationsButton position={"fixed"} top={"50%"} right={5} />
    </>
  );
}

export default QuestsOverview;
