import { QuestPointer } from "@worldwidewebb/client-quests";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  deleteQuestPointerForUser,
  getQuestPointersForUser,
  updateQuestPointerForUser,
} from "../api/quests/questPointers";
import { getDisplayNameByUserId } from "../api/users/users";

const REFRESH_INTERVAL_MS = 10_000;

export interface UserQuestPointerStatus {
  questPointerId?: string;
  questPointerColor?: string;
  isActive?: boolean;
  hasError?: boolean;
  isWaiting?: boolean;
  isCompleted?: boolean;
}

interface UserQuestPointer {
  questPointers: QuestPointer[];
  isLoading: boolean;
  hasError: boolean;
  questPointerDisplayName: string;
  questPointerUserId: string;
  setQuestPointerUserId: (userId: string) => void;
  selectedQuestPointerId: string | null;
  selectQuestPointer: (questPointerId: string) => void;
  updateQuestPointer: (nodeId: string) => void;
  deleteQuestPointer: (questPointerId: string) => void;
  getQuestPointerStatus: (nodeId: string) => UserQuestPointerStatus;
  showQuestPointers: boolean;
  openQuestPointers: () => void;
  hideQuestPointers: () => void;
}

const UserQuestPointerContext = createContext<UserQuestPointer | null>(null);

interface UserQuestPointerProviderProps extends PropsWithChildren {
  questId?: string;
}

export function UserQuestPointerProvider({ questId, children }: UserQuestPointerProviderProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [questPointerUserId, setQuestPointerUserId] = useState<string>("");
  const [questPointerDisplayName, setQuestPointerDisplayName] = useState<string>("");
  const [questPointers, setQuestPointers] = useState<QuestPointer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showQuestPointers, setShowQuestPointers] = useState<boolean>(false);

  const [selectedQuestPointerId, setSelectedQuestPointerId] = useState<string | null>(null);

  const selectQuestPointer = useCallback((questPointerId: string) => {
    setSelectedQuestPointerId((selectedQuestPointerId) =>
      selectedQuestPointerId !== questPointerId ? questPointerId : null
    );
  }, []);

  const updateQuestPointer = useCallback(
    (nodeId: string) => {
      if (selectedQuestPointerId == null) {
        return;
      }

      setIsLoading(true);
      setHasError(false);

      updateQuestPointerForUser(questPointerUserId, selectedQuestPointerId, nodeId)
        .then(() => setSelectedQuestPointerId(null))
        .then(reloadQuestPointers)
        .catch(() => setHasError(true))
        .finally(() => setIsLoading(false));
    },
    [questPointerUserId, selectedQuestPointerId]
  );

  const deleteQuestPointer = useCallback(
    (questPointerId: string) => {
      setIsLoading(true);
      setHasError(false);

      deleteQuestPointerForUser(questPointerUserId, questPointerId)
        .then(() => setSelectedQuestPointerId(null))
        .then(reloadQuestPointers)
        .catch(() => setHasError(true))
        .finally(() => setIsLoading(false));
    },
    [questPointerUserId]
  );

  const getQuestPointerStatus = useCallback(
    (nodeId: string): UserQuestPointerStatus => {
      const questPointer = questPointers.find((questPointer) => questPointer.nodeId === nodeId);
      const questPointerId = questPointer?.pointerId;

      const isActive = questPointer != null;
      const hasError = questPointer?.errored;
      const isWaiting = questPointer?.interactionWait;
      const isCompleted = questPointer?.completed;

      let questPointerColor = undefined;

      if (isActive) {
        questPointerColor = "green.600";
      }

      if (isWaiting) {
        questPointerColor = "green.200";
      }

      if (hasError) {
        questPointerColor = "red.800";
      }

      return {
        questPointerId,
        questPointerColor,
        isActive,
        hasError,
        isWaiting,
        isCompleted,
      };
    },
    [questPointers]
  );

  const reloadQuestPointers = useCallback(() => {
    if (!questId) {
      return;
    }

    setIsLoading(true);
    setHasError(false);

    getQuestPointersForUser(questId, questPointerUserId)
      .then(setQuestPointers)
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [questId, questPointerUserId]);

  const openQuestPointers = useCallback(() => {
    setShowQuestPointers(true);
  }, []);

  const hideQuestPointers = useCallback(() => {
    setShowQuestPointers(false);

    setSelectedQuestPointerId(null);
  }, []);

  useEffect(() => {
    if (!questPointerUserId) {
      return;
    }

    reloadQuestPointers();

    const interval = setInterval(reloadQuestPointers, REFRESH_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [questId, questPointerUserId, reloadQuestPointers]);

  useEffect(() => {
    if (questPointerUserId) {
      return;
    }

    const userId = searchParams.get("userId");

    if (!userId) {
      return;
    }

    setQuestPointerUserId(userId);
  }, [questPointerUserId, searchParams]);

  useEffect(() => {
    setSearchParams({ ...searchParams, userId: questPointerUserId });
  }, [questPointerUserId]);

  useEffect(() => {
    getDisplayNameByUserId(questPointerUserId).then(setQuestPointerDisplayName);
  }, [questPointerUserId]);

  return (
    <UserQuestPointerContext.Provider
      value={{
        questPointers,
        isLoading,
        hasError,
        questPointerDisplayName,
        questPointerUserId,
        setQuestPointerUserId,
        selectedQuestPointerId,
        selectQuestPointer,
        updateQuestPointer,
        deleteQuestPointer,
        getQuestPointerStatus,
        showQuestPointers,
        openQuestPointers,
        hideQuestPointers,
      }}
    >
      {children}
    </UserQuestPointerContext.Provider>
  );
}

export function useUserQuestPointerProvider() {
  const context = useContext(UserQuestPointerContext);

  if (context == null) {
    throw new Error("useUserQuestPointerProvider used outside of UserQuestPointerProvider");
  }

  return context;
}
